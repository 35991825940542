import React, {useEffect} from 'react'
import {ArrowDownTrayIcon, ArrowUpTrayIcon} from '@heroicons/react/24/solid'

import {isEmpty, isNull} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {Button} from '../../../../components/Button'

import './ContractPreview.scss'
import DocumentViewer from '../../../../components/DocumentViewer/DocumentViewer'
import {SignedFileDropzone} from '../../../../components/SignedFileDropzone'
import {getTokenAndUploadFile} from '../../../../utils/files'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {Loader} from '../../../../components/Loader'
import {PencilSquareIcon} from '@heroicons/react/24/outline'
import {ALLOW_EDIT_FILE_EXTENSION} from '../../../../components/DocumentViewer/constants'

const ContractPreview = ({match: {params: {contractID}}, selectedEntityID, user}) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const [fileMeta, setFileMeta] = React.useState(null)
    const [contract, setContract] = React.useState({})

    const handleDropSignedFile = async (selectedFile, documentID) => {
        if(!isEmpty(selectedFile)) {
            setIsLoading(true)
            const fileURL = await getTokenAndUploadFile(selectedFile, selectedEntityID)
            // updateDocument({signed_file: fileURL}, documentID)
            const response = await performRequest(RESOURCES.contracts.update({file: fileURL}, documentID))
            const {data} = response
            setFileMeta(data.file_meta)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const fetchContract = async () => {
            setIsLoading(true)
            const response = await performRequest(RESOURCES.contracts.retrieve(contractID))
            const {data} = response
            setFileMeta(data.file_meta)
            setIsLoading(false)
            return data
        }

        if(contractID) {
            fetchContract().then((data) => {setContract(data)})
        }
     
    }, [contractID])

    return (
        <div className="page-info">
            <div className="contract-preview-page-container">
                <div className="contract-preview-page-header">
                    <div className="header-buttons-container">
                        {!isNull(contract.file) && (
                            <>
                                {fileMeta && ALLOW_EDIT_FILE_EXTENSION.includes(fileMeta.extension) ? <a
                                    href={`/contracts/${contractID}/edit`}
                                    rel="noopener noreferrer"
                                >
                                    <Button title="Editează" icon={() => <PencilSquareIcon/>} color="gray"/>
                                </a> : null}
                                <a
                                    href={contract.file}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Button title="Descarcă" icon={() => <ArrowDownTrayIcon/>}/>
                                </a>
                                <SignedFileDropzone
                                    onChange={(selectedFile) => (
                                        handleDropSignedFile(selectedFile, contractID)
                                    )}
                                    customButton={() =>
                                        <Button
                                            loading={isLoading}
                                            title="Încarcă"
                                            icon={() => <ArrowUpTrayIcon/>}
                                            color="orange"
                                        />
                                    }
                                />
                                {/* <UploadDocumentModal documentID={documentID} /> */}
                            </>
                        )}
                    </div>
                </div>
                {isLoading && (
                    <div className="loader-container">
                        <Loader/>
                    </div>)
                }

                {!!fileMeta && !isLoading ? (
                    <div className="contract-preview-container">
                        <DocumentViewer fileMeta={fileMeta} user={user} />
                    </div>
                ) : (
                    <p className="no-file">Nu există niciun fișier încărcat</p>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    contract: state.contracts.currentContract,
    isLoading: state.contracts.isLoading,
    user: state.users.user,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    retrieveContract: (contractID) => dispatch(RESOURCES.contracts.retrieve(contractID))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractPreview)

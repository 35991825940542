import React from 'react'

// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {StatsItem} from '../../../../components/StatsItem'
// @ts-ignore
import {keepPreviousData} from '@tanstack/react-query'
import {endOfMonth, startOfMonth} from 'date-fns'
import * as URI from 'uri-js'
import {Loader} from '../../../../components/Loader'
import {useQueryResourceList} from '../../../../queries'
import {RequestListingResponse, RequestResponse} from '../../../../types/api'
import {AccessPolicyAction, AccessPolicyPermission, AccessPolicyResource} from '../../../../types/openapi'
import {hasPermission} from '../../../../utils/permissions'

import './ContractStats.scss'
// @ts-ignore
import {toApiDateFormat} from 'utils'

const ContractStats = ({selectedEntityID}: {selectedEntityID: number}) => {
    // @ts-ignore
    const {
        data: accessPoliciesResponse,
        isFetching: accessPoliciesFetching
    }: {
        data: RequestListingResponse
        isFetching: boolean
    } = useQueryResourceList(
        RESOURCES.accessPolicies,
        {
            entity_id: selectedEntityID,
            resource: 'contracts'
        },
        {
            placeholderData: keepPreviousData,
            staleTime: 1000 * 60 * 30
        }
    )

    const hasContractsPermission = hasPermission(
        accessPoliciesResponse?.data?.results,
        AccessPolicyResource.contracts,
        AccessPolicyPermission.ALL,
        AccessPolicyAction.READ
    )

    const startDate = toApiDateFormat(startOfMonth(new Date()))
    const endDate = toApiDateFormat(endOfMonth(new Date()))

    // @ts-ignore
    const {
        data: contractsReportsResponse,
        isFetching: contractsReportsFetching
    }: {
        data: RequestResponse
        isFetching: boolean
    } = useQueryResourceList(
        RESOURCES.contractsReports,
        {
            entity_id: selectedEntityID,
            created__gte: startDate,
            created__lte: endDate
        },
        {
            placeholderData: keepPreviousData,
            staleTime: 1000 * 10,
            enabled: hasContractsPermission
        }
    )

    // @ts-ignore
    const {
        data: contractsAllTimeReportsResponse,
        isFetching: contractsAllTimeReportsFetching
    }: {
        data: RequestResponse
        isFetching: boolean
    } = useQueryResourceList(
        RESOURCES.contractsReports,
        {
            entity_id: selectedEntityID
        },
        {
            placeholderData: keepPreviousData,
            staleTime: 1000 * 10,
            enabled: hasContractsPermission
        }
    )

    // @ts-ignore
    const {
        data: empowermentsResponse,
        isFetching: empowermentsFetching
    }: {
        data: RequestListingResponse
        isFetching: boolean
    } = useQueryResourceList(
        RESOURCES.empowerments,
        {
            entity_id: selectedEntityID,
            created__gte: startDate,
            created__lte: endDate
        },
        {
            placeholderData: keepPreviousData,
            staleTime: 1000 * 10,
            enabled: hasContractsPermission
        }
    )

    if (!hasContractsPermission) {
        return null
    }

    if (accessPoliciesFetching || contractsReportsFetching || empowermentsFetching || contractsAllTimeReportsFetching) {
        return (
            <div className="dashboard-card-container stats-card">
                <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    <Loader />
                </dl>
            </div>
        )
    }

    const totalContracts = contractsReportsResponse?.data?.total || 0
    const totalNotInvoicedContracts = contractsAllTimeReportsResponse?.data?.total_to_be_invoiced || 0
    const totalEmpowerments = empowermentsResponse?.data?.count || 0

    const allContractsPath = URI.serialize({
        path: '/contracts/contracts',
        query: new URLSearchParams({
            created__gte: startDate,
            created__lte: endDate
        }).toString()
    })

    const toBeInvoicedContractsPath = URI.serialize({
        path: '/contracts/contracts',
        query: new URLSearchParams({
            invoicing_state: btoa(JSON.stringify({value: 'to_be_invoiced', label: 'De facturat'}))
        }).toString()
    })

    const allEmpowermentsPath = URI.serialize({
        path: '/contracts/empowerments',
        query: new URLSearchParams({
            created__gte: startDate,
            created__lte: endDate
        }).toString()
    })

    return (
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
            <StatsItem
                name="Contracte emise luna aceasta"
                stat={totalContracts}
                link={allContractsPath}
                linkText="Vezi contracte emise"
            />
            <StatsItem
                name="Contracte de facturat"
                stat={totalNotInvoicedContracts}
                link={toBeInvoicedContractsPath}
                linkText="Vezi contracte nefacturate"
            />
            <StatsItem
                name="Împuterniciri emise luna aceasta"
                stat={totalEmpowerments}
                link={allEmpowermentsPath}
                linkText="Vezi împuterniciri emise"
            />
        </dl>
    )
}

export default ContractStats

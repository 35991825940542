import React, {Component} from 'react'
import {default as ReactPhoneInput} from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import {isEmpty} from 'lodash'

import {getErrorMessage} from '../../utils/errorHelper'

import {InputError} from '../ErrorComponents'

import './PhoneInput.scss'

export default class PhoneInput extends Component {
    render() {
        const {label, value, onChange, size, fullWidth, errors, name} = this.props

        const hasErrors = errors && !isEmpty(errors[name])

        return (
            <div>
                {label && (
                    <label className="avo-phone-input-label">
                        {label}
                    </label>
                )}
                <ReactPhoneInput
                    country={'ro'}
                    value={value}
                    containerClass='avo-phone-input-container'
                    inputClass={`avo-phone-input ${size || 'medium'} ${fullWidth ? 'full-width' : ''}`}
                    onChange={(phone) => onChange(phone)}
                />
                {hasErrors && errors[name].map((error, errorIdx) => (
                    <InputError key={errorIdx} message={getErrorMessage(error, name)}/>
                ))}
            </div>
        )
    }
}

import React from 'react'

import {debounceWait} from 'utils/constants'

export function useDebouncedValue<T>(value: T, delay: number = debounceWait): T {
    const [debouncedValue, setDebouncedValue] = React.useState(value)

    React.useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)

        // Cancel the timeout if value changes (also on delay change or unmount)
        return () => {
            clearTimeout(handler)
        }
    }, [value, delay])

    return debouncedValue
}

export function useDebouncedState<T>(
    initialValue: T,
    delay: number = debounceWait
): [T | undefined, React.Dispatch<React.SetStateAction<T | undefined>>, T | undefined] {
    const [value, setValue] = React.useState<T | undefined>(initialValue)
    const debouncedValue = useDebouncedValue(value, delay)

    return [value, setValue, debouncedValue]
}

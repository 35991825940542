import {keepPreviousData} from '@tanstack/react-query'
import {useQueryResourceList} from '../../queries'
import {CalendarFilter} from './filters'
import {Event, EventType} from './types'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {Hearing, Task} from '../../types/api'
// @ts-ignore
import colors from 'assets/styles/colors'

interface UseEventsResourcesProps {
    selectedEntityID: number
    modalIsOpen: boolean
    query: any
}

const mapTasksToEvent = (task: Task): Event => {
    return {
        id: task.id,
        title: task.title || 'Untitled',
        url: `/tasks/${task.id}`,
        start: task.start,
        end: task.stop,
        allDay: task.all_day,
        color: task.color || colors.primary,
        extendedProps: {
            eventType: EventType.TASK
        }
    }
}
const mapHearingsToEvent = (hearing: Hearing): Event => {
    return {
        id: hearing.id,
        // @ts-ignore
        title: 'Termen: ' + (hearing?.phase.number || 'Untitled'),
        // @ts-ignore
        url: `/external-data/monitor-dosare/${hearing.related_subscription_id}`,
        start: hearing.date,
        end: hearing.date,
        allDay: true,
        color: colors.avoGreen,
        extendedProps: {
            eventType: EventType.HEARING
        }
    }
}

const useEventsResources = ({selectedEntityID, modalIsOpen, query}: UseEventsResourcesProps) => {
    const includeTasks = query.resources?.some(({value}: any) => value === EventType.TASK)
    const {data: tasksData} = useQueryResourceList(
        RESOURCES.tasks,
        {
            entity_id: selectedEntityID,
            ...CalendarFilter.getAPIFilters(query)
        },
        {
            placeholderData: keepPreviousData,
            enabled: !modalIsOpen && includeTasks
        }
    )

    const includeHearings = query.resources?.some(({value}: any) => value === EventType.HEARING)
    const {data: hearingsData} = useQueryResourceList(
        RESOURCES.hearings,
        {
            entity_id: selectedEntityID,
            ...CalendarFilter.getAPIFilters(query)
        },
        {
            placeholderData: keepPreviousData,
            enabled: !modalIsOpen && includeHearings
        }
    )
    return {
        events: [
            ...((includeTasks && (tasksData as any)?.data?.results?.map(mapTasksToEvent)) || []),
            ...((includeHearings && (hearingsData as any)?.data?.results?.map(mapHearingsToEvent)) || [])
        ]
    }
}

export default useEventsResources

import React, {useCallback, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'

import {PlusIcon} from '@heroicons/react/24/solid'

import _ from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {useQueryResourceList} from '../../../../queries'
import {useDebouncedState} from '../../../../utils/hooks'

import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {Dropdown} from '../../../../components/Dropdown'

import {DeleteEmpowermentModal} from '../../../../components/DeleteEmpowermentModal'
import {modalTypes, openModal} from '../../../../redux/modals'
import {columns} from './constants'

export const Empowerments = ({
    selectedEntityID,
    openDeleteEmpowermentModal,
    match: {
        params: {contractID}
    }
}) => {
    const [searchFilter, setSearchFilter] = useDebouncedState('', 300)
    const [page, setPage] = React.useState(1)
    const [selectedEmpowerment, setSelectedEmpowerment] = useState(null)

    const filters = useMemo(
        () => ({
            search: searchFilter,
            contract_id: contractID,
            entity_id: selectedEntityID,
            page_size: 15,
            page
        }),
        [contractID, page, searchFilter, selectedEntityID]
    )

    const {data: empowermentsResult, isFetching} = useQueryResourceList(RESOURCES.empowerments, filters)

    const handleChangeSearchField = (value) => setSearchFilter(value)

    const handleChangePage = useCallback((newPage) => {
        setPage(newPage)
    }, [])

    if (_.isNil(empowermentsResult)) {
        return <React.Fragment />
    }

    return (
        <div className="page-project-show">
            <Datatable
                title="Listă împuterniciri"
                data={empowermentsResult.data.results}
                headerButton={() => (
                    <Link to={`/contracts/empowerments/add?initialContractId=${contractID}`}>
                        <Button
                            title="Adaugă împuternicire"
                            icon={() => <PlusIcon />}
                        />
                    </Link>
                )}
                columns={[
                    ...columns,
                    {
                        Header: 'Acțiuni',
                        accessor: 'id',
                        Cell: ({value: addendumID, row: {original: empowerment}}) => (
                            <div className="flex gap-2 items-center">
                                <Link to={`/contracts/empowerments/${addendumID}/preview`}>
                                    <Button
                                        title="Vizualizare"
                                        variant="text"
                                        size="small"
                                    />
                                </Link>
                                <Dropdown
                                    title="Descarcă"
                                    disabled={
                                        _.isNil(empowerment.file_meta?.url_public) && _.isNil(empowerment.signed_file)
                                    }
                                    items={[
                                        {
                                            customContent: () =>
                                                empowerment.file_meta?.url_public ? (
                                                    <a
                                                        href={empowerment.file_meta.url_public}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="avo-dropdown-menu-item">
                                                        Descarcă
                                                    </a>
                                                ) : (
                                                    <React.Fragment />
                                                )
                                        },
                                        {
                                            customContent: () =>
                                                empowerment.signed_file ? (
                                                    <a
                                                        href={empowerment.signed_file}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="avo-dropdown-menu-item">
                                                        Descarcă fișier semnat
                                                    </a>
                                                ) : (
                                                    <React.Fragment />
                                                )
                                        }
                                    ]}
                                    variant="text"
                                    color="secondary"
                                    size="small"
                                />
                                <Button
                                    title="Șterge"
                                    onClick={() => {
                                        setSelectedEmpowerment(empowerment)
                                        openDeleteEmpowermentModal()
                                    }}
                                    size="small"
                                    color="red"
                                    variant="text"
                                />
                            </div>
                        )
                    }
                ]}
                loading={isFetching}
                previousPage={empowermentsResult.data?.pages?.previous}
                currentPage={empowermentsResult.data?.page}
                nextPage={empowermentsResult.data?.pages?.next}
                totalPages={empowermentsResult.data?.number_of_pages}
                onChangePage={(page) => handleChangePage(page)}
                searchable
                searchValue={searchFilter}
                searchPlaceholder="Caută împuterniciri"
                onSearch={(event) => handleChangeSearchField(event.target.value)}
            />
            <DeleteEmpowermentModal empowerment={selectedEmpowerment} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    openDeleteEmpowermentModal: () => dispatch(openModal(modalTypes.DELETE_EMPOWERMENT))
})

export default connect(mapStateToProps, mapDispatchToProps)(Empowerments)

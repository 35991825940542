import React, {useEffect} from 'react'

import {Form, Formik} from 'formik'

import {values as _values, find, head, isEmpty} from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {slideoverTypes} from '../../../../../redux/slideovers'

import {clientBankAccountsSchema} from '../../../../../assets/validations'
import {getFieldOptions, objectKeysToSnakeCase} from '../../../../../utils'
import {RON_CURRENCY_VALUE} from '../../../../../utils/constants'

import {Button} from '../../../../../components/Button'
import {ErrorsList} from '../../../../../components/ErrorComponents'
import {Input} from '../../../../../components/Input'
import {Loader} from '../../../../../components/Loader'
import {Select} from '../../../../../components/Select'
import {Slideover} from '../../../../../components/Slideover'

import '../../../../../assets/scss/SlideoverForms.scss'

export const AddClientBankAccountSlideover = ({
    open,
    client,
    isLoading,
    clientAddresses,
    listAllClientAddresses,
    fieldErrors,
    nonFieldErrors,
    selectedEntityID,
    clientBankAccountsOptions,
    getClientBankAccountsOptions,
    addClientBankAccount
}) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {getClientBankAccountsOptions()}, [])

    useEffect(() => {
        if(open) {
            listAllClientAddresses(selectedEntityID, client.id)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const currencies = getFieldOptions(clientBankAccountsOptions, 'currency')

    return (
        <Slideover open={open && !isEmpty(client)} title='Editează cont bancar'>
            {!isEmpty(client) && !isLoading ? (
                <div className="slideover-form-container">
                    <ErrorsList errors={nonFieldErrors} />
                    <Formik
                        initialValues={{
                            clientID: client.id,
                            currency: find(currencies, ['value', RON_CURRENCY_VALUE]),
                            iban: '',
                            bankName: '',
                            swiftCode: '',
                            bankAccountDescription: '',
                            clientAddressId: head(clientAddresses)
                        }}
                        validationSchema={clientBankAccountsSchema}
                        validateOnMount
                        onSubmit={(values) => {
                            const updateData = {
                                ...objectKeysToSnakeCase(values),
                                currency: values.currency.value,
                                client_address_id: values.clientAddressId.id
                            }

                            addClientBankAccount(updateData)
                        }}
                    >
                        {({
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            values,
                            handleSubmit,
                            touched,
                            errors,
                            isValid
                        }) => (
                            <>
                                <Form className="slideover-form">
                                    <Input
                                        label='IBAN*'
                                        value={values.iban}
                                        onChange={handleChange('iban')}
                                        onBlur={handleBlur('iban')}
                                        name='iban'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.iban}
                                        fullWidth
                                    />
                                    <Select
                                        label='Moneda*'
                                        value={values.currency}
                                        options={currencies}
                                        onChange={(e) => setFieldValue('currency', e)}
                                        onBlur={handleBlur('currency')}
                                        name='currency'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.currency}
                                        fullWidth
                                    />
                                    <Input
                                        label='Banca*'
                                        value={values.bankName}
                                        onChange={handleChange('bankName')}
                                        onBlur={handleBlur('bankName')}
                                        name='bankName'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.bankName}
                                        fullWidth
                                    />
                                    <Input
                                        label='Cod SWIFT'
                                        value={values.swiftCode}
                                        onChange={handleChange('swiftCode')}
                                        onBlur={handleBlur('swiftCode')}
                                        name='swiftCode'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.swiftCode}
                                        fullWidth
                                    />
                                    <Input
                                        label='Descriere cont bancar*'
                                        value={values.bankAccountDescription}
                                        onChange={handleChange('bankAccountDescription')}
                                        onBlur={handleBlur('bankAccountDescription')}
                                        name='bankAccountDescription'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.bankAccountDescription}
                                        fullWidth
                                    />
                                    <Select
                                        label="Adresă cont bancar"
                                        value={values.clientAddressId}
                                        options={clientAddresses}
                                        onChange={(e) => setFieldValue('clientAddressId', e)}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) =>
                                            `${option.address}${
                                                option.address_description
                                                    ? ' (' + option.address_description + ')'
                                                    : ''
                                            }`
                                        }
                                        onBlur={handleBlur('clientAddressId')}
                                        name="clientAddressId"
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.clientAddressId}
                                        isClearable
                                        fullWidth
                                    />
                                </Form>
                                <div className="buttons-container">
                                    <Button
                                        title='Salvează'
                                        onClick={handleSubmit}
                                        loading={isLoading}
                                        disabled={!isValid}
                                        type='submit'
                                        fullWidth
                                    />
                                </div>
                            </>
                        )}
                    </Formik>
                </div>
            ) : isLoading ? (
                <div className="loader-container">
                    <Loader size='large' />
                </div>
            ) : null}
        </Slideover>
    )
}

const mapStateToProps = (state) => ({
    open: state.slideovers.type === slideoverTypes.ADD_CLIENT_BANK_ACCOUNT,
    clientBankAccountsOptions: state.clientBankAccounts.options,
    client: state.clients.currentClient,
    clientAddresses: _values(state.clientAddresses.data),
    isLoading: state.clientBankAccounts.isLoading,
    fieldErrors: state.clientBankAccounts.fieldErrors,
    nonFieldErrors: state.clientBankAccounts.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    getClientBankAccountsOptions: () => dispatch(RESOURCES.clientBankAccounts.getOptions()),
    addClientBankAccount: (values) => dispatch(RESOURCES.clientBankAccounts.create(values)),
    listAllClientAddresses: (entityID, clientID) =>
        dispatch(RESOURCES.clientAddresses.listAll({entity_id: entityID, client_id: clientID}))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddClientBankAccountSlideover)

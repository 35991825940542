import React, {useCallback, useMemo} from 'react'

import {differenceInMinutes} from 'date-fns'
import {round} from 'lodash'

// @ts-ignore
import {getDiffTime, getIsoFromTime, toApiTimeFormat} from '../../../../../../utils'

import './TaskTimeLogContainer.scss'

interface TaskTimeLogContainerProps {
    log: any
    onClickLog?: (log: any) => void
}

export default function TaskTimeLogContainer({log, onClickLog}: TaskTimeLogContainerProps) {
    const formatTime = useCallback(
        (time: any) => {
            if (time) {
                const isoTime = getIsoFromTime(log.date, time)

                return toApiTimeFormat(isoTime, true)
            }

            return '-'
        },
        [log.date]
    )

    const height = useMemo(() => {
        const startISO = getIsoFromTime(log.date, log.start)
        const stopISO = getIsoFromTime(log.date, log.stop)
        const difference = differenceInMinutes(stopISO, startISO)

        round(difference / 15)
    }, [log.date, log.start, log.stop])

    const localOnClickLog = useCallback(() => {
        if (onClickLog) {
            onClickLog(log)
        }
    }, [log, onClickLog])

    return (
        <div
            className="task-time-log-container"
            onClick={localOnClickLog}
            style={{height: `${height}rem`, minHeight: '6rem'}}>
            <div className="task-project-container">
                <p className="task-project-name">{log.project?.name}</p>
                <p className="task-name">{log.task?.title || '-'}</p>
            </div>
            <div className="time-container">
                <p className="start-stop">
                    {formatTime(log.start)} - {formatTime(log.stop)}
                </p>
                <p className="diff-time">{getDiffTime(log)}</p>
            </div>
        </div>
    )
}

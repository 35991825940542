import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {TrashIcon} from '@heroicons/react/24/outline'
import {Form, Formik} from 'formik'

import {addMinutes, differenceInMinutes, getHours, getMinutes, isAfter, isSameDay, parseISO, setSeconds} from 'date-fns'
import _ from 'lodash'

// @ts-ignore
import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from 'redux/modals'

import {useDebouncedEffect} from 'hooks'
import {Dispatch} from 'redux'
import {debounceWait} from 'utils/constants'

import {ProjectMinimal} from 'types/api'
import {timeErrors} from 'utils/types'
// @ts-ignore
import {taskTimeLogsSchema} from '../../assets/validations'
// @ts-ignore
import {setTime, toApiDateFormat, toApiTimeFormat} from '../../utils'

import {Button} from 'components/Button'
import {ErrorsList} from 'components/ErrorComponents'
import {Loader} from 'components/Loader'
import {Modal} from 'components/Modal'
import {Select} from 'components/Select'
import {TaskCreatable} from 'components/TaskCreatable'
// @ts-ignore
import {DatePicker} from '../DatePicker'
// @ts-ignore
import {RequiredFieldsText} from '../RequiredFieldsText'
// @ts-ignore
import {Toggle} from '../Toggle'

import './EditTaskTimeLogModal.scss'

interface EditTaskTimeLogModalProps {
    open: boolean
    selectedEntityID: string
    selectedLog: any
    nonFieldErrors: any
    fieldErrors: any
    isLoading: boolean
    projects: any[]
    isLoadingProjects: boolean
    currentTask: any
    searchProjects: (search: string) => void
    retrieveTask: (taskID: string, params?: any) => void
    updateTimeLog: (data: any, taskTimeLogID: string) => void
    openDeleteTimeLog: () => void
}

export const EditTaskTimeLogModal = ({
    open,
    selectedLog,
    nonFieldErrors,
    fieldErrors,
    isLoading,
    projects,
    isLoadingProjects,
    searchProjects,
    currentTask,
    retrieveTask,
    updateTimeLog,
    openDeleteTimeLog,
    selectedEntityID
}: EditTaskTimeLogModalProps) => {
    const [projectsQuery, setProjectsQuery] = useState<string>('')
    const [selectedProject, setSelectedProject] = useState<ProjectMinimal | undefined | null>(null)
    const [timeError, setTimeError] = useState<any>(null)
    const [changeTask, setChangeTask] = useState<boolean>(false)

    // TODO: Use mutations and queries for this component
    useEffect(() => {
        if (!_.isEmpty(selectedLog) && !_.isNull(selectedLog.task_id)) {
            retrieveTask(selectedLog.task_id)
        }
    }, [retrieveTask, selectedLog])

    const handleFetchProjects = useCallback(() => {
        searchProjects(projectsQuery)
    }, [projectsQuery, searchProjects])

    const handleChangeProjectsSearchField = useCallback((value) => setProjectsQuery(value), [])

    useDebouncedEffect(handleFetchProjects, [projectsQuery], debounceWait)

    const onChangeInterval = useCallback((start, stop, setFieldValue) => {
        const diffInMin = differenceInMinutes(stop, start)

        setTimeError(null)

        if (_.isNull(start) || _.isNull(stop)) {
            setTimeError(timeErrors.missing_value)
        } else if (isAfter(start, stop)) {
            setFieldValue('time', null)
            setFieldValue('duration_billable', null)
            setTimeError(timeErrors.stop_before_start)
        } else if (diffInMin <= 1) {
            setFieldValue('stop', addMinutes(stop, 1))
            setFieldValue('time', setTime(new Date(), 0, 1, 0))
            setFieldValue('duration_billable', setTime(new Date(), 0, 1, 0))
        } else {
            const hours = parseInt((diffInMin / 60).toString())
            const mins = parseInt((diffInMin % 60).toString())

            setFieldValue('time', setTime(new Date(), hours, mins, 0))
            setFieldValue('duration_billable', setTime(new Date(), hours, mins, 0))
        }
    }, [])

    const onChangeTime = useCallback((time, stop, setFieldValue) => {
        // let hours = getHours(stop) - getHours(time) <= 24 ? getHours(stop) - getHours(time) : 24
        let hours = getHours(stop) - getHours(time)
        // const minutes = getMinutes(stop) - getMinutes(time) > 1 ? getMinutes(stop) - getMinutes(time) : 1
        let minutes = getMinutes(stop) - getMinutes(time)

        if (minutes < 0 && hours > 0) {
            minutes = 60 + minutes
            hours = hours - 1
        } else if (minutes < 0 && hours <= 0) {
            minutes = getMinutes(stop)
            hours = 0
            // TODO: Send error for the case beyond midnight.
        } else if (hours <= 0) {
            hours = 24 - hours
            // TODO: Send error for the case beyond midnight.
        }

        const auxStart = setTime(stop, hours, minutes, 0)

        setTimeError(null)

        if (!isSameDay(auxStart, stop)) {
            setTimeError(timeErrors.interval_too_long)
            setFieldValue('start', setTime(new Date(stop), 0, 0, 0))
        } else {
            setFieldValue('start', auxStart)
        }
    }, [])

    const getInitialProject = useCallback(() => {
        if (!_.isEmpty(currentTask) && !_.isNil(selectedLog.task_id)) {
            return _.find(projects, (project) => parseInt(project.id) === parseInt(currentTask.project_id))
        }

        return null
    }, [currentTask, projects, selectedLog?.task_id])

    const initialDate = useMemo(() => {
        if (!_.isEmpty(selectedLog)) {
            return parseISO(`${selectedLog.date}T${selectedLog.start}`)
        }

        return null
    }, [selectedLog])

    const getInitialDuration = useCallback(
        (durationKey = 'duration') => {
            if (!_.isEmpty(selectedLog) && !_.isNil(selectedLog[durationKey])) {
                // remove days from the duration
                const duration = _.split(selectedLog[durationKey], ' ')[1]

                return parseISO(`${selectedLog.date}T${duration}`)
            }

            return null
        },
        [selectedLog]
    )

    const getInitialDurationBillable = useCallback(() => {
        if (!_.isEmpty(selectedLog)) {
            if (!_.isNil(selectedLog.duration_billable)) {
                return getInitialDuration('duration_billable')
            }

            return getInitialDuration()
        }

        return null
    }, [getInitialDuration, selectedLog])

    const getInitialStartStop = useCallback(() => {
        let stop
        let start

        if (!_.isEmpty(selectedLog)) {
            start = new Date(parseISO(`${selectedLog.date}T${selectedLog.start}`))

            if (!_.isNull(selectedLog.stop)) {
                stop = new Date(parseISO(`${selectedLog.date}T${selectedLog.stop}`))
            }
        }

        return {start, stop}
    }, [selectedLog])

    const handleSetChangeTask = useCallback(
        (value) => {
            setChangeTask(value)

            if (_.isNull(selectedProject) && !_.isEmpty(currentTask)) {
                setSelectedProject(getInitialProject())
            }
        },
        [currentTask, getInitialProject, selectedProject]
    )

    return (
        <Modal
            open={open && !_.isEmpty(selectedLog)}
            title="Editează pontaj">
            {!_.isEmpty(selectedLog) ? (
                <>
                    <ErrorsList errors={nonFieldErrors} />
                    <Formik
                        initialValues={{
                            projectId: getInitialProject(),
                            taskId: !_.isNil(selectedLog?.task_id) ? currentTask : null,
                            date: initialDate ? new Date(initialDate) : null,
                            start: getInitialStartStop().start,
                            stop: getInitialStartStop().stop,
                            time: getInitialDuration(),
                            duration_billable: getInitialDurationBillable(),
                            description: selectedLog.description || ''
                        }}
                        validationSchema={taskTimeLogsSchema}
                        onSubmit={(values) => {
                            const taskID = !_.isNull(values.taskId) ? values.taskId.id : null

                            const taskTimeLogData = {
                                entity_id: selectedEntityID,
                                date: toApiDateFormat(values.date),
                                start: values.start ? toApiTimeFormat(setSeconds(values.start, 0)) : null,
                                stop: values.stop ? toApiTimeFormat(setSeconds(values.stop, 0)) : null,
                                description: values.description,
                                duration: !_.isNull(values.time)
                                    ? `${values.time.getHours()}:${values.time.getMinutes()}:00`
                                    : null,
                                duration_billable: !_.isNull(values.duration_billable)
                                    ? `${values.duration_billable.getHours()}:` +
                                      `${values.duration_billable.getMinutes()}:00`
                                    : null,
                                ...(changeTask ? {task_id: taskID} : {})
                            }

                            updateTimeLog(taskTimeLogData, selectedLog.id)
                        }}>
                        {({handleBlur, setFieldValue, handleSubmit, values, errors, touched, isValid}) => (
                            <Form className="edit-task-time-log-form-container">
                                {_.isNull(selectedLog.task_id) && (
                                    <>
                                        <Select
                                            label="Proiect"
                                            placeholder="Alege un proiect"
                                            value={values.projectId}
                                            options={projects}
                                            onChange={(option) => {
                                                setFieldValue('projectId', option)
                                                setFieldValue('taskId', null)
                                                setSelectedProject(option)
                                            }}
                                            onInputChange={(value) => handleChangeProjectsSearchField(value)}
                                            onBlur={handleBlur('projectId')}
                                            getOptionLabel={(option) => option.name}
                                            getOptionValue={(option) => option.id}
                                            name="projectId"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.projectId}
                                            loading={isLoadingProjects}
                                        />
                                        <TaskCreatable
                                            value={values.taskId}
                                            onBlur={handleBlur('taskId')}
                                            onChange={(option) => setFieldValue('taskId', option)}
                                            disabled={_.isNull(selectedProject)}
                                            name="taskId"
                                            errors={fieldErrors}
                                            frontendErrors={errors}
                                            touched={touched.taskId}
                                            projectId={selectedProject?.id || null}
                                        />
                                    </>
                                )}
                                <div className="edit-task-time-log-form-row asym-split">
                                    <DatePicker
                                        label="Data*"
                                        value={values.date}
                                        onChange={(value: any) => setFieldValue('date', new Date(value))}
                                        onBlur={handleBlur('date')}
                                        name="date"
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.date}
                                        fullWidth
                                    />
                                    <div className={`${!_.isNull(timeError) ? 'has-error' : ''}`}>
                                        <DatePicker
                                            label="Ore lucrate"
                                            value={values.time}
                                            onChange={(date: any) => {
                                                setFieldValue('time', date)
                                                setFieldValue('duration_billable', date)
                                                onChangeTime(date, values.stop, setFieldValue)
                                            }}
                                            timeFormat="HH:mm"
                                            timeSelect
                                            fullWidth
                                        />
                                    </div>
                                    <div className={`${!_.isNull(timeError) ? 'has-error' : ''}`}>
                                        <DatePicker
                                            label="Ore facturabile"
                                            value={values.duration_billable}
                                            timeFormat="HH:mm"
                                            disabled
                                            timeSelect
                                            fullWidth
                                        />
                                    </div>
                                </div>
                                <div className={`interval-picker-container ${!_.isNull(timeError) ? 'has-error' : ''}`}>
                                    <p className="interval-picker-label">Interval*</p>
                                    <div className="interval-datepickers-container">
                                        <DatePicker
                                            value={values.start}
                                            onChange={(date: any) => {
                                                setFieldValue('start', date)
                                                onChangeInterval(date, values.stop, setFieldValue)
                                            }}
                                            timeFormat="HH:mm"
                                            timeSelect
                                            fullWidth
                                        />
                                        <p>-</p>
                                        <DatePicker
                                            value={values.stop}
                                            onChange={(date: any) => {
                                                setFieldValue('stop', date)
                                                onChangeInterval(values.start, date, setFieldValue)
                                            }}
                                            timeFormat="HH:mm"
                                            timeSelect
                                            fullWidth
                                        />
                                    </div>
                                    {!_.isNull(timeError) && <p className="time-error-message">{timeError.message}</p>}
                                </div>
                                <div className="textarea-container">
                                    <label className="note-textarea-label">Notă activitate</label>
                                    <textarea
                                        value={values.description}
                                        className="note-textarea"
                                        placeholder="Adaugă o notă pentru activitate"
                                        onChange={(e) => setFieldValue('description', e.target.value)}
                                        rows={3}
                                    />
                                </div>
                                {!_.isNull(selectedLog.task_id) && (
                                    <>
                                        <Toggle
                                            label="Mută acest pontaj într-o altă sarcină"
                                            onChange={(value: any) => handleSetChangeTask(value)}
                                            checked={changeTask}
                                        />
                                        {changeTask && (
                                            <>
                                                <Select
                                                    label="Proiect"
                                                    placeholder="Alege un proiect"
                                                    value={values.projectId}
                                                    options={projects}
                                                    onChange={(option) => {
                                                        setFieldValue('projectId', option)
                                                        setFieldValue('taskId', null)
                                                        setSelectedProject(option)
                                                    }}
                                                    onInputChange={(value) => handleChangeProjectsSearchField(value)}
                                                    onBlur={handleBlur('projectId')}
                                                    getOptionLabel={(option) => option.name}
                                                    getOptionValue={(option) => option.id}
                                                    name="projectId"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.projectId}
                                                    loading={isLoadingProjects}
                                                />
                                                <TaskCreatable
                                                    value={values.taskId}
                                                    onBlur={handleBlur('taskId')}
                                                    onChange={(option) => setFieldValue('taskId', option)}
                                                    disabled={_.isNull(selectedProject)}
                                                    name="taskId"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.taskId}
                                                    projectId={selectedProject?.id || null}
                                                />
                                            </>
                                        )}
                                    </>
                                )}
                                <RequiredFieldsText />
                                <div className="buttons-container">
                                    <Button
                                        title="Sterge"
                                        onClick={openDeleteTimeLog}
                                        loading={isLoading}
                                        icon={() => <TrashIcon />}
                                        color="red"
                                        variant="text"
                                        fullWidth
                                    />
                                    <Button
                                        title="Salvează"
                                        onClick={handleSubmit}
                                        disabled={!isValid}
                                        loading={isLoading}
                                        color="secondary"
                                        type="submit"
                                        fullWidth
                                    />
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            ) : isLoadingProjects ? (
                <div className="loading-container">
                    <Loader />
                </div>
            ) : null}
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.EDIT_TASK_TIME_LOG,
    currentTimeLog: state.taskTimeLogs.currentTaskTimeLog,
    nonFieldErrors: state.taskTimeLogs.nonFieldErrors,
    fieldErrors: state.taskTimeLogs.fieldErrors,
    isLoading: state.taskTimeLogs.isLoading,
    projects: _.values(state.projects.searchData),
    isLoadingProjects: state.projects.isLoading,
    currentTask: state.tasks.currentTask,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    openDeleteTimeLog: () => dispatch(openModal(modalTypes.DELETE_TASK_TIME_LOG)),
    retrieveTask: (taskID: string, params?: any) => dispatch(RESOURCES.tasks.retrieve(taskID, params)),
    searchProjects: (search: string) => dispatch(RESOURCES_V1.projects.search(search)),
    updateTimeLog: (data: any, taskTimeLogID: string) => dispatch(RESOURCES.taskTimeLogs.update(data, taskTimeLogID))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditTaskTimeLogModal)

import React, {useMemo, useState} from 'react'

import {lightFormat} from 'date-fns'
import {filter, isEmpty, isNull, join, toString} from 'lodash'

import {connect} from 'react-redux'

import {upperSnakeCase} from '../../../../utils'
import {billingPaymentMethods, currencies, successBonusTypes, taxPercentageOptions} from '../../../../utils/types'

import {Button} from '../../../../components/Button'
import {DetailsCard} from '../../../../components/DetailsCard'
import {EditContractSettingsDisclaimerModal} from '../../../../components/EditContractSettingsDisclaimerModal'

import {EditContractForm} from './partials'

export const ContractSettings = ({contract, isLoading}) => {
    const [editMode, setEditMode] = useState(false)

    const isHourlyBlended = useMemo(() =>
        !isEmpty(contract) && contract.billing_payment_method === billingPaymentMethods.HOURLY_BLENDED.value
    , [contract])

    const rows = useMemo(() => {
        if(!isEmpty(contract)) {
            return filter([
                {
                    label: 'Proiecte',
                    value: !isEmpty(contract.projects) ?
                        join(contract.projects.map((project) => project.name), ', ') :
                        ''
                },
                {
                    label: 'Clienți',
                    value: !isEmpty(contract.clients) ?
                        join( contract.clients.map((client) => client.name), ', ') :
                        ''
                },
                {
                    label: 'Serie internă',
                    value: `${contract.internal_series_name} (${contract.internal_series_number})`
                },
                {
                    label: 'Serie barou',
                    value: `${contract.bar_series_name} (${contract.bar_series_number})`
                },
                {label: 'Obiect contract', value: contract.legal_object},
                {
                    label: 'Data semnării',
                    value: contract.signed_date ? lightFormat(new Date(contract.signed_date), 'dd/MM/yyyy') : ''
                },
                {
                    label: 'Data producere efente',
                    value: contract.start_date ? lightFormat(new Date(contract.start_date), 'dd/MM/yyyy') : ''
                },
                {
                    label: 'Data încetare',
                    value: contract.end_date ? lightFormat(new Date(contract.end_date), 'dd/MM/yyyy') : ''
                },
                {
                    label: 'Sumă de recuperat',
                    value: contract.amount_to_recover && contract.amount_to_recover_currency ?
                        `${contract.amount_to_recover} ${
                            currencies[upperSnakeCase(contract.amount_to_recover_currency)].label
                        }` :
                        ''
                },
                {
                    label: 'Tip facturare',
                    value: contract.billing_payment_method ?
                        billingPaymentMethods[upperSnakeCase(contract.billing_payment_method)].label :
                        ''
                },
                {
                    label: isHourlyBlended ? 'Ore minime' : null,
                    value: isHourlyBlended && contract.billing_minimum_hours ?
                        contract.billing_minimum_hours :
                        ''
                },
                {
                    label: 'Sumă',
                    value: contract.billing_amount ?
                        `${contract.billing_amount} ${currencies[upperSnakeCase(contract.billing_currency)].label}` :
                        ''
                },
                {
                    label: isHourlyBlended ? 'Sumă ore suplimentare' : null,
                    value: contract.billing_amount_for_extra_hours ?
                        `${contract.billing_amount_for_extra_hours} ${
                            currencies[upperSnakeCase(contract.billing_currency)].label
                        }` :
                        ''
                },
                {
                    label: 'Taxă adăugtă',
                    value: taxPercentageOptions[parseInt(contract.billing_tax_percentage)]?.label ||
                        `${contract.billing_tax_percentage}%`
                },
                {
                    label: 'Recurent',
                    value: !isNull(contract.billing_recurring_months)
                        ? `${contract.billing_recurring_months} luni`
                        : 'Nu'
                },
                {
                    label: 'Onorariu succes',
                    value:
                    !isNull(contract.billing_success_bonus_value) && !isNull(contract.billing_success_bonus_type) ?
                        `${contract.billing_success_bonus_value}${
                            contract.billing_success_bonus_type === successBonusTypes.PERCENT.value ?
                                '%' :
                                ` ${currencies[upperSnakeCase(contract.billing_success_bonus_currency)].label}`
                        }` :
                        ''
                },
                {label: 'Șablon', value: toString(contract.template.name)}
            ], (row) => !isNull(row.label))
        }

        return []
    }, [contract, isHourlyBlended])

    return (
        <div className="contract-details-page-container">
            <EditContractSettingsDisclaimerModal />
            {!isEmpty(contract) ? (
                <div className="contract-info-cards-container">
                    {editMode ? (
                        <EditContractForm handleExitEditMode={() => setEditMode(false)} />
                    ) : (
                        <DetailsCard
                            title='Setări contract'
                            rows={rows}
                            headerButton={() => (
                                <Button
                                    title='Editează'
                                    onClick={() => setEditMode(true)}
                                    size='small'
                                />
                            )}
                            loading={isLoading}
                        />

                    )}
                </div>
            ) : null}
        </div>
    )
}

const mapStateToProps = (state) => ({
    contract: state.contracts.currentContract,
    isLoading: state.contracts.isLoading
})

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(ContractSettings)
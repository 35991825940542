const colors = {
    primary: '#00acec',
    primaryDark: '#0078a5',
    primaryLight: '#3fcaff',
    avoGreen: '#31ab62',
    avoGreenLight: '#8edeae',
    avoGreenDark: '#185531',
    avoRed: '#ff544c',
    avoRedDark: '#a50700',
    avoRedLight: '#ffa9a5',
    avoOrange: '#f0833a',
    avoOrangeLight: '#f4a875',
    avoOrangeDark: '#8a3d0a'
}

export default colors

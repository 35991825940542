import React from 'react'

import {isEmpty} from 'lodash'

// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {closeModal, modalTypes} from 'redux/modals'

import {Button} from 'components/Button'
import {Modal} from 'components/Modal'

import '../../assets/scss/DeleteModals.scss'

interface DeleteTaskTimeLogModalProps {
    open: boolean
    selectedLog: any
    isLoading: boolean
    deleteTimeLog: (selectedLog: any) => void
    closeModal: () => void
}

function DeleteTaskTimeLogModal({
    open,
    selectedLog,
    isLoading,
    deleteTimeLog,
    closeModal
}: DeleteTaskTimeLogModalProps) {
    return (
        <Modal
            open={open && !isEmpty(selectedLog)}
            title="Șterge pontaj">
            <div className="delete-modal">
                <p className="message">Ești sigur că vrei să ștergi acest pontaj?</p>
                <div className="buttons-container">
                    <Button
                        title="Nu, renunță"
                        onClick={closeModal}
                        variant="outlined"
                        loading={isLoading}
                        color="gray"
                    />
                    <Button
                        title="Da, șterge"
                        onClick={() => deleteTimeLog(selectedLog)}
                        loading={isLoading}
                        color="red"
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.DELETE_TASK_TIME_LOG,
    isLoading: state.taskTimeLogs.isLoading
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteTimeLog: (selectedLog: any) => dispatch(RESOURCES.taskTimeLogs.destroy(selectedLog))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteTaskTimeLogModal)

import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {PlusIcon} from '@heroicons/react/24/solid'

import {eachDayOfInterval, isMonday, isSameDay, isSunday, isToday, nextSunday, previousMonday} from 'date-fns'
import {filter, forEach, sortBy, uniqBy, values} from 'lodash'

import {connect} from 'react-redux'

// @ts-ignore
import {classNames, roLocaleFormat, toApiDateFormat} from '../../../../../../utils'

import {Button} from 'components/Button'

import {TaskTimeLogContainer} from '../TaskTimeLogContainer'

import './CalendarWeekView.scss'

interface CalendarWeekViewProps {
    timeLogs: any[]
    selectedDay: Date
    onSelectDay: (day: any) => void
    onClickLog: (log: any) => void
    onAddLog: (log: any) => void
}

export const CalendarWeekView = ({timeLogs, selectedDay, onSelectDay, onClickLog, onAddLog}: CalendarWeekViewProps) => {
    // TODO: Fetch time logs using useQuery
    //  listTimeLogs({
    //     entity_id: selectedEntityID,
    //     owner_id: selectedEntityProfile.id,
    //     date__gte: toApiDateFormat(startOfWeek(selectedDay, {weekStartsOn: 1})),
    //     date__lte: toApiDateFormat(endOfWeek(selectedDay, {weekStartsOn: 1}))
    // })

    const getWeekdays = useCallback(() => {
        const intStart = isMonday(new Date(selectedDay)) ? new Date(selectedDay) : previousMonday(new Date(selectedDay))
        const intEnd = isSunday(new Date(selectedDay)) ? new Date(selectedDay) : nextSunday(new Date(selectedDay))

        return eachDayOfInterval({start: intStart, end: intEnd})
    }, [selectedDay])

    const [days, setDays] = useState(getWeekdays())

    useEffect(() => {
        setDays(getWeekdays())
    }, [getWeekdays])

    const colStarts = useMemo<any>(() => {
        return {
            0: 1,
            1: 3,
            2: 5,
            3: 7,
            4: 9,
            5: 11,
            6: 12
        }
    }, [])

    return (
        <div className="avo-week-calendar-outer-container">
            <div className="avo-week-calendar-inner-container">
                <div className="avo-week-calendar-header-container">
                    <div className="avo-week-calendar-mobile-header">
                        {days.map((day, dayIdx) => (
                            <button
                                key={dayIdx}
                                className="avo-week-calendar-header-day-container"
                                onClick={() => onSelectDay(day)}
                                type="button">
                                {roLocaleFormat(new Date(day), 'EEEEEE')}{' '}
                                <span
                                    className={classNames(
                                        'avo-week-calendar-header-day-number',
                                        isToday(new Date(day)) && 'is-today',
                                        isSameDay(new Date(selectedDay), new Date(day)) && 'is-selected'
                                    )}>
                                    {roLocaleFormat(new Date(day), 'd')}
                                </span>
                            </button>
                        ))}
                    </div>
                    <div className="avo-week-calendar-desktop-header">
                        {days.map((day, dayIdx) => (
                            <div
                                key={dayIdx}
                                className={classNames(
                                    'avo-week-calendar-header-day-container',
                                    `start-${colStarts[dayIdx]}`
                                )}>
                                <span
                                    className={classNames(
                                        isSameDay(new Date(selectedDay), new Date(day)) && 'selected-container'
                                    )}>
                                    {roLocaleFormat(new Date(day), 'E')}{' '}
                                    <span
                                        className={classNames(
                                            'avo-week-calendar-header-day-number',
                                            isToday(new Date(day)) && 'is-today'
                                        )}>
                                        {roLocaleFormat(new Date(day), 'd')}
                                    </span>
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="avo-week-calendar-body-container">
                    <div className="avo-week-calendar-body">
                        <div className="avo-week-calendar-body-day-container">
                            {days.map((day, dayIdx) => {
                                const dayTimeLogs = timeLogs[toApiDateFormat(day)]

                                return (
                                    <div
                                        key={dayIdx}
                                        className={classNames(
                                            'avo-week-calendar-day-content',
                                            `start-${colStarts[dayIdx]}`,
                                            dayIdx < days.length - 2 ? 'weekday' : 'weekend'
                                        )}>
                                        {values(dayTimeLogs).map((log) => (
                                            <TaskTimeLogContainer
                                                log={log}
                                                key={log.id}
                                                onClickLog={onClickLog}
                                            />
                                        ))}
                                        <Button
                                            icon={() => <PlusIcon />}
                                            onClick={() => onAddLog(day)}
                                            className="col-span-full"
                                            size="small"
                                            color="gray"
                                            fullWidth
                                        />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="avo-week-calendar-mobile-body">
                        {values(timeLogs[toApiDateFormat(selectedDay)]).map((log) => (
                            <TaskTimeLogContainer
                                log={log}
                                key={log.id}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => {
    const timeLogs: any = {}
    const sortedLogs = sortBy(state.taskTimeLogs.data, 'start')
    const uniqDates = uniqBy(sortedLogs, 'date')

    forEach(uniqDates, (d) => {
        timeLogs[d.date] = filter(sortedLogs, ['date', d.date])
    })

    return {timeLogs}
}

export default connect(mapStateToProps)(CalendarWeekView)

import {MutationOptions, useMutation} from '@tanstack/react-query'
// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {Invoice} from '../types/api'

import {
    cancelSmartBillInvoice,
    createSmartBillInvoice,
    deleteSmartBillInvoice,
    manualUpdateSmartBillInvoice
    // @ts-ignore
} from 'avoapp-react-common/dist/redux/smartbill'

export const useCreateSmartBillInvoice = (overrides?: MutationOptions) => {
    return useMutation({
        // @ts-ignore
        mutationFn: async (invoice: Invoice) => {
            return await performRequest(createSmartBillInvoice(invoice.id))
        },
        ...overrides
    })
}

export const useCancelSmartBillInvoice = (overrides?: MutationOptions) => {
    return useMutation({
        // @ts-ignore
        mutationFn: async (invoice: Invoice) => {
            return await performRequest(cancelSmartBillInvoice(invoice.id))
        },
        ...overrides
    })
}

export const useDeleteSmartBillInvoice = (overrides?: MutationOptions) => {
    return useMutation({
        // @ts-ignore
        mutationFn: async (invoice: Invoice) => {
            return await performRequest(deleteSmartBillInvoice(invoice.id))
        },
        ...overrides
    })
}

export const useManualUpdateSmartBillInvoice = (overrides?: MutationOptions) => {
    return useMutation({
        // @ts-ignore
        mutationFn: async (invoice: Invoice) => {
            return await performRequest(manualUpdateSmartBillInvoice(invoice.id))
        },
        ...overrides
    })
}

import React, {useMemo} from 'react'

import {useFormik} from 'formik'

import {sub} from 'date-fns'
import _ from 'lodash'

// @ts-ignore
import {searchTabs} from '../../pages/ProjectDetails/partials/ExternalData/constants'

// @ts-ignore
import {litigationsCheckData} from '../../assets/data/choices'
// @ts-ignore
import {objectKeysToSnakeCase, toApiDateFormat} from '../../utils'
import {MDSubscriptionSources} from '../../utils/constants'

import {Button} from 'components/Button'
import {Select} from 'components/Select'
// @ts-ignore
import {DatePicker} from '../DatePicker'
// @ts-ignore
import {Input} from '../Input'

interface FormProps {
    onSubmit: (values: any) => void
    isLoading: boolean
    isDisabled: boolean
}

interface SearchDataSourcesFormProps extends FormProps {
    selectedTab: string
}

export default function SearchDataSourcesForm({
    selectedTab,
    onSubmit,
    isLoading,
    isDisabled
}: SearchDataSourcesFormProps) {
    switch (selectedTab) {
        case searchTabs.SIMPLE:
            return (
                <SimpleSearchForm
                    onSubmit={onSubmit}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                />
            )

        case searchTabs.ADVANCED:
            return (
                <AdvancedSearchForm
                    onSubmit={onSubmit}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                />
            )

        default:
            return <React.Fragment />
    }
}

function SimpleSearchForm({onSubmit, isLoading, isDisabled}: FormProps) {
    const {values, touched, setFieldValue, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues: {
            source: MDSubscriptionSources.PORTAL,
            litigationNumber: ''
        },
        onSubmit: () => {
            const finalValue = {
                ...objectKeysToSnakeCase(values),
                source: values.source.value
            }

            onSubmit(finalValue)
        }
    })

    return (
        <form className="search-data-sources-form">
            <Select
                label="Sursa"
                value={values.source}
                name="source"
                options={_.values(MDSubscriptionSources)}
                onChange={(value: any) => setFieldValue('source', value)}
                disabled={isDisabled}
                fullWidth
            />
            <Input
                label="Număr dosar"
                value={values.litigationNumber}
                onChange={handleChange('litigationNumber')}
                onBlur={handleBlur('litigationNumber')}
                name="litigationNumber"
                touched={touched.litigationNumber}
                disabled={isDisabled}
                fullWidth
            />
            <Button
                title={values.source === MDSubscriptionSources.PORTAL ? 'Caută surse de date' : 'Mai departe'}
                onClick={handleSubmit}
                loading={isLoading}
                disabled={isDisabled}
                fullWidth
            />
        </form>
    )
}

function AdvancedSearchForm({onSubmit, isLoading, isDisabled}: FormProps) {
    const institutions = useMemo(() => {
        const keys = Object.keys(litigationsCheckData.institution)

        return keys.map((key) => ({
            value: litigationsCheckData.institution[key],
            label: key
        }))
    }, [])

    const {values, touched, setFieldValue, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues: {
            source: MDSubscriptionSources.PORTAL,
            partyName: '',
            object: '',
            institution: institutions[0],
            startDate: sub(new Date(), {years: 1}),
            stopDate: new Date()
        },
        onSubmit: () => {
            const finalValue = {
                source: values.source.value,
                party_name: values.partyName || null,
                object: values.object || null,
                institution: values.institution.value,
                start_date: toApiDateFormat(values.startDate),
                stop_date: toApiDateFormat(values.stopDate)
            }

            onSubmit(finalValue)
        }
    })

    return (
        <form className="search-data-sources-form">
            <Select
                label="Sursa"
                value={values.source}
                name="source"
                fullWidth
                disabled
            />
            <Input
                label="Nume parte"
                value={values.partyName}
                onChange={handleChange('partyName')}
                onBlur={handleBlur('partyName')}
                name="partyName"
                touched={touched.partyName}
                disabled={isDisabled}
                fullWidth
            />
            <Input
                label="Obiect"
                value={values.object}
                onChange={handleChange('object')}
                onBlur={handleBlur('object')}
                name="object"
                touched={touched.object}
                disabled={isDisabled}
                fullWidth
            />
            <Select
                label="Instituția"
                options={institutions}
                value={values.institution}
                name="institution"
                onChange={(value: any) => setFieldValue('institution', value)}
                disabled={isDisabled}
                fullWidth
            />
            <DatePicker
                label="Data start"
                value={values.startDate}
                onChange={(date: any) =>
                    _.isNil(date) ? setFieldValue('startDate', date) : setFieldValue('startDate', new Date(date))
                }
                onBlur={handleBlur('startDate')}
                name="startDate"
                touched={touched.startDate}
                disabled={isDisabled}
                fullWidth
            />
            <DatePicker
                label="Data stop"
                value={values.stopDate}
                onChange={(date: any) =>
                    _.isNil(date) ? setFieldValue('stopDate', date) : setFieldValue('stopDate', new Date(date))
                }
                onBlur={handleBlur('stopDate')}
                name="stopDate"
                touched={touched.stopDate}
                disabled={isDisabled}
                fullWidth
            />
            <Button
                title="Caută surse de date"
                onClick={handleSubmit}
                loading={isLoading}
                disabled={isDisabled}
                fullWidth
            />
        </form>
    )
}

import React, {Component} from 'react'
import {Link} from 'react-router-dom'

import './NoAccount.scss'

export default class NoAccount extends Component {
    render() {
        return <div></div>
         
        return (
            <div className="no-account-container">
                <div className="separator-container">
                    <div className="line-container">
                        <div className="line" />
                    </div>
                    <div className="text-container">
                        <span className="text">Nu ai cont incă?</span>
                    </div>
                </div>
                <div className="create-account-link-container">
                    <div>
                        <Link className="create-account-link" to='/register' >
                            Creează un cont nou acum
                        </Link>
                    </div>
                </div>
            </div>

        )
    }
}

import {toast} from 'react-toastify'
import {put, select, takeEvery} from 'redux-saga/effects'

// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {closeModal, modalTypes} from './modals'

const {tasks: resource} = RESOURCES

function* handleCreateTaskSuccess(): Generator<any, void, any> {
    toast.success('Sarcina a fost adaugată cu succes.')

    const openModal = yield select((state) => state.modals)

    if (openModal.type === modalTypes.ADD_TASK) {
        yield put(closeModal())
    }
}

function* handleUpdateTaskSuccess() {
    toast.success('Salvat.')
    yield put(closeModal())
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateTaskSuccess)
    yield takeEvery(resource.actions.update.success, handleUpdateTaskSuccess)
}

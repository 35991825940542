import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
import {useQueryParams} from 'use-query-params'

import {PlayIcon, StopIcon} from '@heroicons/react/24/outline'
import {keepPreviousData} from '@tanstack/react-query'

import {endOfDay, nextFriday, nextSunday, startOfDay} from 'date-fns'
import _, {isNull} from 'lodash'

// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {useMutationResourceUpdate} from 'mutations'
import {useQueryResourceList} from 'queries'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
// @ts-ignore
import {stopRunningTaskTimeLog} from '../../../../redux/taskTimeLogs'

import {Cell} from 'types/local'
// @ts-ignore
import {toApiDateFormat, toApiTimeFormat} from '../../../../utils'

import {Button} from 'components/Button'
import FilterHeader from 'components/Datatable/Filters/FilterHeader/FilterHeader'
import {FilterForm} from 'components/FilterForm'
import {ResourceFilter} from 'components/FilterForm/filters'
import {StackDatatable} from 'components/StackDatatable'
import {StackSectionConfig} from 'components/StackDatatable/types'
import {ToolTipWrapper} from 'components/ToolTipWrapper'
// @ts-ignore
import {AddTaskModal} from '../../../../components/AddTaskModal'
// @ts-ignore
import {Datatable} from '../../../../components/Datatable'

import {columns, isCompletedOptions} from './constants'
import {TableListFilter} from './filters'
import {StackTaskCard} from './partials'

import './TasksList.scss'

interface TasksList {
    currentTimeLog: any
    entityProfileID: string
    selectedEntityID: string
    anyModalIsOpen: boolean
    createTimeLog: (data: any) => void
    stopTaskTimeLog: (hasAutoStopped: boolean) => void
}

export const TasksList = ({
    entityProfileID,
    selectedEntityID,
    anyModalIsOpen,
    createTimeLog,
    stopTaskTimeLog,
    currentTimeLog
}: TasksList) => {
    const [query, setQuery] = useQueryParams(TableListFilter.mapping)
    const [useEffectApplied, setUseEffectApplied] = useState(false)

    useEffect(() => {
        if (!query.owner_id) {
            setQuery({...query, owner_id: {value: entityProfileID, label: 'Sarcinile mele'}})
        }
        setQuery({...query, is_completed: isCompletedOptions.FALSE})
        setUseEffectApplied(true)
    }, [entityProfileID, query, setQuery])

    const dates = useMemo(() => {
        const today = new Date()

        return {
            today: today,
            tomorrow: new Date().setDate(today.getDate() + 1),
            nextMonday: new Date().setDate(today.getDate() + (8 - today.getDay()))
        }
    }, [])

    const {data: todayTasksData, refetch: refetchTasks} = useQueryResourceList(
        RESOURCES.tasks,
        {
            entity_id: selectedEntityID,
            start__lte: toApiDateFormat(dates.tomorrow),
            ...TableListFilter.getAPIFilters(query)
        },
        {
            placeholderData: keepPreviousData,
            enabled: !anyModalIsOpen && useEffectApplied
        }
    )

    const todayTasks = useMemo(() => {
        return (todayTasksData as any)?.data
    }, [todayTasksData])

    const updateTaskMutation = useMutationResourceUpdate(RESOURCES.tasks, {
        onSuccess: () => {
            refetchTasks()
        }
    })

    const handleChangeCompletion = useCallback(
        (task: any) => {
            let taskData

            if (isNull(task.completion)) {
                const datetime = `${toApiDateFormat(new Date())}T${toApiTimeFormat(new Date())}`

                taskData = {completion: datetime}
            }

            updateTaskMutation.mutateAsync({...taskData, id: task.id})
        },
        [updateTaskMutation]
    )

    const handleStartTimer = useCallback(
        (task) => {
            createTimeLog({
                entity_id: selectedEntityID,
                task_id: task.id,
                date: toApiDateFormat(new Date()),
                start: toApiTimeFormat(new Date())
            })
        },
        [createTimeLog, selectedEntityID]
    )

    const handleStopTimer = useCallback(
        (hasAutoStopped = false) => {
            stopTaskTimeLog(hasAutoStopped)
        },
        [stopTaskTimeLog]
    )

    const labelAccessorFunction = useCallback(
        (item) => {
            if (item.id === entityProfileID) {
                return 'Sarcinile mele'
            }

            // @ts-ignore
            return item[TableListFilter.config.owner_id.labelAccessor]
        },
        [entityProfileID]
    )

    const sectionsConfig = useMemo<StackSectionConfig[]>(() => {
        const config: StackSectionConfig[] = []

        if ([5, 6, 0].includes(dates.today.getDay())) {
            config.push(
                ...[
                    {
                        sectionTitle: 'Următoarea săptămână',
                        resource: RESOURCES.tasks,
                        resourceFilters: {
                            is_planned: true,
                            start__gte: toApiDateFormat(startOfDay(dates.tomorrow)),
                            start__lte: toApiDateFormat(endOfDay(nextFriday(dates.today)))
                        },
                        useQueryOverrides: {
                            enabled: !anyModalIsOpen && useEffectApplied
                        },
                        render: (item: any) => <StackTaskCard task={item} />,
                        filterConfig: TableListFilter
                    },
                    {
                        sectionTitle: 'In viitor',
                        resource: RESOURCES.tasks,
                        resourceFilters: {
                            is_planned: true,
                            start__gte: toApiDateFormat(dates.nextMonday)
                        },
                        useQueryOverrides: {
                            enabled: !anyModalIsOpen && useEffectApplied
                        },
                        render: (item: any) => <StackTaskCard task={item} />,
                        filterConfig: TableListFilter
                    }
                ]
            )
        } else {
            config.push(
                ...[
                    {
                        sectionTitle: 'Restul săptămânii',
                        resource: RESOURCES.tasks,
                        resourceFilters: {
                            is_planned: true,
                            start__gte: toApiDateFormat(startOfDay(dates.tomorrow)),
                            start__lte: toApiDateFormat(endOfDay(nextSunday(dates.today)))
                        },
                        useQueryOverrides: {
                            enabled: !anyModalIsOpen && useEffectApplied
                        },
                        render: (item: any) => <StackTaskCard task={item} />,
                        filterConfig: TableListFilter
                    },
                    {
                        sectionTitle: 'In viitor',
                        resource: RESOURCES.tasks,
                        resourceFilters: {
                            is_planned: true,
                            start__gte: toApiDateFormat(dates.nextMonday)
                        },
                        useQueryOverrides: {
                            enabled: !anyModalIsOpen && useEffectApplied
                        },
                        render: (item: any) => <StackTaskCard task={item} />,
                        filterConfig: TableListFilter
                    }
                ]
            )
        }

        config.push({
            sectionTitle: 'Neplanificate',
            resource: RESOURCES.tasks,
            resourceFilters: {
                is_planned: false
            },
            useQueryOverrides: {
                enabled: !anyModalIsOpen && useEffectApplied
            },
            render: (item: any) => <StackTaskCard task={item} />,
            filterConfig: TableListFilter
        })

        return config
    }, [anyModalIsOpen, dates.nextMonday, dates.today, dates.tomorrow, useEffectApplied])

    return (
        <div className="page-info">
            <div className="page-info-extended">
                <div className="page-filters-container">
                    <div className="filters-header">
                        <p className="table-title">Listă sarcini</p>
                        <div className="w-52">
                            <ResourceFilter
                                value={query.owner_id}
                                filterName="owner_id"
                                // @ts-ignore
                                filterConfig={TableListFilter.config.owner_id}
                                setFieldValue={(_filterName, e) => {
                                    setQuery({...query, owner_id: e})
                                }}
                                selectedEntityID={selectedEntityID}
                                labelAccessorFunction={labelAccessorFunction}
                            />
                        </div>
                    </div>
                    <FilterHeader
                        filterable
                        appliedFilters={TableListFilter.getUIFilters(query)}
                        filtersForm={() => <FilterForm filterClass={TableListFilter} />}
                        handleRemoveFilter={(filter) => {
                            setQuery({
                                ...query,
                                [filter.key]: null
                            })
                        }}
                        searchable
                        searchPlaceholder="Caută sarcini"
                        searchDefaultValue={query.search}
                        onSearch={(event) =>
                            setQuery({
                                ...query,
                                search: event.target.value
                            })
                        }
                    />
                </div>
                <Datatable
                    title="Astăzi"
                    data={todayTasks?.results || []}
                    columns={[
                        ...columns,
                        {
                            Header: 'Ore Lucrate',
                            accessor: 'duration_as_hours',
                            Cell: ({value, row: {original: task}}: Cell) => (
                                <>
                                    {value || 0}{' '}
                                    {_.isEmpty(currentTimeLog) || !_.isNil(currentTimeLog.stop) ? (
                                        <Button
                                            size="small"
                                            icon={() => (
                                                <ToolTipWrapper text="Începe pontaj">
                                                    <PlayIcon
                                                        className="w-4"
                                                        onClick={() => handleStartTimer(task)}
                                                    />
                                                </ToolTipWrapper>
                                            )}
                                        />
                                    ) : currentTimeLog.task_id === task.id ? (
                                        <Button
                                            size="small"
                                            icon={() => (
                                                <ToolTipWrapper text="Opreste timer">
                                                    <StopIcon
                                                        className="w-4"
                                                        onClick={handleStopTimer}
                                                    />
                                                </ToolTipWrapper>
                                            )}
                                        />
                                    ) : (
                                        <Button
                                            size="small"
                                            icon={() => (
                                                <ToolTipWrapper text="Timerul este deja pornit pentru alta sarcina">
                                                    <PlayIcon className="w-4" />
                                                </ToolTipWrapper>
                                            )}
                                            disabled
                                        />
                                    )}
                                </>
                            ),
                            style: {
                                whiteSpace: 'nowrap',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                gap: '0.5rem'
                            }
                        },
                        {
                            Header: '',
                            accessor: 'id',
                            Cell: ({value: taskID, row: {original: task}}: Cell) => (
                                <div className="tasks-list-datatable-action-buttons-column">
                                    <Link to={`/tasks/${taskID}`}>
                                        <Button
                                            title="Vizualizare"
                                            variant="text"
                                            size="small"
                                        />
                                    </Link>
                                    <Button
                                        title={isNull(task.completion) ? 'Finalizează' : 'Activează'}
                                        color={isNull(task.completion) ? 'secondary' : 'gray'}
                                        onClick={() => handleChangeCompletion(task)}
                                        variant="text"
                                        size="small"
                                    />
                                </div>
                            )
                        }
                    ]}
                    nextPage={todayTasks?.pages.next}
                    previousPage={todayTasks?.pages.previous}
                    currentPage={todayTasks?.page}
                    totalPages={todayTasks?.number_of_pages}
                    pageSize={todayTasks?.page_size}
                    onChangePage={(page: number) => {
                        setQuery((prevQuery) => ({...prevQuery, page: page}))
                    }}
                    onChangePageSize={(pageSize: number) => {
                        setQuery((prevQuery) => ({...prevQuery, page_size: pageSize}))
                    }}
                />
                <StackDatatable sectionsConfig={sectionsConfig} />
            </div>
            <AddTaskModal />
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    anyModalIsOpen: !!state.modals.type,
    entityProfileID: state.localConfigs.entityProfileID,
    selectedEntityID: state.localConfigs.selectedEntityID,
    currentTimeLog: state.taskTimeLogs.currentTaskTimeLog
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    createTimeLog: (data: any) => dispatch(RESOURCES.taskTimeLogs.create(data)),
    stopTaskTimeLog: (hasAutoStopped = false) => dispatch(stopRunningTaskTimeLog(hasAutoStopped))
})

export default connect(mapStateToProps, mapDispatchToProps)(TasksList)

import React, {useCallback, useMemo} from 'react'
import {Link} from 'react-router-dom'

import {PlusIcon} from '@heroicons/react/24/solid'

import _ from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {useQueryResourceList} from '../../../../queries'
import {useDebouncedState} from '../../../../utils/hooks'

import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {Dropdown} from '../../../../components/Dropdown'

import {columns} from './constants'

export const Addendums = ({
    selectedEntityID,
    match: {
        params: {contractID}
    }
}) => {
    const [searchFilter, setSearchFilter] = useDebouncedState('', 300)
    const [page, setPage] = React.useState(1)

    const filters = useMemo(
        () => ({
            search: searchFilter,
            contract_id: contractID,
            entity_id: selectedEntityID,
            page_size: 15,
            page
        }),
        [contractID, page, searchFilter, selectedEntityID]
    )

    const {data: addendumsResult, isFetching} = useQueryResourceList(RESOURCES.addendums, filters)

    const handleChangeSearchField = (value) => setSearchFilter(value)

    const handleChangePage = useCallback((newPage) => {
        setPage(newPage)
    }, [])

    if (_.isNil(addendumsResult)) {
        return <React.Fragment />
    }

    return (
        <div className="page-project-show">
            <Datatable
                title="Listă acte adiționale"
                data={addendumsResult.data.results}
                headerButton={() => (
                    <Link to={`/contracts/addendums/add?initialContractId=${contractID}`}>
                        <Button
                            title="Adaugă act adițional"
                            icon={() => <PlusIcon />}
                        />
                    </Link>
                )}
                columns={[
                    ...columns,
                    {
                        Header: 'Acțiuni',
                        accessor: 'id',
                        Cell: ({value: addendumID, row: {original: addendum}}) => (
                            <div className="flex gap-2 items-center">
                                <Link to={`/contracts/addendums/${addendumID}/preview`}>
                                    <Button
                                        title="Vizualizare"
                                        variant="text"
                                        size="small"
                                    />
                                </Link>
                                <Dropdown
                                    title="Descarcă"
                                    disabled={_.isNil(addendum.file_meta?.url_public) && _.isNil(addendum.signed_file)}
                                    items={[
                                        {
                                            customContent: () =>
                                                addendum.file_meta?.url_public ? (
                                                    <a
                                                        href={addendum.file_meta.url_public}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="avo-dropdown-menu-item">
                                                        Descarcă
                                                    </a>
                                                ) : (
                                                    <React.Fragment />
                                                )
                                        },
                                        {
                                            customContent: () =>
                                                addendum.signed_file ? (
                                                    <a
                                                        href={addendum.signed_file}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        className="avo-dropdown-menu-item">
                                                        Descarcă fișier semnat
                                                    </a>
                                                ) : (
                                                    <React.Fragment />
                                                )
                                        }
                                    ]}
                                    variant="text"
                                    color="secondary"
                                    size="small"
                                />
                            </div>
                        ),
                        disableSortBy: true
                    }
                ]}
                loading={isFetching}
                previousPage={addendumsResult.data?.pages?.previous}
                currentPage={addendumsResult.data?.page}
                nextPage={addendumsResult.data?.pages?.next}
                totalPages={addendumsResult.data?.number_of_pages}
                onChangePage={(page) => handleChangePage(page)}
                searchable
                searchValue={searchFilter}
                searchPlaceholder="Caută acte adiționale"
                onSearch={(event) => handleChangeSearchField(event.target.value)}
            />
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID
})

export default connect(mapStateToProps)(Addendums)

import React, {useEffect} from 'react'
import {ArrowDownTrayIcon, ArrowUpTrayIcon} from '@heroicons/react/24/solid'

import {isEmpty, isNull} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'

import {Button} from '../../../../components/Button'

import './AddendumPreview.scss'
import DocumentViewer from '../../../../components/DocumentViewer/DocumentViewer'
import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {Loader} from '../../../../components/Loader'
import {SignedFileDropzone} from '../../../../components/SignedFileDropzone'
import {getTokenAndUploadFile} from '../../../../utils/files'
import {PencilSquareIcon} from '@heroicons/react/24/outline'
import {ALLOW_EDIT_FILE_EXTENSION} from '../../../../components/DocumentViewer/constants'

const AddendumPreview = ({match: {params: {addendumID}}, selectedEntityID, user}) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const [addendum, setAddendum] = React.useState({})
    const [fileMeta, setFileMeta] = React.useState(null)

    const handleDropSignedFile = async (selectedFile, documentID) => {
        if(!isEmpty(selectedFile)) {
            setIsLoading(true)
            const fileURL = await getTokenAndUploadFile(selectedFile, selectedEntityID)
            // updateDocument({signed_file: fileURL}, documentID)
            const response = await performRequest(RESOURCES.addendums.update({file: fileURL}, documentID))
            const {data} = response
            setFileMeta(data.file_meta)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const fetchAddendum = async () => {
            setIsLoading(true)
            const response = await performRequest(RESOURCES.addendums.retrieve(addendumID))
            const {data} = response
            setFileMeta(data.file_meta)
            setIsLoading(false)
            return data
        }

        if(addendumID) {
            fetchAddendum().then((data) => {setAddendum(data)})
        }
     
    }, [addendumID])

    return (
        <div className="page-info">
            <div className="addendum-preview-page-container">
                <div className="addendum-preview-page-header">
                    <div className="header-buttons-container">
                        {!isNull(addendum.file) && (
                            <>
                                {fileMeta && ALLOW_EDIT_FILE_EXTENSION.includes(fileMeta.extension) ? <a
                                    href={`/contracts/addendums/${addendumID}/edit`}
                                    rel="noopener noreferrer"
                                >
                                    <Button title="Editează" icon={() => <PencilSquareIcon/>} color="gray"/>
                                </a> : null}
                                <a
                                    href={addendum.file}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Button title="Descarcă" icon={() => <ArrowDownTrayIcon/>}/>
                                </a>
                                <SignedFileDropzone
                                    onChange={(selectedFile) => (
                                        handleDropSignedFile(selectedFile, addendumID)
                                    )}
                                    customButton={() =>
                                        <Button
                                            loading={isLoading}
                                            title="Încarcă"
                                            icon={() => <ArrowUpTrayIcon/>} color="orange"
                                        />
                                    }
                                />
                                {/* <UploadDocumentModal documentID={documentID} /> */}
                            </>
                        )}
                    </div>
                </div>

                {isLoading && (
                    <div className="loader-container">
                        <Loader/>
                    </div>)
                }

                {!!fileMeta && !isLoading ? (
                    <div className="addendum-preview-container">
                        <DocumentViewer fileMeta={fileMeta} user={user}/>
                    </div>
                ) : (
                    <p className="no-file">Nu există niciun fișier încărcat</p>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    user: state.users.user
})

export default connect(mapStateToProps)(AddendumPreview)

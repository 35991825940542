import React, {useCallback, useMemo} from 'react'
import {Link} from 'react-router-dom'

import _ from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {push} from 'connected-react-router'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'

import {CANCELED_STATE} from '../../../../utils/constants'

import {DeleteInvoiceModal} from '../../../../components/DeleteInvoiceModal'
import {Dropdown} from '../../../../components/Dropdown'

import {toast} from 'react-toastify'
import {useCancelSmartBillInvoice, useCreateSmartBillInvoice} from '../../../../mutations'
import {externalSyncActions} from '../../../Invoices/constants'
import './InvoiceActionButtons.scss'

export const InvoiceActionButtons = ({
    invoice,
    isLoading,
    openDeleteInvoiceModal,
    cancelInvoice,
    retrieveInvoice,
    openSyncInvoiceModal,
    usersPreferences
}) => {
    const isActionDisabled = useMemo(() => _.isNil(invoice) || isLoading, [invoice, isLoading])
    const onSmartBillFinished = useCallback(
        (data, action) => {
            const {errors, status} = data

            if (_.includes([403, 418], status)) {
                // SmartBill is not enabled for this entity. Missing API key or action not needed.
                return
            }

            if (status >= 400) {
                const errorMessage = _.get(
                    errors,
                    'non_field_errors.0.message',
                    'A apărut o eroare la sincronizarea cu SmartBill'
                )
                openSyncInvoiceModal(invoice.id, action, errorMessage)
            } else {
                toast('Factura a fost sincronizată cu succes în SmartBill', {type: 'success'})
                retrieveInvoice(invoice.id)
            }
        },
        [invoice, openSyncInvoiceModal, retrieveInvoice]
    )

    const {mutate: createSmartBillInvoice} = useCreateSmartBillInvoice({
        onSuccess: (data, variables, context) => {
            onSmartBillFinished(data, externalSyncActions.CREATE)
        }
    })
    const {mutateAsync: cancelSmartBillInvoice} = useCancelSmartBillInvoice({
        onSuccess: (data, variables, context) => {
            onSmartBillFinished(data, externalSyncActions.CANCEL)
        }
    })

    const renderDownloadButton = useCallback(
        (link, label) => (
            <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className={`avo-dropdown-menu-item ${_.isNil(link) || _.isEmpty(link) ? 'disabled' : ''}`}>
                {label}
            </a>
        ),
        []
    )

    const handleDeleteInvoice = useCallback(
        async (invoice) => {
            if (invoice.deletion_prevented) {
                const {status: cancelHTTPStatus} = await cancelSmartBillInvoice(invoice)
                if (cancelHTTPStatus === 400) {
                    return
                }
                cancelInvoice(invoice.id)
            } else {
                openDeleteInvoiceModal()
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [cancelInvoice, openDeleteInvoiceModal]
    )

    return (
        <div className="invoice-actions-buttons-container">
            <Dropdown
                title="Descarcă"
                disabled={isActionDisabled}
                items={[
                    {customContent: () => renderDownloadButton(invoice.file_pdf?.url_public, 'Descarcă factură')},
                    {
                        customContent: () =>
                            renderDownloadButton(invoice.reports_file_pdf?.url_public, 'Descarcă raport activitate')
                    },
                    {
                        customContent: () => (
                            <Link
                                to={`/invoices/${invoice.id}/payments`}
                                className="avo-dropdown-menu-item">
                                <p>Descarcă chitanțe</p>
                            </Link>
                        )
                    }
                ]}
                variant="text"
                color="primary"
                size="small"
            />

            <Dropdown
                title="Acțiuni"
                disabled={isActionDisabled}
                items={[
                    {
                        customContent: () => (
                            <Link
                                to={`/invoices/${invoice.id}/edit`}
                                className="avo-dropdown-menu-item">
                                <p>Editează factură</p>
                            </Link>
                        )
                    },
                    {
                        title: `${invoice.deletion_prevented ? 'Anulează' : 'Șterge'} factura`,
                        onClick: async () => {
                            await handleDeleteInvoice(invoice)
                        }
                    },
                    ...(usersPreferences.has_smartbill_integration
                        ? [
                              {
                                  title: 'Adaugă factura în SmartBill',
                                  onClick: () => {
                                      createSmartBillInvoice(invoice)
                                  }
                              }
                          ]
                        : [])
                ]}
                variant="text"
                color="secondary"
                size="small"
            />
            <DeleteInvoiceModal selectedInvoice={invoice} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    invoice: state.invoices.currentInvoice,
    usersPreferences: state.usersPreferences.data,
    isLoading: state.invoices.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    navigate: (route) => dispatch(push(route)),
    openDeleteInvoiceModal: () => dispatch(openModal(modalTypes.DELETE_INVOICE)),
    openSyncInvoiceModal: (invoiceID, action, errorMessage) =>
        dispatch(openModal(modalTypes.INVOICE_EXTERNAL_SYNC_MODAL, {invoiceID, action, errorMessage})),
    cancelInvoice: (invoiceID) => dispatch(RESOURCES.invoices.update({state: CANCELED_STATE}, invoiceID)),
    retrieveInvoice: (invoiceID) => dispatch(RESOURCES.invoices.retrieve(invoiceID))
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceActionButtons)

import React, {useCallback, useMemo} from 'react'
import ReactSelect from 'react-select'

import _ from 'lodash'

// @ts-ignore
import {getErrorMessage} from '../../utils/errorHelper'

import {InputError} from 'components/ErrorComponents'

import {SelectProps} from './model'

import './Select.scss'

export default function Select({
    label,
    value,
    options,
    placeholder,
    onChange,
    onBlur,
    isMulti,
    isClearable,
    onInputChange,
    loading,
    size = 'medium',
    fullWidth,
    menuPlacement,
    getOptionLabel,
    getOptionValue,
    labelInline,
    noOptionsMessage,
    name,
    disabled,
    errors,
    frontendErrors,
    touched
}: SelectProps) {
    const snakeCaseName: string = _.snakeCase(name)
    const camelCaseName = _.camelCase(name)

    const hasErrors = useMemo(() => {
        return (
            (errors && !_.isEmpty(errors[snakeCaseName])) ||
            (frontendErrors && touched && !_.isEmpty(frontendErrors[camelCaseName]))
        )
    }, [camelCaseName, errors, frontendErrors, snakeCaseName, touched])

    const chooseErrorMessageToShow = useMemo(() => {
        if (!_.isNil(errors) && !_.isEmpty(errors[snakeCaseName])) {
            return getErrorMessage(errors[snakeCaseName][0], name)
        }

        if (!_.isNil(frontendErrors) && !_.isEmpty(frontendErrors[camelCaseName])) {
            return frontendErrors[camelCaseName]
        }
    }, [camelCaseName, errors, frontendErrors, name, snakeCaseName])

    const localOnInputChange = useCallback(
        (value) => {
            if (onInputChange !== undefined) {
                onInputChange(value)
            }
        },
        [onInputChange]
    )

    const localGetOptionLabel = useCallback(
        (option) => {
            if (getOptionLabel !== undefined) {
                return getOptionLabel(option)
            }

            return option.label
        },
        [getOptionLabel]
    )

    const localGetOptionValue = useCallback(
        (option) => {
            if (getOptionValue !== undefined) {
                return getOptionValue(option)
            }

            return option.value
        },
        [getOptionValue]
    )

    const renderNoOptionsMessage = useCallback(() => {
        if (noOptionsMessage !== undefined) {
            return noOptionsMessage()
        }

        return 'Fără rezultate '
    }, [noOptionsMessage])

    return (
        <div className={`avo-select-container ${labelInline ? 'label-inline' : ''}`}>
            {label && <label className="avo-select-label">{label}</label>}
            <ReactSelect
                value={value}
                options={options}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder || ''}
                onInputChange={localOnInputChange}
                // ! Not sure what this did before, but returning "false" is not valid
                // filterOption={onInputChange ? false : undefined}
                menuPlacement={menuPlacement ? menuPlacement : 'auto'}
                getOptionLabel={localGetOptionLabel}
                getOptionValue={localGetOptionValue}
                noOptionsMessage={renderNoOptionsMessage}
                menuPortalTarget={document.body}
                styles={{
                    input: (base) => ({...base, 'input:focus': {boxShadow: 'none'}}),
                    menuPortal: (base) => ({...base, zIndex: 9999})
                }}
                isLoading={loading}
                isDisabled={disabled}
                isClearable={isClearable}
                isMulti={isMulti}
                classNamePrefix="avo-select"
                className={`avo-select ${size} ${fullWidth ? 'full-width' : ''}`}
            />
            {hasErrors && <InputError message={chooseErrorMessageToShow} />}
        </div>
    )
}

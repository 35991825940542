import React, {useCallback, useEffect, useMemo, useState} from 'react'

import _ from 'lodash'
import {toast} from 'react-toastify'

// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {getCompaniesFromName} from 'avoapp-react-common/dist/redux/companies'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {closeModal, ModalType, modalTypes, openModal} from 'redux/modals'

// @ts-ignore
import {bpiAddTabs} from '../../pages/ProjectDetails/partials/ExternalData/constants'
import {ProjectMinimal} from '../../types/api'

import {BPISubscriptionInformationModal} from 'components/BPISubscriptionInformationModal'
import {ErrorComponent} from 'components/ErrorComponents/ErrorsList'
import {Modal} from 'components/Modal'
import {ProjectSelect} from 'components/ProjectSelect'
import {Tabs} from 'components/Tabs'

import CompaniesList from './CompaniesList'
import SearchDataSourcesForm from './SearchDataSourcesForm'

import './AddBPISubscriptionModal.scss'

interface AddBPISubscriptionModalProps {
    open: boolean
    project?: ProjectMinimal
    closeModal: () => void
    openModal: (modalType: string) => void
}

export const AddBPISubscriptionModal = ({open, project, closeModal, openModal}: AddBPISubscriptionModalProps) => {
    const [selectedTab, setSelectedTab] = useState<string>(bpiAddTabs.WITH_VAT_CODE)
    const [companiesResult, setCompaniesResult] = useState<any>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [selectedProject, setSelectedProject] = useState<ProjectMinimal | undefined>(project)

    const isNameFormSelected = useMemo(() => {
        return selectedTab === bpiAddTabs.WITH_NAME
    }, [selectedTab])

    useEffect(() => {
        if (!open) {
            setCompaniesResult(null)
        } else if (project) {
            setSelectedProject(project)
        }
    }, [open, project])

    const handleChangeTab = useCallback((tab) => setSelectedTab(tab), [])

    const onAddSubscription = useCallback(
        ({vatNumber, companyName}) => {
            setIsLoading(true)
            performRequest(
                RESOURCES.bpiSubscriptions.create({
                    project_id: selectedProject?.id,
                    vat_number: vatNumber,
                    company_name: companyName
                })
            ).then((res: any) => {
                setIsLoading(false)
                if (!res.errors) {
                    toast.success('Salvat')
                    closeModal()
                    openModal(modalTypes.BPI_SUBSCRIPTION_INFORMATION)
                } else {
                    _.forEach(_.values(res.errors), (error) => {
                        if (Array.isArray(error)) {
                            _.forEach(error, (err) => {
                                toast.error(err.message)
                            })
                        } else {
                            toast.error(error.message)
                        }
                    })
                }
            })
        },
        [closeModal, openModal, selectedProject]
    )

    const onSearchCompany = useCallback(({companyName}) => {
        setIsLoading(true)
        performRequest(getCompaniesFromName(companyName)).then((res: any) => {
            setIsLoading(false)
            setCompaniesResult(res)
        })
    }, [])

    const hasErrors = useMemo(() => {
        return (
            !_.isNil(companiesResult) &&
            _.isEmpty(companiesResult.data) &&
            !_.isEmpty(companiesResult.errors?.non_field_errors)
        )
    }, [companiesResult])

    return (
        <>
            <Modal
                open={open}
                title="Adaugă abondare Monitor BPI"
                maxWidth="80%"
                minWidth="1000px">
                <div className="add-bpi-subscription-modal-content">
                    <div className="search-data-sources-container">
                        <p className="section-title">Caută surse de date</p>
                        <Tabs
                            tabs={_.values(bpiAddTabs)}
                            selectedTab={selectedTab}
                            onChangeTab={handleChangeTab}
                        />
                        {!project && (
                            <div className="project-select">
                                <ProjectSelect
                                    value={selectedProject}
                                    onChange={setSelectedProject}
                                    fullWidth
                                />
                            </div>
                        )}
                        <SearchDataSourcesForm
                            selectedTab={selectedTab}
                            onAddSubscription={onAddSubscription}
                            onSearchCompany={onSearchCompany}
                            isLoading={isLoading}
                            isDisabled={!selectedProject}
                        />
                    </div>

                    {isNameFormSelected && (
                        <div className="results-list-container">
                            <p className="section-title">Alege o companie</p>
                            {hasErrors ? (
                                <ErrorComponent message={companiesResult.errors.non_field_errors.message} />
                            ) : (
                                <>
                                    {_.isNil(companiesResult) && (
                                        <p className="companies-list-empty">
                                            Aici vor aparea rezultatele in urma cautarii
                                        </p>
                                    )}

                                    {!_.isNil(companiesResult) && _.isEmpty(companiesResult?.data?.results) && (
                                        <p className="companies-list-empty">Nu s-au gasit companii cu numele acesta</p>
                                    )}

                                    {!_.isNil(companiesResult) && !_.isEmpty(companiesResult?.data?.results) && (
                                        <CompaniesList
                                            companies={companiesResult?.data?.results}
                                            onAddSubscription={onAddSubscription}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </div>
            </Modal>
            <BPISubscriptionInformationModal />
        </>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.ADD_MONITOR_BPI_SUBSCRIPTION
})

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal()),
    openModal: (modalType: ModalType) => dispatch(openModal(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddBPISubscriptionModal)

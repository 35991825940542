import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {useFormik} from 'formik'

import {addMinutes, differenceInMinutes, formatISO, isAfter} from 'date-fns'
import {
    values as _values,
    cloneDeep,
    concat,
    map,
    difference,
    filter,
    find,
    forEach,
    get,
    head,
    isEmpty,
    isNil,
    flatten,
    includes,
    isNull,
    isUndefined,
    toString, isArray, camelCase
} from 'lodash'

import {getExchangeRate} from 'avoapp-react-common/dist/redux/exchangeRate'
import {RESOURCES, RESOURCES_V1} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {closeModal, modalTypes, openModal} from '../../redux/modals'

import {invoicesSchema} from '../../assets/validations'
import {getFieldOptions, setTime, toApiDateFormat} from '../../utils'
import {roundFixed} from '../../utils/math'
import {RON_CURRENCY_VALUE, debounceWait, ronCurrency, CANCELED_STATE} from '../../utils/constants'
import {useDebouncedEffect, useQueryArgs} from '../../utils/hooks'
import {documentSeriesTypes, timeErrors} from '../../utils/types'

import {AddDiscountModal} from '../../components/AddDiscountModal'
import {AddStornoModal} from '../../components/AddStornoModal'
import {Button} from '../../components/Button'
import {DatePicker} from '../../components/DatePicker'
import {ErrorsList} from '../../components/ErrorComponents'
import {Input} from '../../components/Input'
import {InvoiceEntriesSection} from '../../components/InvoiceEntriesSection'
import {InvoiceInfobox} from '../../components/InvoiceInfobox'
import {InvoiceTotalErrors} from '../../components/InvoiceTotalErrors'
import {RequiredFieldsText} from '../../components/RequiredFieldsText'
import {ResetInvoiceEntriesInfoModal} from '../../components/ResetInvoiceEntriesInfoModal'
import {Select} from '../../components/Select'

import {documentTemplateTypes} from './constants'

import './InvoiceAdd.scss'
import {Loader} from '../../components/Loader'
import {ErrorComponent} from '../../components/ErrorComponents/ErrorsList'

const InvoiceAdd = ({
    isLoading,
    nonFieldErrors,
    fieldErrors,
    selectedEntityID,
    contracts,
    isLoadingContracts,
    projects,
    isLoadingProjects,
    searchProjects,
    searchContracts,
    createInvoice,
    invoicesOptions,
    getInvoicesOptions,
    getExchangeRate,
    exchangeRate,
    entityProfiles,
    listEntityProfiles,
    documentTemplates,
    isLoadingDocumentTemplates,
    isLoadingDocumentSeries,
    listAllDocumentSeries,
    listAllDocumentTemplates,
    documentSeries,
    reportTemplates,
    clients,
    isLoadingClients,
    searchClients,
    taskTimeLogs,
    listTaskTimeLogs,
    openModal,
    closeModal
}) => {
    const query = useQueryArgs()

    const initialClientId = useMemo(() => {
        return query.get('initialClientId')
    }, [query])

    const initialProjectId = useMemo(() => {
        return query.get('initialProjectId')
    }, [query])

    const initialClient = useMemo(() => {
        if(initialClientId) {
            return find(clients, (client) => toString(client.id) === toString(initialClientId))
        } else if(initialProjectId) {

        }

        return undefined
    }, [clients, initialClientId, initialProjectId])

    const initialProject = useMemo(() => {
        if(initialProjectId) {
            return find(projects, (project) => toString(project.id) === toString(initialProjectId))
        }

        return undefined
    }, [initialProjectId, projects])

    const [clientsQuery, setClientsQuery] = useState('')
    const [selectedClient, setSelectedClient] = useState(initialClient)

    const [projectsQuery, setProjectsQuery] = useState('')
    const [selectedProject, setSelectedProject] = useState(initialProject)

    const [contractsQuery, setContractsQuery] = useState('')

    const [timeError, setTimeError] = useState(null)
    const [selectedInvoiceCurrency, setSelectedInvoiceCurrency] = useState(ronCurrency)
    const [tempCurrency, setTempCurrency] = useState(undefined)
    const [currentExchangeRate, setCurrentExchangeRate] = useState(null)
    const [reportsTemplateError, setReportsTemplateError] = useState('')

    useEffect(() => {
        if(isNil(currentExchangeRate)) {
            setCurrentExchangeRate(exchangeRate)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exchangeRate])

    useEffect(() => {
        getInvoicesOptions()
        getExchangeRate()
        listAllDocumentTemplates({entity_id: selectedEntityID})
        listAllDocumentSeries({entity_id: selectedEntityID})
        listEntityProfiles(selectedEntityID)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFetchClients = useCallback((query = clientsQuery ) => {
        let params

        if(!isNil(initialProjectId) && isNil(selectedClient)) {
            params = {project_id: initialProjectId}
        }

        searchClients(query, params)
    }, [clientsQuery, initialProjectId, searchClients, selectedClient])

    const handleChangeClientsSearchField = (value) => setClientsQuery(value)

    useDebouncedEffect(handleFetchClients, [clientsQuery], debounceWait)

    const handleFetchProjects = useCallback((query = projectsQuery) => {
        let params

        if(!isNil(selectedClient)) {
            params = {client_id: selectedClient.id}
        }

        searchProjects(query, params)
    }, [projectsQuery, searchProjects, selectedClient])

    const handleChangeProjectsSearchField = (value) => setProjectsQuery(value)

    useDebouncedEffect(handleFetchProjects, [projectsQuery], debounceWait)

    useEffect(() => {
        if(!isNil(selectedClient)) handleFetchProjects()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedClient])

    const handleFetchContracts = useCallback((query = contractsQuery) => {
        if(!isUndefined(selectedProject) && !isNull(selectedProject)) {
            let params

            params = {project_id: selectedProject.id}

            searchContracts(query, params)
        }
    }, [contractsQuery, searchContracts, selectedProject])

    const handleChangeContractsSearchField = (value) => setContractsQuery(value)

    useDebouncedEffect(handleFetchContracts, [contractsQuery], debounceWait)

    useEffect(() => {
        if(!isNil(selectedProject)) handleFetchContracts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProject])

    const currencies = useMemo(() => {
        return getFieldOptions(invoicesOptions, 'currency')
    }, [invoicesOptions])

    const selectIfOnlyOption = useCallback((options) => {
        if(!isEmpty(options) && options.length === 1) return head(options)
        return null
    }, [])

    const {
        handleChange,
        setFieldValue,
        handleBlur,
        handleSubmit,
        values,
        errors,
        touched,
        isValid
    } = useFormik({
        initialValues: {
            clientId: initialClient,
            projectId:  initialProject,
            contractId: undefined,
            documentTemplateId: null,
            seriesId: null,
            issueDate: new Date(),
            dueDate: new Date(),
            currency: ronCurrency,
            exchangeRate: '',
            reportsTemplateId: null,
            reportsStart: null,
            reportsStop: null,
            entries: []
        },
        validationSchema: invoicesSchema.main,
        onSubmit: (values) => {
            const invoiceData = {
                entity_id: selectedEntityID,
                client_id: values.clientId?.id || null,
                project_id: values.projectId?.id || null,
                contract_id: values.contractId?.id || null,
                template_id: values.documentTemplateId?.id || null,
                series_id: values.seriesId?.id || null,
                issue_date: formatISO(values.issueDate, {representation: 'date'}),
                due_date: formatISO(values.dueDate, {representation: 'date'}),
                currency: values.currency.value,
                exchange_rate: values.exchangeRate,
                reports_template_id: values.reportsTemplateId?.id || null,
                reports_start: toApiDateFormat(values.reportsStart),
                reports_stop: toApiDateFormat(values.reportsStop),
                entries: values.entries.map((entry) => ({
                    name: entry.name,
                    storno_id: entry.stornoID,
                    storno_entry_ids: entry.stornoEntryIDs,
                    unit: entry.unit.value,
                    quantity: entry.quantity,
                    tax_percentage: entry.tax.value,
                    tax_name: 'VAT',
                    tax_value: entry.taxValue,
                    tax_value_RON: entry.taxValueRON,
                    price: entry.price,
                    is_discount: entry.isDiscount,
                    currency: entry.currency.value,
                    exchange_rate: entry.exchangeRate
                })),
                total: invoiceTotal.totalWithoutTax,
                total_RON: invoiceTotal.totalWithoutTaxRON,
                total_VAT: invoiceTotal.tax,
                total_VAT_RON: invoiceTotal.taxRON,
                total_with_VAT: invoiceTotal.total,
                total_with_VAT_RON: invoiceTotal.totalRON
            }

            createInvoice(invoiceData)
        }
    })

    useEffect(() => {
        if(isNil(values.clientId)) {
            const client = selectIfOnlyOption(clients)

            if(!isNil(client)) {
                setFieldValue('clientId', client)
                setSelectedClient(client)
            }
        }
    }, [clients, selectIfOnlyOption, setFieldValue, values.clientId])

    useEffect(() => {
        if(isNil(values.projectId) && !isNil(values.clientId)) {
            let project

            if(!isNil(initialProjectId)) {
                project = find(projects, (project) => toString(project.id) === toString(initialProjectId))
            } else {
                project = selectIfOnlyOption(projects)
            }

            if(!isNil(project)) {
                setFieldValue('projectId', project)
                setSelectedProject(project)
            }
        }
    }, [initialProjectId, projects, selectIfOnlyOption, setFieldValue, values.clientId, values.projectId])

    useEffect(() => {
        if (isLoadingProjects || isLoadingContracts) {
            setFieldValue('contractId', undefined)
        } else if(isUndefined(values.contractId) && !isNil(selectedProject)) {
            const contract = selectIfOnlyOption(contracts)
            if(!isNil(contract)) setFieldValue('contractId', contract)
        }
    }, [
        selectIfOnlyOption, selectedProject, isLoadingProjects, isLoadingContracts, contracts,
        setFieldValue, values.contractId
    ])

    useEffect(() => {
        if(isNil(values.documentTemplateId)) {
            const documentTemplate = selectIfOnlyOption(documentTemplates)

            if(!isNil(documentTemplate)) {
                setFieldValue('documentTemplateId', documentTemplate)
            }
        }
    }, [documentTemplates, selectIfOnlyOption, setFieldValue, values.documentTemplateId])

    useEffect(() => {
        if(isNil(values.seriesId)) {
            const series = selectIfOnlyOption(documentSeries )

            if(!isNil(series)) {
                setFieldValue('seriesId', series)
            }
        }
    }, [documentSeries, selectIfOnlyOption, setFieldValue, values.seriesId])
    //
    // useEffect(() => {
    //     if(isNil(values.reportsTemplateId)) {
    //         const reportTemplate = selectIfOnlyOption(reportTemplates)
    //
    //         if(!isNil(reportTemplate)) {
    //             setFieldValue('reportsTemplateId', reportTemplate)
    //         }
    //     }
    // }, [reportTemplates, selectIfOnlyOption, setFieldValue, values.reportsTemplateId])

    useEffect(() => {
        setFieldValue(
            'exchangeRate', values.currency.value === RON_CURRENCY_VALUE ? '' :
                get(exchangeRate, values.currency.value)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exchangeRate])

    const invoiceTotal = useMemo(() => {
        let totalWithoutTax = 0
        let totalWithoutTaxRON = 0

        let tax = 0
        let taxRON = 0

        if(values.currency.value === RON_CURRENCY_VALUE) {
            forEach(values.entries, (entry) => {
                const entryTotal = roundFixed(entry.quantity * entry.priceRON, 2)
                const entryTax = (entry.tax.value / 100) * entryTotal

                totalWithoutTax = roundFixed(totalWithoutTax + roundFixed(entryTotal, 2), 2)
                totalWithoutTaxRON = totalWithoutTax

                tax = roundFixed(tax + roundFixed(entryTax, 2), 2)
                taxRON = tax
            })
        } else {
            forEach(values.entries, (entry) => {
                const entryTotal = roundFixed(entry.quantity * entry.price, 2)
                const entryTax = (entry.tax.value / 100) * entryTotal

                const entryTotalRON = entry.quantity * entry.priceRON
                const entryTaxRON = (entry.tax.value / 100) * entryTotalRON

                totalWithoutTax = roundFixed(totalWithoutTax + roundFixed(entryTotal, 2), 2)
                tax = roundFixed(tax + roundFixed(entryTax, 2), 2)

                totalWithoutTaxRON = roundFixed(totalWithoutTaxRON + roundFixed(entryTotalRON, 2), 2)
                taxRON = roundFixed(taxRON + roundFixed(entryTaxRON, 2), 2)
            })
        }

        return {
            totalWithoutTax: totalWithoutTax,
            totalWithoutTaxRON: totalWithoutTaxRON,
            tax: tax,
            taxRON: taxRON,
            total: roundFixed(totalWithoutTax + tax, 2),
            totalRON: roundFixed(totalWithoutTaxRON + taxRON, 2)
        }
    }, [values.currency.value, values.entries])

    const onChangeInterval = (start, stop) => {
        const diffInMin = differenceInMinutes(stop, start)

        setTimeError(null)

        if ((start || stop) && isEmpty(values.reportsTemplateId)) {
            setReportsTemplateError('Șablonul de raport activitate este obligatoriu!')
        }
        if ((!start && !stop)) {
            setReportsTemplateError('')
        }

        if((isNull(start) && !isNull(stop)) || (isNull(stop) && !isNull(start))) {
            setTimeError(timeErrors.missing_value)
        } else if(isAfter(start, stop)) {
            setFieldValue('time', null)
            setTimeError(timeErrors.stop_before_start)
        } else if(diffInMin <= 1) {
            setFieldValue('stop', addMinutes(stop, 1))
            setFieldValue('time', setTime(new Date(), 0, 1, 0))
        } else {
            const hours = parseInt(diffInMin / 60)
            const mins = parseInt(diffInMin % 60)

            setFieldValue('time', setTime(new Date(), 0, mins, hours))
        }
    }

    const changeCurrency = useCallback(
        (e) => {
            setFieldValue('currency', e)
            setFieldValue(
                'exchangeRate',
                e.value === RON_CURRENCY_VALUE ? '' : get(exchangeRate, e.value)
            )
            setSelectedInvoiceCurrency(e)
        },
        [exchangeRate, setFieldValue]
    )

    const confirmChangeCurrency = useCallback(() => {
        closeModal()
        changeCurrency(tempCurrency)
        setTempCurrency(undefined)
        setFieldValue('entries', [])
    }, [changeCurrency, closeModal, setFieldValue, tempCurrency])

    const closeResetEntriesModal = useCallback(() => {
        setTempCurrency(undefined)
        closeModal()
    }, [closeModal])

    const hasReportInterval = useMemo(() => {
        return !isNil(values.reportsStart) && !isNil(values.reportsStop) && isNil(timeError)
    }, [timeError, values.reportsStart, values.reportsStop])

    const selectedReportInterval = useMemo(() => {
        if(hasReportInterval) {
            return {
                start: values.reportsStart,
                stop: values.reportsStop
            }
        }

        return undefined
    }, [hasReportInterval, values.reportsStart, values.reportsStop])

    const isUsingVat = useMemo(() => {
        const selectedEntity = entityProfiles.filter(({entity}) => entity.id === selectedEntityID)[0]?.entity
        return selectedEntity?.is_vat_payer
    }, [entityProfiles, selectedEntityID])

    useEffect(() => {
        if(hasReportInterval && !isNil(values.contractId)) {
            const timeLogsParams = {
                contract_id: values.contractId.id,
                entity_id: selectedEntityID,
                date__gte: toApiDateFormat(new Date(values.reportsStart)),
                date__lte: toApiDateFormat(new Date(values.reportsStop))
            }

            listTaskTimeLogs(timeLogsParams)
        }
    }, [
        values.reportsStart,
        values.reportsStop,
        hasReportInterval,
        listTaskTimeLogs,
        values.contractId,
        selectedEntityID
    ])

    const entriesErrorCodes = useMemo(
        () => {
            const entriesErrors = get(fieldErrors, 'entries', [])
            if (!entriesErrors || !isArray(entriesErrors)) {
                return []
            }

            const nonFieldErrors = map(entriesErrors, (entryError) => entryError.non_field_errors || [])
            const flattenedErrors = flatten(nonFieldErrors)

            return map(flattenedErrors, (error) => error.code || '')

        },
        [fieldErrors]
    )

    useEffect(() => {
        if (fieldErrors) {
            const elementName = camelCase(Object.keys(fieldErrors)[0])
            const element = document.getElementsByName(elementName)[0]

            if (element) {
                element.parentElement.parentElement.scrollIntoView({behavior: 'smooth'})
            }
        }
    }, [fieldErrors])

    return (
        <div className='resource-details-content'>
            <>
                <form className='add-invoice-form-container'>
                    <div className="add-invoice-form-row">
                        <Select
                            label='Client*'
                            value={values.clientId}
                            options={clients}
                            loading={isLoadingClients}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            onChange={(option) => {
                                setSelectedClient(option)
                                setFieldValue('clientId', option)
                                setFieldValue('projectId', null)
                                setFieldValue('contractId', undefined)
                                setFieldValue('entries', [])
                            }}
                            onInputChange={(value) => handleChangeClientsSearchField(value)}
                            onBlur={handleBlur('clientId')}
                            name='clientId'
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.clientId}
                            disabled={!isNil(initialClientId)}
                            isClearable
                            fullWidth
                        />
                        <Select
                            label='Proiect*'
                            value={values.projectId}
                            options={projects}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            onChange={(option) => {
                                setSelectedProject(option)
                                setFieldValue('projectId', option)
                                setFieldValue('contractId', undefined)
                            }}
                            onInputChange={(value) => handleChangeProjectsSearchField(value)}
                            onBlur={handleBlur('projectId')}
                            disabled={isNil(values.clientId) || !isNil(initialProjectId)}
                            name='projectId'
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.projectId}
                            loading={isLoadingProjects}
                            isClearable
                            fullWidth
                        />
                        <Select
                            label='Contract'
                            value={values.contractId}
                            options={contracts}
                            loading={isLoadingContracts}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            onChange={(e) => setFieldValue('contractId', e)}
                            onInputChange={(value) => handleChangeContractsSearchField(value)}
                            onBlur={handleBlur('contractId')}
                            name='contractId'
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.contractId}
                            disabled={isNil(values.projectId)}
                            isClearable
                            fullWidth
                        />
                    </div>
                    <div className="add-invoice-form-row">
                        <Select
                            label='Șablon factură*'
                            value={values.documentTemplateId}
                            options={documentTemplates}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => setFieldValue('documentTemplateId', e)}
                            onBlur={handleBlur('documentTemplateId')}
                            name='documentTemplateId'
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.documentTemplateId}
                            loading={isLoadingDocumentTemplates}
                            isClearable
                            fullWidth
                        />
                        <Select
                            label='Serie factură*'
                            value={values.seriesId}
                            options={documentSeries}
                            loading={isLoadingDocumentSeries}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => `${option.name} (${option.current_number})`}
                            onChange={(e) => setFieldValue('seriesId', e)}
                            onBlur={handleBlur('seriesId')}
                            disabled={isEmpty(documentSeries)}
                            name='seriesId'
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.seriesId}
                            fullWidth
                        />
                    </div>
                    <div className="add-invoice-form-row">
                        <DatePicker
                            label='Data emiterii*'
                            value={values.issueDate}
                            onChange={(date) => {
                                if (isNull(date)) {
                                    setFieldValue('issueDate', null)
                                } else {
                                    setFieldValue('issueDate', date)
                                    getExchangeRate(toApiDateFormat(date))
                                }
                            }}
                            onBlur={handleBlur('issueDate')}
                            name='issueDate'
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.issueDate}
                            fullWidth
                        />
                        <DatePicker
                            label='Data scadență*'
                            value={values.dueDate}
                            onChange={(date) => isNull(date) ?
                                setFieldValue('dueDate', date) :
                                setFieldValue('dueDate', new Date(date))
                            }
                            onBlur={handleBlur('dueDate')}
                            name='dueDate'
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.dueDate}
                            fullWidth
                        />
                    </div>
                    <div className="add-invoice-form-row">
                        <Select
                            label='Moneda*'
                            value={values.currency}
                            options={currencies}
                            onChange={(e) => {
                                if (isEmpty(values.entries)) {
                                    changeCurrency(e)
                                } else {
                                    openModal(modalTypes.RESET_ENTRIES_INFO)
                                    setTempCurrency(e)
                                }
                            }}
                            onBlur={handleBlur('currency')}
                            name='currency'
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.currency}
                            fullWidth
                        />
                        <ResetInvoiceEntriesInfoModal
                            onConfirm={confirmChangeCurrency}
                            onClose={closeResetEntriesModal}
                        />
                        {values.currency.value && values.currency.value !== RON_CURRENCY_VALUE && (
                            <Input
                                label='Curs Valutar*'
                                value={values.exchangeRate}
                                onChange={handleChange('exchangeRate')}
                                onBlur={handleBlur('exchangeRate')}
                                name='exchangeRate'
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.exchangeRate}
                                fullWidth
                            />
                        )}
                    </div>
                    <div className="add-invoice-form-row">
                        <Select
                            label='Șablon raport activitate'
                            value={values.reportsTemplateId}
                            options={reportTemplates}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            onChange={(e) => {
                                setFieldValue('reportsTemplateId', e)
                                if (isNull(e)) {
                                    if (values.reportsStart || values.reportsStop) {
                                        setReportsTemplateError('Raportul de activitate este obligatoriu!')
                                    }
                                } else {
                                    setReportsTemplateError('')
                                    setTimeError(timeErrors.missing_value)
                                }
                            }}
                            onBlur={handleBlur('reportsTemplateId')}
                            name='reportsTemplateId'
                            errors={fieldErrors}
                            frontendErrors={
                                {...errors, reportsTemplateId: errors.reportsTemplateId || reportsTemplateError}
                            }
                            touched={true}
                            loading={isLoadingDocumentTemplates}
                            isClearable
                            fullWidth
                        />
                        <div className={`interval-picker-container ${!isNull(timeError) ? 'has-error' : ''}`}>
                            <div className='interval-datepickers-container'>
                                <DatePicker
                                    label='Raport activitate start'
                                    value={values.reportsStart}
                                    onChange={(date) => {
                                        setFieldValue('reportsStart', date)
                                        onChangeInterval(date, values.reportsStop)
                                    }}
                                    fullWidth
                                />
                                <DatePicker
                                    label='Raport activitate stop'
                                    value={values.reportsStop}
                                    onChange={(date) => {
                                        setFieldValue('reportsStop', date)
                                        onChangeInterval(values.reportsStart, date)
                                    }}
                                    fullWidth
                                />
                            </div>
                            {!isNull(timeError) && <p className="time-error-message">{timeError.message}</p>}
                        </div>
                    </div>
                    <RequiredFieldsText/>
                    <InvoiceInfobox
                        interval={selectedReportInterval}
                        contract={values.contractId}
                        project={values.projectId}
                    />
                    {!isEmpty(entityProfiles) ? <InvoiceEntriesSection
                        entries={values.entries}
                        entriesErrors={get(fieldErrors, 'entries', [])}
                        invoiceContract={values.contractId}
                        invoiceCurrency={selectedInvoiceCurrency}
                        invoiceExchangeRate={values.exchangeRate}
                        handleAddEntry={(entry) => setFieldValue('entries', [...values.entries, entry])}
                        handleEditEntry={(entry, entryIdx) => {
                            const newEntries = cloneDeep(values.entries)
                            newEntries[entryIdx] = entry

                            setFieldValue('entries', newEntries)
                        }}
                        handleRemoveEntry={(entryToRemove) => {
                            const entriesToRemove = filter(values.entries, (entry) => (
                                entry === entryToRemove || (entry.isDiscount && entry.connectedEntry === entryToRemove)
                            ))

                            setFieldValue('entries', difference(values.entries, entriesToRemove))
                        }}
                        isUsingVat={isUsingVat}
                    /> : <Loader />}
                    {
                        fieldErrors && get(fieldErrors, ['entries', 'non_field_errors']) && (
                            <ErrorsList errors={get(fieldErrors, ['entries', 'non_field_errors'])} />
                        )
                    }

                    {
                        entriesErrorCodes && (
                            includes(entriesErrorCodes, 'product_already_storned') ||
                            includes(entriesErrorCodes, 'product_is_storno') ||
                            includes(entriesErrorCodes, 'entry_total_different_from_entries_sum')
                        ) && (
                            <div className="errors-list">
                                <ErrorComponent
                                    message={'Produsele taiate nu pot fi stornate. Va rugam sa le stergeti!'} />
                            </div>
                        )
                    }

                    <div className="totals-section">
                        <div className="buttons-container">
                            <Button
                                title='Adaugă storno'
                                onClick={() => openModal(modalTypes.ADD_STORNO)}
                                disabled={isNil(values.issueDate) || isNil(values.clientId) || isNil(values.currency)}
                                variant='outlined'
                                color='gray'
                                fullWidth
                            />
                            <AddStornoModal
                                handleAddStorno={(stornoData) => {
                                    const newEntries = cloneDeep(values.entries)
                                    const finalEntries = concat(newEntries, stornoData)

                                    setFieldValue('entries', finalEntries)
                                }}
                                invoiceIssueDate={values.issueDate}
                                invoiceClient={values.clientId}
                                invoiceCurrency={values.currency}
                            />
                            <Button
                                title='Adaugă discount'
                                onClick={() => openModal(modalTypes.ADD_DISCOUNT)}
                                disabled={isEmpty(values.entries)}
                                variant='outlined'
                                color='gray'
                                fullWidth
                            />
                            <AddDiscountModal
                                entries={values.entries}
                                invoiceTotal={invoiceTotal}
                                invoiceCurrency={selectedInvoiceCurrency}
                                invoiceExchangeRate={values.exchangeRate}
                                handleAddDiscount={(discountEntry, discountIndex) => {
                                    const newEntries = cloneDeep(values.entries)
                                    newEntries.splice(discountIndex, 0, discountEntry)

                                    setFieldValue('entries', newEntries)
                                }}
                            />
                        </div>
                        <div className="total-texts-container">
                            {isUsingVat && <>
                                <p className="total-text">
                                    Total fără TVA:{' '}
                                    <span>{invoiceTotal.totalWithoutTax} {values.currency.label}</span>
                                    {values.currency.value !== RON_CURRENCY_VALUE && (
                                        <span>{' '}({invoiceTotal.totalWithoutTaxRON} {ronCurrency.label})</span>
                                    )}
                                </p>
                                <p className="total-text">
                                    Total TVA:{' '}
                                    <span>{invoiceTotal.tax} {values.currency.label}</span>
                                    {values.currency.value !== RON_CURRENCY_VALUE && (
                                        <span>{' '}({invoiceTotal.taxRON} {ronCurrency.label})</span>
                                    )}
                                </p>
                            </>}
                            <p className="total-text">
                                TOTAL:{' '}
                                <span>{invoiceTotal.total} {values.currency.label}</span>
                                {values.currency.value !== RON_CURRENCY_VALUE && (
                                    <span>{' '}({invoiceTotal.totalRON} {ronCurrency.label})</span>
                                )}
                            </p>
                        </div>
                    </div>
                    <InvoiceTotalErrors/>
                    <ErrorsList errors={nonFieldErrors}/>
                    {
                        fieldErrors && get(fieldErrors, 'entity_id') && (
                            <ErrorsList errors={fieldErrors['entity_id']}/>
                        )
                    }
                    <Button
                        title='Adaugă factură'
                        onClick={handleSubmit}
                        disabled={!isValid || timeError}
                        loading={isLoading}
                        color='secondary'
                        type='submit'
                        fullWidth
                    />
                </form>
            </>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        invoicesOptions: state.invoices.options,
        exchangeRate: state.exchangeRate.data,
        entityProfiles: _values(state.entityProfiles.data),
        nonFieldErrors: state.invoices.nonFieldErrors,
        fieldErrors: state.invoices.fieldErrors,
        contracts: _values(state.contracts.searchData),
        isLoadingContracts: state.contracts.isLoading,
        projects: _values(state.projects.searchData),
        clients: _values(state.clients.searchData),
        isLoadingClients: state.clients.isLoading,
        isLoadingProjects: state.projects.isLoading,
        selectedEntityID: state.localConfigs.selectedEntityID,
        reportTemplates: filter(
            state.documentTemplates.data,
            ['type', find(documentTemplateTypes, ['value', 'document_reports']).value]
        ),
        documentTemplates: filter(
            state.documentTemplates.data,
            ['type', find(documentTemplateTypes, ['value', 'invoice']).value]
        ),
        isLoadingDocumentTemplates: state.documentTemplates.isLoading,
        documentSeries: filter(
            state.documentSeries.data,
            ['type', documentSeriesTypes.FISCAL_INVOICE.value]
        ),
        isLoadingDocumentSeries: state.documentSeries.isLoading,
        taskTimeLogs: _values(state.taskTimeLogs.data),
        isLoadingTaskTimeLogs: state.taskTimeLogs.isLoading
    }
}

const mapDispatchToProps = (dispatch) => ({
    getInvoicesOptions: () => dispatch(RESOURCES.invoices.getOptions()),
    getExchangeRate: (date= null) => dispatch(getExchangeRate(date)),
    searchClients: (search, params) => dispatch(RESOURCES.clients.search(search, {...params, active: 'true'})),
    searchProjects: (search, params) => dispatch(RESOURCES_V1.projects.search(search, {...params, active: 'true'})),
    searchContracts: (search, params) => dispatch(RESOURCES.contracts.search(
        search, {...params, state_not: [CANCELED_STATE]}
    )),
    createInvoice: (data) => dispatch(RESOURCES.invoices.create(data)),
    listAllDocumentTemplates: (params) => dispatch(RESOURCES.documentTemplates.listAll(params)),
    listAllDocumentSeries: (params) => dispatch(RESOURCES.documentSeries.listAll({...params, active: true})),
    listTaskTimeLogs: (params) => RESOURCES.taskTimeLogs.list(params),
    listEntityProfiles: (entityID) => dispatch(RESOURCES.entityProfiles.listAll({entity_id:  entityID})),
    openModal: (modalType) => dispatch(openModal(modalType)),
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceAdd)
import React from 'react'

import {PencilSquareIcon} from '@heroicons/react/24/outline'

import {connect} from 'react-redux'
import {ModalType, openModal} from 'redux/modals'

import './OpenModalColumn.scss'

interface EditableColumnProps {
    value: number | string
    modalType: ModalType
    openModal: (modalType: ModalType) => void
    onClick: () => any
}

const OpenModalColumn: React.FC<EditableColumnProps> = ({value, modalType, onClick, openModal}) => {
    return (
        <div className="open-modal-column">
            <div>{value}</div>
            <div
                onClick={() => {
                    onClick()
                    openModal(modalType)
                }}
                className="icon">
                <PencilSquareIcon />
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch: any) => ({
    openModal: (modalType: ModalType) => dispatch(openModal(modalType))
})

export default connect(undefined, mapDispatchToProps)(OpenModalColumn)

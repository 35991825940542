import {useFormik} from 'formik'
import fileDownload from 'js-file-download'
import React from 'react'
import {toast} from 'react-toastify'

import {keepPreviousData, useQuery} from '@tanstack/react-query'

import _ from 'lodash'

// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {closeModal, modalTypes} from 'redux/modals'

import {DocumentTemplate} from '../../../../types/api'

import {Button} from 'components/Button'
import {Select} from 'components/Select'
// @ts-ignore
import {Modal} from '../../../../components/Modal'
import {OptionType} from '../../../../components/Select/types'

import './TimeLogExportModal.scss'

interface TimeLogExportModalProps {
    selectedEntityID: string
    exportFilters: {[key: string]: string}
    open: boolean
    closeModal: () => void
}

const exportFormatOptions = [
    {
        value: 'docx',
        label: 'DOCX'
    },
    {
        value: 'pdf',
        label: 'PDF'
    }
]

const TimeLogExportModal: React.FC<TimeLogExportModalProps> = ({selectedEntityID, exportFilters, open, closeModal}) => {
    const {setFieldValue, handleSubmit, values, isSubmitting} = useFormik<{
        template?: DocumentTemplate
        format: OptionType
    }>({
        initialValues: {
            format: exportFormatOptions[0],
            template: undefined
        },
        onSubmit: async (values) => {
            const response = await performRequest(
                RESOURCES.taskTimeLogs.exportFile({
                    entity_id: selectedEntityID,
                    format: values.format.value,
                    template_id: values.template?.id,
                    ...exportFilters
                })
            )

            if (response.status === 200) {
                toast.success('În curând veți primi un email cu raportul dorit.')
                fileDownload(response.data, `raport-pontaj.${values.format.value}`)
                closeModal()
            } else if (response.status === 400) {
                toast.error('Nu există niciun șablon pentru raport pontaj.')
            } else {
                toast.error('A apărut o eroare la generarea raportului.')
            }
        }
    })

    const {data: templates, isLoading: templatesIsLoading} = useQuery({
        queryKey: [RESOURCES.documentTemplates.name],
        queryFn: async () => {
            const {data} = await performRequest(
                RESOURCES.documentTemplates.list({
                    entity_id: selectedEntityID,
                    type: 'time_logs_report'
                })
            )

            if (!values.template) {
                setFieldValue('template', data.results[0])
            }
            return data.results || []
        },
        placeholderData: keepPreviousData
    })

    return (
        <Modal
            open={open}
            title="Export report">
            <form className="time-log-export-form">
                <Select
                    label="Șablon*"
                    value={values.template}
                    onChange={(e: DocumentTemplate) => setFieldValue('template', e)}
                    options={templates}
                    disabled={_.isEmpty(templates)}
                    loading={templatesIsLoading}
                    getOptionValue={(option: DocumentTemplate) => option.id}
                    getOptionLabel={(option: DocumentTemplate) => option.name}
                    name="template"
                />
                <Select
                    label="Format*"
                    value={values.format}
                    onChange={(e: OptionType) => setFieldValue('format', e)}
                    options={exportFormatOptions}
                    name="format"
                />
                <hr />
                <Button
                    title="Descarcă"
                    onClick={handleSubmit}
                    loading={templatesIsLoading || isSubmitting}
                    color="secondary"
                    fullWidth
                />
            </form>
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.EXPORT_TASK_TIME_LOG,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch: any) => ({
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(TimeLogExportModal)

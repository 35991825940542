import React, {useCallback, useState} from 'react'

import {Form, Formik} from 'formik'

import {
    addMinutes,
    differenceInMinutes,
    getHours,
    getMinutes,
    isAfter,
    isSameDay,
    setHours,
    setMinutes,
    setSeconds,
    subHours
} from 'date-fns'
import _ from 'lodash'

// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {modalTypes} from 'redux/modals'

import {ProjectMinimal} from 'types/api'
// @ts-ignore
import {taskTimeLogsSchema} from '../../assets/validations'
// @ts-ignore
import {setTime, toApiDateFormat, toApiTimeFormat} from '../../utils'
import {timeErrors} from '../../utils/types'

import {Button} from 'components/Button'
import {ErrorsList} from 'components/ErrorComponents'
import {Modal} from 'components/Modal'
import {ProjectSelect} from 'components/ProjectSelect'
// @ts-ignore
import {DatePicker} from '../DatePicker'
// @ts-ignore
import {RequiredFieldsText} from '../RequiredFieldsText'
// @ts-ignore
import {TaskCreatable} from '../TaskCreatable'
// @ts-ignore
import {Toggle} from '../Toggle'

import './AddTaskTimeLogModal.scss'

interface AddTaskTimeLogModalProps {
    open: boolean
    dayToAdd?: Date
    taskToAdd?: any
    selectedEntityID: string
    nonFieldErrors: any
    fieldErrors: any
    isLoading: boolean
    createTimeLog: (data: any) => void
    updateTask: (taskData: any, taskID: string) => void
}

export const AddTaskTimeLogModal = ({
    open,
    dayToAdd,
    taskToAdd,
    selectedEntityID,
    nonFieldErrors,
    fieldErrors,
    isLoading,
    createTimeLog,
    updateTask
}: AddTaskTimeLogModalProps) => {
    const [selectedProject, setSelectedProject] = useState<ProjectMinimal | null | undefined>(undefined)
    const [timeError, setTimeError] = useState<any>(null)
    const [markSelectedTaskAsFinished, setMarkSelectedTaskAsFinished] = useState(false)

    const onChangeInterval = useCallback((start, stop, setFieldValue) => {
        const diffInMin = differenceInMinutes(stop, start)

        setTimeError(null)

        if (_.isNull(start) || _.isNull(stop)) {
            setTimeError(timeErrors.missing_value)
        } else if (isAfter(start, stop)) {
            setFieldValue('time', null)
            setFieldValue('duration_billable', null)
            setTimeError(timeErrors.stop_before_start)
        } else if (diffInMin <= 1) {
            setFieldValue('stop', addMinutes(stop, 1))
            setFieldValue('time', setTime(new Date(), 0, 1, 0))
            setFieldValue('duration_billable', setTime(new Date(), 0, 1, 0))
        } else {
            const hours = parseInt((diffInMin / 60).toString())
            const mins = parseInt((diffInMin % 60).toString())

            setFieldValue('time', setTime(new Date(), hours, mins, 0))
            setFieldValue('duration_billable', setTime(new Date(), hours, mins, 0))
        }
    }, [])

    const onChangeTime = useCallback((time, stop, setFieldValue) => {
        // let hours = getHours(stop) - getHours(time) <= 24 ? getHours(stop) - getHours(time) : 24
        let hours = getHours(stop) - getHours(time)
        // const minutes = getMinutes(stop) - getMinutes(time) > 1 ? getMinutes(stop) - getMinutes(time) : 1
        let minutes = getMinutes(stop) - getMinutes(time)

        if (minutes < 0 && hours > 0) {
            minutes = 60 + minutes
            hours = hours - 1
        } else if (minutes < 0 && hours <= 0) {
            minutes = getMinutes(stop)
            hours = 0
            // TODO: Send error for the case beyond midnight.
        } else if (hours <= 0) {
            hours = 24 - hours
            // TODO: Send error for the case beyond midnight.
        }

        const auxStart = setTime(stop, hours, minutes, 0)

        setTimeError(null)

        if (!isSameDay(auxStart, stop)) {
            setTimeError(timeErrors.interval_too_long)
            setFieldValue('start', setTime(new Date(stop), 0, 0, 0))
        } else {
            setFieldValue('start', auxStart)
        }
    }, [])

    const handleMarkTaskAsFinished = useCallback(
        (task) => {
            if (_.isNull(task.completion)) {
                updateTask(
                    {
                        completion: `${toApiDateFormat(new Date())}T${toApiTimeFormat(new Date())}`
                    },
                    task.id
                )
            }
        },
        [updateTask]
    )

    return (
        <Modal
            open={open}
            title="Adaugă pontaj">
            <ErrorsList errors={nonFieldErrors} />
            <Formik
                initialValues={{
                    projectId: undefined,
                    taskId: taskToAdd ? taskToAdd : null,
                    date: dayToAdd ? new Date(dayToAdd) : new Date(),
                    start: subHours(new Date(), 1),
                    stop: new Date(),
                    time: setHours(setMinutes(setSeconds(new Date(), 0), 0), 1),
                    duration_billable: setHours(setMinutes(setSeconds(new Date(), 0), 0), 1),
                    description: ''
                }}
                validationSchema={taskTimeLogsSchema}
                onSubmit={(values) => {
                    // TODO: Use mutations
                    const taskTimeLogData = {
                        entity_id: selectedEntityID,
                        date: toApiDateFormat(values.date),
                        start: toApiTimeFormat(setSeconds(values.start, 0)),
                        stop: toApiTimeFormat(setSeconds(values.stop, 0)),
                        project_id: !_.isNil(values.projectId) ? (values.projectId as any).id : null,
                        task_id: !_.isNull(values.taskId) ? values.taskId.id : null,
                        description: values.description,
                        duration: !_.isNull(values.time)
                            ? `${values.time.getHours()}:${values.time.getMinutes()}:00`
                            : null,
                        duration_billable: !_.isNull(values.duration_billable)
                            ? `${values.duration_billable.getHours()}:${values.duration_billable.getMinutes()}:00`
                            : null
                    }

                    createTimeLog(taskTimeLogData)

                    if (markSelectedTaskAsFinished) {
                        handleMarkTaskAsFinished(values.taskId)
                    }
                }}>
                {({handleBlur, setFieldValue, handleSubmit, values, errors, touched, isValid}) => (
                    <Form className="add-task-time-log-form-container">
                        {_.isUndefined(taskToAdd) ? (
                            <>
                                <ProjectSelect
                                    label="Proiect"
                                    value={values.projectId}
                                    placeholder="Alege un proiect"
                                    onChange={(option) => {
                                        setFieldValue('projectId', option)
                                        setSelectedProject(option)
                                        setFieldValue('taskId', null)
                                        setMarkSelectedTaskAsFinished(false)
                                    }}
                                    onBlur={handleBlur('projectId')}
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.projectId}
                                    isClearable
                                />
                                <TaskCreatable
                                    value={values.taskId}
                                    onBlur={handleBlur('taskId')}
                                    onChange={(option: any) => {
                                        setFieldValue('taskId', option)
                                        setMarkSelectedTaskAsFinished(false)
                                    }}
                                    disabled={_.isNil(selectedProject)}
                                    name="taskId"
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.taskId}
                                    projectId={selectedProject?.id || null}
                                />
                            </>
                        ) : null}
                        <div className="add-task-time-log-form-row asym-split">
                            <DatePicker
                                label="Data*"
                                value={values.date}
                                onChange={(value: any) => setFieldValue('date', new Date(value))}
                                onBlur={handleBlur('date')}
                                name="date"
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.date}
                                fullWidth
                            />
                            <div className={`${!_.isNull(timeError) ? 'has-error' : ''}`}>
                                <DatePicker
                                    label="Ore lucrate"
                                    value={values.time}
                                    onChange={(date: any) => {
                                        setFieldValue('time', date)
                                        setFieldValue('duration_billable', date)
                                        onChangeTime(date, values.stop, setFieldValue)
                                    }}
                                    timeFormat="HH:mm"
                                    timeSelect
                                    fullWidth
                                />
                            </div>
                            <div className={`${!_.isNull(timeError) ? 'has-error' : ''}`}>
                                <DatePicker
                                    label="Ore facturabile"
                                    value={values.duration_billable}
                                    timeFormat="HH:mm"
                                    disabled
                                    timeSelect
                                    fullWidth
                                />
                            </div>
                        </div>
                        <div className={`interval-picker-container ${!_.isNull(timeError) ? 'has-error' : ''}`}>
                            <p className="interval-picker-label">Interval*</p>
                            <div className="interval-datepickers-container">
                                <DatePicker
                                    value={values.start}
                                    onChange={(date: any) => {
                                        setFieldValue('start', date)
                                        onChangeInterval(date, values.stop, setFieldValue)
                                    }}
                                    timeFormat="HH:mm"
                                    timeSelect
                                    fullWidth
                                />
                                <p>-</p>
                                <DatePicker
                                    value={values.stop}
                                    onChange={(date: any) => {
                                        setFieldValue('stop', date)
                                        onChangeInterval(values.start, date, setFieldValue)
                                    }}
                                    timeFormat="HH:mm"
                                    timeSelect
                                    fullWidth
                                />
                            </div>
                            {!_.isNull(timeError) && <p className="time-error-message">{timeError.message}</p>}
                        </div>
                        <Toggle
                            label="Marchează sarcina selectată ca finalizată"
                            checked={markSelectedTaskAsFinished}
                            onChange={setMarkSelectedTaskAsFinished}
                            disabled={_.isNil(values.taskId)}
                        />
                        <div className="textarea-container">
                            <label className="note-textarea-label">Notă activitate</label>
                            <textarea
                                value={values.description}
                                className="note-textarea"
                                placeholder="Adaugă o notă pentru activitate"
                                onChange={(e) => setFieldValue('description', e.target.value)}
                                onBlur={handleBlur('description')}
                                rows={3}
                            />
                        </div>
                        <RequiredFieldsText />
                        <Button
                            title="Adaugă"
                            onClick={handleSubmit}
                            disabled={!isValid}
                            loading={isLoading}
                            color="secondary"
                            type="submit"
                            fullWidth
                        />
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.ADD_TASK_TIME_LOG,
    nonFieldErrors: state.taskTimeLogs.nonFieldErrors,
    fieldErrors: state.taskTimeLogs.fieldErrors,
    isLoading: state.taskTimeLogs.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    createTimeLog: (data: any) => dispatch(RESOURCES.taskTimeLogs.create(data)),
    updateTask: (taskData: any, taskID: string) => dispatch(RESOURCES.tasks.update(taskData, taskID))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddTaskTimeLogModal)

import React, {useCallback, useMemo} from 'react'

import {keepPreviousData} from '@tanstack/react-query'

// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {RESOURCES, RESOURCES_V2} from 'avoapp-react-common/dist/redux/spec'
import {useDebouncedState} from 'hooks'
import {useQueryResourceSearch} from 'queries'
import {connect} from 'react-redux'

import {Select, SelectProps} from 'components/Select'

interface TaskCreatableProps extends SelectProps {
    projectId: number | undefined | null
    entityProfileID: string
    selectedEntityID: string
    initialValue?: string
}

export const TaskCreatable = ({
    value,
    disabled,
    onChange,
    onBlur,
    name,
    errors,
    frontendErrors,
    touched,
    projectId,
    selectedEntityID,
    entityProfileID
}: TaskCreatableProps) => {
    const [tasksQuery, setTasksQuery, debouncedTasksQuery] = useDebouncedState('')

    const {
        data: tasksData,
        isFetching,
        isPlaceholderData
    } = useQueryResourceSearch(
        RESOURCES_V2.tasks,
        debouncedTasksQuery,
        {
            project_id: projectId,
            entity_id: selectedEntityID,
            is_completed: false,
            page_size: 15
        },
        {
            placeholderData: keepPreviousData,
            staleTime: 1000 * 5
        }
    )

    const tasks = useMemo(() => {
        return tasksData as any
    }, [tasksData])

    const handleChangeTasksSearchField = useCallback(
        (value: string) => {
            setTasksQuery(value)
        },
        [setTasksQuery]
    )

    const handleCreateTask = useCallback(() => {
        // TODO: Use mutation
        const taskData = {
            title: tasksQuery,
            project_id: projectId,
            entity_id: selectedEntityID,
            owners_ids: [entityProfileID],
            billable: true
        }

        performRequest(RESOURCES.tasks.create(taskData)).then((response: any) => {
            if (onChange) {
                onChange(response.data)
            }
            setTasksQuery('')
        })
    }, [entityProfileID, onChange, projectId, selectedEntityID, setTasksQuery, tasksQuery])

    const noOptionsMessage = useCallback(() => {
        return (
            <div
                className="avoapp-select-no-options-custom-message"
                onClick={handleCreateTask}>
                Crează sarcină <span>"{tasksQuery}"</span>
            </div>
        )
    }, [handleCreateTask, tasksQuery])

    return (
        <Select
            label="Sarcină"
            placeholder="Alege o sarcină"
            value={value}
            options={tasks}
            loading={isFetching || isPlaceholderData}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.title}
            onInputChange={handleChangeTasksSearchField}
            noOptionsMessage={noOptionsMessage}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            errors={errors}
            frontendErrors={frontendErrors}
            touched={touched}
            isClearable
            fullWidth
        />
    )
}

const mapStateToProps = (state: any) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    entityProfileID: state.localConfigs.entityProfileID
})

export default connect(mapStateToProps)(TaskCreatable)

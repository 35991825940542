import React, {useCallback, useMemo, useState} from 'react'

import {TrashIcon} from '@heroicons/react/24/outline'

import _ from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'

import {useQueryResourceList} from '../../../../queries'
import {useDebouncedState} from '../../../../utils/hooks'

import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'

import AddAppealModal from './AddAppealModal'
import DeleteAppealModal from './DeleteAppealModal'

function AppealsSection({subscription, selectedEntityID, isManualSubscription, openModal, openedModal}) {
    const [searchFilter, setSearchFilter] = useDebouncedState('', 300)
    const [page, setPage] = useState(1)
    const [selectedAppeal, setSelectedAppeal] = useState(undefined)

    const columns = useMemo(() => {
        return [
            {
                Header: 'Data',
                accessor: 'date'
            },
            {
                Header: 'Parte',
                accessor: 'party'
            },
            {
                Header: 'Tip',
                accessor: 'type'
            },
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({row}) => {
                    console.log('row.original: ', row.original)
                    return (
                        <div className="datatable-row-buttons-container">
                            <Button
                                icon={() => <TrashIcon />}
                                onClick={() => {
                                    setSelectedAppeal(row.original)
                                    openModal(modalTypes.DELETE_APPEAL)
                                }}
                                size="small"
                                color="red"
                            />
                        </div>
                    )
                }
            }
        ]
    }, [openModal])

    const filters = useMemo(
        () => ({
            subscription_id: subscription.id,
            search: searchFilter,
            entity_id: selectedEntityID,
            page_size: 5,
            page: page
        }),
        [page, searchFilter, selectedEntityID, subscription.id]
    )

    const {data: appealsResult, isFetching} = useQueryResourceList(
        RESOURCES.appeals,
        filters,
        undefined,
        //we want to refetch the data when the add or delete modal closes
        _.isNil(openedModal)
    )

    const handleChangeSearchField = useCallback((value) => setSearchFilter(value), [setSearchFilter])

    const handleChangePage = useCallback((newPage) => setPage(newPage), [])

    const renderAddButton = useCallback(() => {
        if (isManualSubscription) {
            return (
                <Button
                    title="Adaugă căi de atac"
                    onClick={() => openModal(modalTypes.ADD_APPEAL_MODAL)}
                    color="secondary"
                />
            )
        }
    }, [isManualSubscription, openModal])

    if (_.isNil(appealsResult)) {
        return <React.Fragment />
    }

    return (
        <>
            <Datatable
                title="Căi de atac"
                data={appealsResult.data?.results || []}
                columns={columns}
                loading={isFetching}
                previousPage={appealsResult.data?.pages?.previous}
                headerButton={renderAddButton}
                currentPage={appealsResult.data?.page}
                nextPage={appealsResult.data?.pages?.next}
                totalPages={appealsResult.data?.number_of_pages}
                onChangePage={(page) => handleChangePage(page)}
                searchable
                searchValue={searchFilter}
                searchPlaceholder="Caută căi de atac"
                onSearch={(event) => handleChangeSearchField(event.target.value)}
            />
            <AddAppealModal subscription={subscription} />
            <DeleteAppealModal appeal={selectedAppeal} />
        </>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    openedModal: state.modals.type
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType))
})

export default connect(mapStateToProps, mapDispatchToProps)(AppealsSection)

import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'

import {ArrowDownTrayIcon, TrashIcon} from '@heroicons/react/24/outline'
import {XMarkIcon} from '@heroicons/react/24/solid'

import {lightFormat} from 'date-fns'
import fileDownload from 'js-file-download'
import _, {isEmpty, isNil, toNumber} from 'lodash'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {removeFilter} from '../../../../redux/filters/filters'
import {generateFiltersForAPI} from '../../../../redux/filters/utils'
import {closeModal, modalTypes, openModal} from '../../../../redux/modals'

import {getFieldOptions} from '../../../../utils'
import {debounceWait} from '../../../../utils/constants'
import {datatablePageSize} from '../../../../utils/datatables'
import {useDebouncedEffect} from '../../../../utils/hooks'

import {AddInvoicePaymentModal} from '../../../../components/AddInvoicePaymentModal'
import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {DeleteInvoicePayment} from '../../../../components/DeleteInvoicePayment'
import {GenericWarningModal} from '../../../../components/GenericWarningModal'

import {FilterInvoicePaymentsForm} from '../forms'

import './InvoicePaymentsList.scss'
import {Loader} from '../../../../components/Loader'
import {externalSyncActions, externalSyncStates} from '../../constants'
import InvoiceExternalSyncIcon from '../../../InvoiceDetails/partials/InvoiceExternalSyncIcon/InvoiceExternalSyncIcon'
import {InvoiceExternalSyncResolveModal} from '../../../../components/InvoiceExternalSyncResolveModal'

export const InvoicePaymentsList = ({
    invoicePaymentsOptions,
    getInvoicePaymentsOptions,
    invoicePayments,
    isLoadingPayments,
    currentPaymentsPage,
    nextPaymentsPage,
    previousPaymentsPage,
    totalPaymentsPages,
    invoicePaymentsReports,
    isLoadingInvoicePaymentsReports,
    listInvoicePaymentsReports,
    listInvoicePayments,
    usersPreferences,
    selectedEntityID,
    filters,
    removeFilter,
    openInvoiceExternalSyncResolveModal,
    openModal,
    closeModal,
    openSyncInvoiceModal
}) => {
    const [searchFilter, setSearchFilter] = useState('')
    const [selectedPayment, setSelectedPayment] = useState(null)
    const [isPendingExport, setIsPendingExport] = useState(false)

    useEffect(() => { getInvoicePaymentsOptions() }, [getInvoicePaymentsOptions])

    const handleFetchInvoicePayments = useCallback((page) => {
        const appliedFilters = generateFiltersForAPI(filters)

        listInvoicePayments(selectedEntityID, searchFilter, appliedFilters, page)
    }, [filters, listInvoicePayments, searchFilter, selectedEntityID])

    useDebouncedEffect(handleFetchInvoicePayments, [searchFilter], debounceWait)

    const handleListInvoicePaymentsReports = useCallback((query = searchFilter, page = 1) => {
        const appliedFilters = generateFiltersForAPI(filters)

        listInvoicePaymentsReports(selectedEntityID, query, appliedFilters)
    }, [filters, listInvoicePaymentsReports, searchFilter, selectedEntityID])

    useDebouncedEffect(handleListInvoicePaymentsReports, [searchFilter], debounceWait)

    const handleChangeSearchField = (value) => setSearchFilter(value)

    const handleChangePage = (page) => !isLoadingPayments && handleFetchInvoicePayments(page)

    const invoicePaymentTypes = useMemo(() => {
        return getFieldOptions(invoicePaymentsOptions, 'type')
    }, [invoicePaymentsOptions])

    const getPaymentType = useCallback(({value: type}) => {
        if(!_.isNil(type)) {
            const typeObj = _.find(invoicePaymentTypes, ['value', type])

            if(!_.isNil(typeObj)) {
                return typeObj.label
            }
        }

        return '-'
    }, [invoicePaymentTypes])

    const handleOpenDeletePaymentModal = useCallback((payment) => {
        setSelectedPayment(payment)
        openModal(modalTypes.DELETE_INVOICE_PAYMENT)
    }, [openModal])

    const handleOpenCancelPaymentModal = useCallback((payment) => {
        setSelectedPayment(payment)
        openModal(modalTypes.GENERIC_WARNING_MODAL)
    }, [openModal])

    const getCanceledClassName = useCallback((invoicePayment) => {
        if(invoicePayment.state === 'canceled') {
            return 'payment-canceled'
        }

        return ''
    }, [])

    const paymentsColumns = useMemo(() => {
        return [
            {
                Header: 'Data',
                accessor: 'date',
                Cell: ({value: date, row: {original: invoicePayment}}) => {
                    return (
                        <span className={getCanceledClassName(invoicePayment)}>
                            {!_.isNil(date) ? lightFormat(new Date(date), 'dd/MM/yyyy') : '-'}
                        </span>
                    )
                }
            },
            {
                Header: 'Metoda',
                accessor: 'type',
                Cell: ({value: type, row: {original: invoicePayment}}) => {
                    return (
                        <span className={getCanceledClassName(invoicePayment)}>
                            {getPaymentType({value: type})}
                        </span>
                    )
                }
            },
            {
                Header: 'Sumă încasată',
                accessor: 'amount',
                Cell: ({row: {original: invoicePayment}}) => {
                    if(!_.isNil(invoicePayment.amount)) {
                        return (
                            <span className={getCanceledClassName(invoicePayment)}>
                                {`${invoicePayment.amount} ${invoicePayment.currency}`}
                            </span>
                        )
                    }

                    return '-'
                }
            },
            {
                Header: 'Proiect',
                accessor: 'project',
                Cell: ({value: project, row: {original: invoicePayment}}) => {
                    return (
                        <span className={getCanceledClassName(invoicePayment)}>
                            {!_.isNil(project) ? project.name : '-'}
                        </span>
                    )
                }
            },
            {
                Header: 'Client',
                accessor: 'client',
                Cell: ({value: client, row: {original: invoicePayment}}) => (
                    <span className={getCanceledClassName(invoicePayment)}>
                        {!_.isNil(client) ? client.name : '-'}
                    </span>
                )
            },
            {
                Header: 'Serie chitanță',
                accessor: 'series_id',
                Cell: ({row: {original: invoicePayment}}) => {
                    if(invoicePayment.type === 'cash') {
                        return (
                            <span className={getCanceledClassName(invoicePayment)}>
                                {`${invoicePayment.series_name} ${invoicePayment.series_number}`}
                            </span>
                        )
                    }

                    return '-'
                }
            },
            {
                Header: 'Factura aferentă',
                accessor: 'invoice',
                Cell: ({value: invoice}) => {
                    return (
                        <Link to={`/invoices/${invoice.id}/info`}>
                            <div className={
                                'category-button h-full grid place-items-center'
                            }>
                                <Button
                                    title={`Vezi factura: ${invoice.series_name} ${invoice.series_number}`}
                                    size='small'
                                    variant='outlined'
                                    color='gray'
                                />
                            </div>

                        </Link>
                    )
                }
            },
            ...usersPreferences.has_smartbill_integration ? [{
                Header: 'SmartBill',
                accessor: 'sync_state_smartbill',
                Cell: ({value, row: {original: invoicePayment}}) => (
                    <span onClick={() => {
                        setSelectedPayment(invoicePayment)
                        if (value === externalSyncStates.MANUAL_NEEDS_UPDATE) {
                            openInvoiceExternalSyncResolveModal()
                        }
                    }}>
                        <InvoiceExternalSyncIcon
                            state={value}
                        />
                    </span>
                ),
                style: {
                    width: '55px'
                }
            }] : [],
            {
                Header: 'Acțiuni',
                accessor: 'id',
                Cell: ({row: {original: invoicePayment}}) => (
                    <div className='datatable-row-buttons-container'>
                        {!_.isNil(invoicePayment.file_pdf) && (
                            <a href={invoicePayment.file_pdf.url_public}
                                target="_blank" rel="noopener noreferrer">
                                <Button title='Descarcă chitanță' icon={() => <ArrowDownTrayIcon />} size='small' />
                            </a>
                        )}
                        {!invoicePayment.deletion_prevented && (
                            <Button
                                icon={() => <TrashIcon />}
                                onClick={() => handleOpenDeletePaymentModal(invoicePayment)}
                                size='small'
                                color='red'
                            />
                        )}
                        {invoicePayment.state === 'issued' && invoicePayment.type === 'cash' && (
                            <Button
                                title='Anulează'
                                icon={() => <XMarkIcon />}
                                onClick={() => {
                                    handleOpenCancelPaymentModal(invoicePayment)}
                                }
                                variant='contained'
                                size='small'
                                color='red'
                            />
                        )}
                    </div>
                )
            }
        ]
    }, [
        usersPreferences.has_smartbill_integration,
        getCanceledClassName,
        getPaymentType,
        openInvoiceExternalSyncResolveModal,
        handleOpenDeletePaymentModal,
        handleOpenCancelPaymentModal
    ])

    return (
        <>
            <Datatable
                title='Încasări factură'
                data={invoicePayments}
                columns={paymentsColumns}
                customHeader={() =>
                    <div className="custom-invoice-payments-header">
                        <div className="total-filtered-container">
                            {
                                !isNil(invoicePaymentsReports)
                                && !isEmpty(invoicePaymentsReports)
                                && !isLoadingInvoicePaymentsReports ? (
                                    <div>
                                            Total sume încasate:
                                            <span className="total-sum">
                                                {invoicePaymentsReports.RON.total_amount} (RON)
                                            </span>
                                            {toNumber(invoicePaymentsReports.EUR.total_amount) > 0 && (
                                        <>
                                                    , { }
                                                    <span className="total-sum">
                                                {invoicePaymentsReports.EUR.total_amount} (EUR)
                                            </span>
                                                </>
                                            )}
                                        </div>
                                    ) : (<Loader size="small"/>)
                            }
                        </div>
                    </div>
                }
                searchContainerButton={() => (
                    <Button
                        loading={isPendingExport}
                        disabled={isEmpty(invoicePayments) || isPendingExport}
                        title='Descarcă CSV'
                        icon={() => <ArrowDownTrayIcon/>}
                        onClick={async () => {
                            setIsPendingExport(true)

                            const preparedFilters = {
                                ...generateFiltersForAPI(filters), search: searchFilter
                            }

                            const response = await performRequest(RESOURCES.invoicePayments.listCSV({
                                entity_id: selectedEntityID,
                                ...preparedFilters
                            }))
                            setIsPendingExport(false)

                            if (response.status === 200) {
                                toast.success('Fișierul CSV a fost generat.')
                                fileDownload(response.data, 'export-incasari.csv')
                            } else {
                                toast.error('A apărut o eroare la generarea raportului.')
                            }
                        }}
                    />
                )}

                loading={isLoadingPayments}
                totalPages={totalPaymentsPages}
                nextPage={nextPaymentsPage}
                previousPage={previousPaymentsPage}
                currentPage={currentPaymentsPage}
                onChangePage={handleChangePage}
                filterable
                filters={filters}
                filtersForm={() => (
                    <FilterInvoicePaymentsForm filterInvoicePayments={() => {
                        handleFetchInvoicePayments()
                        handleListInvoicePaymentsReports()
                    }}/>
                )}
                handleRemoveFilter={(filter) => {
                    removeFilter(filter)
                    handleFetchInvoicePayments()
                    handleListInvoicePaymentsReports()
                }}
                searchable
                searchValue={searchFilter}
                searchPlaceholder='Caută facturi'
                onSearch={(event) => handleChangeSearchField(event.target.value)}
                emptyText='Nu există încasări pentru această factură'
            />
            <AddInvoicePaymentModal />
            {
                selectedPayment && (<DeleteInvoicePayment payment={selectedPayment} />)
            }
            <InvoiceExternalSyncResolveModal
                selectedInvoicePayment={selectedPayment} reloadData={() => {handleFetchInvoicePayments()}}
            />
            {selectedPayment && (
                <GenericWarningModal
                    text={
                        `Ești sigur ca dorești sa anulezi această plată 
                    (${selectedPayment.series_name} ${selectedPayment.series_number})?`
                    }
                    title={'Anulare plată'}
                    action={async () => {
                        const {status} = await performRequest(
                            RESOURCES.invoicePayments.update({state: 'canceled'}, selectedPayment.id)
                        )
                        if (status === 200) {
                            toast.success('Plata a fost anulată.')
                            if (usersPreferences.has_smartbill_integration) {
                                openSyncInvoiceModal(selectedPayment.id, externalSyncActions.CANCEL)
                            }
                            else{
                                closeModal()
                            }
                        } else {
                            toast.error('A apărut o eroare la anularea plății.')
                        }
                        handleFetchInvoicePayments()
                    }}
                />
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    invoicePaymentsOptions: state.invoicePayments.options,
    invoicePayments: _.orderBy(state.invoicePayments.data, ['date', 'created'], ['desc', 'desc']),
    isLoadingPayments: state.invoicePayments.isLoading,
    totalPaymentsPages: state.invoicePayments.totalPages,
    nextPaymentsPage: state.invoicePayments.next,
    previousPaymentsPage: state.invoicePayments.previous,
    currentPaymentsPage: state.invoicePayments.current,
    invoiceReports: state.invoiceReports.data,
    isLoadingInvoiceReports: state.invoiceReports.isLoading,
    filters: state.filters.invoicePayments,
    invoicePaymentsReports: state.invoicePaymentsReports.data,
    isLoadingInvoicePaymentsReports: state.invoicePaymentsReports.isLoading,
    selectedEntityID: state.localConfigs.selectedEntityID,
    usersPreferences: state.usersPreferences.data
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType)),
    openSyncInvoiceModal: (invoicePaymentID, action, errorMessage) => dispatch(
        openModal(modalTypes.INVOICE_EXTERNAL_SYNC_MODAL, {invoicePaymentID, action, errorMessage})
    ),
    removeFilter: (filter) => dispatch(removeFilter(RESOURCES.invoicePayments.name, filter)),
    getInvoicePaymentsOptions: () => dispatch(RESOURCES.invoicePayments.getOptions()),
    openInvoiceExternalSyncResolveModal: () => dispatch(openModal(modalTypes.INVOICE_EXTERNAL_MANUAL_RESOLVE_MODAL)),
    listInvoicePayments:(entityID, search, filters, page) => dispatch(
        RESOURCES.invoicePayments.list(
            {
                ...filters,
                entity_id: entityID,
                search: search,
                page: page,
                page_size: datatablePageSize
            },
            // overwriteData
            true
        )
    ),
    listInvoicePaymentsReports: (entityID, search, filters, page) => dispatch(
        RESOURCES.invoicePaymentsReports.list(
            {...filters, entity_id: entityID, search: search},
            // overwriteData
            true
        )
    ),
    closeModal: () => dispatch(closeModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePaymentsList)
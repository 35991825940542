import React, {useEffect} from 'react'
import {Form, Formik} from 'formik'

import {isEmpty, find, values as _values} from 'lodash'

import {connect} from 'react-redux'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {slideoverTypes} from '../../../../../redux/slideovers'

import {getFieldOptions, objectKeysToSnakeCase} from '../../../../../utils'
import {clientBankAccountsSchema} from '../../../../../assets/validations'

import {Input} from '../../../../../components/Input'
import {Select} from '../../../../../components/Select'
import {Button} from '../../../../../components/Button'
import {Loader} from '../../../../../components/Loader'
import {ErrorsList} from '../../../../../components/ErrorComponents'
import {Slideover} from '../../../../../components/Slideover'

import '../../../../../assets/scss/SlideoverForms.scss'

export const EditClientBankAccountSlideover = ({
    open,
    selectedBankAccountId,
    setSelectedBankAccountId,
    client,
    clientAddresses,
    listAllClientAddresses,
    currentBankAccount,
    isLoading,
    fieldErrors,
    nonFieldErrors,
    selectedEntityID,
    clientBankAccountsOptions,
    getClientBankAccountsOptions,
    updateClientBankAccount
}) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {getClientBankAccountsOptions()}, [])

    useEffect(() => {
        if(open) listAllClientAddresses({client_id: client.id, entity_id: selectedEntityID}, client.id)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const getInitialAddressOption = (currentAddressID) =>
        find(clientAddresses, (address) => parseInt(address.id) === parseInt(currentAddressID))

    const getInitialCurrencyOption = (currentCurrency) => find(currencies, ['value', currentCurrency])

    const currencies = getFieldOptions(clientBankAccountsOptions, 'currency')

    return (
        <Slideover
            title='Editează cont bancar'
            open={open && !isEmpty(currentBankAccount) && currentBankAccount.id === selectedBankAccountId}
            onClose={() => setSelectedBankAccountId(null)}
        >
            {!isEmpty(currentBankAccount) && !isEmpty(clientAddresses) && !isLoading ? (
                <div className="slideover-form-container">
                    <ErrorsList errors={nonFieldErrors} />
                    <Formik
                        initialValues={{
                            clientID: currentBankAccount.client_id,
                            currency: getInitialCurrencyOption(currentBankAccount.currency),
                            iban: currentBankAccount.iban,
                            bankName: currentBankAccount.bank_name,
                            swiftCode: currentBankAccount.swift_code || '',
                            bankAccountDescription: currentBankAccount.bank_account_description,
                            clientAddressId: getInitialAddressOption(currentBankAccount.client_address_id)
                        }}
                        validationSchema={clientBankAccountsSchema}
                        validateOnMount
                        onSubmit={(values) => {
                            const updateData = {
                                ...objectKeysToSnakeCase(values),
                                currency: values.currency.value,
                                client_address_id: values.clientAddressId.id
                            }

                            updateClientBankAccount(updateData, currentBankAccount.id)
                        }}
                    >
                        {({
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            values,
                            handleSubmit,
                            touched,
                            errors,
                            isValid
                        }) => (
                            <>
                                <Form className="slideover-form">
                                    <Input
                                        label='IBAN*'
                                        value={values.iban}
                                        onChange={handleChange('iban')}
                                        onBlur={handleBlur('iban')}
                                        name='iban'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.iban}
                                        fullWidth
                                    />
                                    <Select
                                        label='Moneda*'
                                        value={values.currency}
                                        options={currencies}
                                        onChange={(e) => setFieldValue('currency', e)}
                                        onBlur={handleBlur('currency')}
                                        name='currency'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.currency}
                                        fullWidth
                                    />
                                    <Input
                                        label='Banca*'
                                        value={values.bankName}
                                        onChange={handleChange('bankName')}
                                        onBlur={handleBlur('bankName')}
                                        name='bankName'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.bankName}
                                        fullWidth
                                    />
                                    <Input
                                        label='Cod SWIFT'
                                        value={values.swiftCode}
                                        onChange={handleChange('swiftCode')}
                                        onBlur={handleBlur('swiftCode')}
                                        name='swiftCode'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.swiftCode}
                                        fullWidth
                                    />
                                    <Input
                                        label='Descriere cont bancar*'
                                        value={values.bankAccountDescription}
                                        onChange={handleChange('bankAccountDescription')}
                                        onBlur={handleBlur('bankAccountDescription')}
                                        name='bankAccountDescription'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.bankAccountDescription}
                                        fullWidth
                                    />
                                    <Select
                                        label="Adresă cont bancar"
                                        value={values.clientAddressId}
                                        options={clientAddresses}
                                        onChange={(e) => setFieldValue('clientAddressId', e)}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) =>
                                            `${option.address}${
                                                option.address_description
                                                    ? ' (' + option.address_description + ')'
                                                    : ''
                                            }`
                                        }
                                        onBlur={handleBlur('clientAddressId')}
                                        name="clientAddressId"
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.clientAddressId}
                                        isClearable
                                        fullWidth
                                    />
                                </Form>
                                <div className="buttons-container">
                                    <Button
                                        title='Salvează'
                                        onClick={handleSubmit}
                                        loading={isLoading}
                                        disabled={!isValid}
                                        type='submit'
                                        fullWidth
                                    />
                                </div>
                            </>
                        )}
                    </Formik>
                </div>
            ) : isLoading ? (
                <div className="loader-container">
                    <Loader size='large' />
                </div>
            ) : null}
        </Slideover>
    )
}

const mapStateToProps = (state) => ({
    open: state.slideovers.type === slideoverTypes.EDIT_CLIENT_BANK_ACCOUNT,
    clientBankAccountsOptions: state.clientBankAccounts.options,
    client: state.clients.currentClient,
    clientAddresses: _values(state.clientAddresses.data),
    currentBankAccount: state.clientBankAccounts.currentClientBankAccount,
    isLoading: state.clientBankAccounts.isLoading,
    fieldErrors: state.clientBankAccounts.fieldErrors,
    nonFieldErrors: state.clientBankAccounts.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch) => ({
    getClientBankAccountsOptions: () => dispatch(RESOURCES.clientBankAccounts.getOptions()),
    listAllClientAddresses: (params) => dispatch(RESOURCES.clientAddresses.listAll(params)),
    updateClientBankAccount: (values, clientAddressID) => dispatch(
        RESOURCES.clientBankAccounts.update(values, clientAddressID)
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(EditClientBankAccountSlideover)

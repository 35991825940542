import React from 'react'

import _ from 'lodash'

import {getErrorMessage} from '../../utils/errorHelper'

import './ErrorsList.scss'

interface ErrorsListProps {
    errors: any[] | null | undefined
}

export default function ErrorsList({errors}: ErrorsListProps) {
    if (_.isNil(errors) || _.isEmpty(errors)) {
        return null
    }

    return (
        <div className="errors-list">
            {errors.map((error, index) => (
                <ErrorComponent
                    message={getErrorMessage(error)}
                    key={index}
                />
            ))}
        </div>
    )
}

interface ErrorsComponentProps {
    message: string
}

export function ErrorComponent({message}: ErrorsComponentProps) {
    return (
        <div className="error-div">
            <p className="error-text">{message}</p>
        </div>
    )
}

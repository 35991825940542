import React from 'react'

import {connect} from 'react-redux'
import {Dispatch} from 'redux'

export const CalendarMonthView = () => {
    return <div>CalendarMonthView</div>
}

const mapStateToProps = (_state: any) => ({})

const mapDispatchToProps = (_dispatch: Dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CalendarMonthView)

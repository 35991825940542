import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {toast} from 'react-toastify'

import _ from 'lodash'

// @ts-ignore
import {performRequest} from 'avoapp-react-common/dist/redux/api'
// @ts-ignore
import {litigationsCheck} from 'avoapp-react-common/dist/redux/litigations'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {setCurrent} from 'avoapp-react-common/dist/redux/subscriptions'
import {connect} from 'react-redux'
import {closeModal, modalTypes, openModal} from '../../redux/modals'

import {ProjectMinimal} from '../../types/api'
import {MDSubscriptionSources} from '../../utils/constants'

// @ts-ignore
import {searchTabs} from '../../pages/ProjectDetails/partials/ExternalData/constants'

import {Button} from 'components/Button'
import {ErrorComponent} from 'components/ErrorComponents/ErrorsList'
import {Modal} from 'components/Modal'
import {ProjectSelect} from 'components/ProjectSelect'
import {Tabs} from 'components/Tabs'

import LitigationsList from './LitigationsList'
import SearchDataSourcesForm from './SearchDataSourcesForm'

import './AddMDSubscriptionModal.scss'

interface AddMDSubscriptionModalProps {
    open: boolean
    project?: ProjectMinimal
    openModal: (modalType: string) => void
    closeModal: () => void
    setCurrentSubscription: (data: any) => void
}

export const AddMDSubscriptionModal = ({
    open,
    project,
    openModal,
    closeModal,
    setCurrentSubscription
}: AddMDSubscriptionModalProps) => {
    const [selectedTab, setSelectedTab] = useState<string>(searchTabs.SIMPLE)
    const [litigationResult, setLitigationResult] = useState<any>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [selectedLitigations, setSelectedLitigations] = useState<any[]>([])
    const [selectedProject, setSelectedProject] = useState<ProjectMinimal | undefined>(project)

    useEffect(() => {
        if (!open) {
            setLitigationResult(null)
            setSelectedLitigations([])
        } else if (project) {
            setSelectedProject(project)
        }
    }, [open, project])

    const handleChangeTab = useCallback((tab) => setSelectedTab(tab), [])

    const handleSearch = useCallback(
        async (values) => {
            if (values.source === MDSubscriptionSources.MANUAL.value) {
                const manualSubscriptionData = [
                    {
                        project_id: selectedProject?.id,
                        source: MDSubscriptionSources.MANUAL.value,
                        litigation_number: values.litigation_number
                    }
                ]

                setIsLoading(true)
                performRequest(RESOURCES.subscriptions.create(manualSubscriptionData)).then((res: any) => {
                    setCurrentSubscription(res.data[0])
                    openModal(modalTypes.ADD_MONITOR_DOSARE_MANUAL_SUBSCRIPTION)
                })
            } else {
                setIsLoading(true)
                performRequest(litigationsCheck(values)).then((data: any) => {
                    setLitigationResult(data)
                    setIsLoading(false)
                })
            }
        },
        [openModal, selectedProject, setCurrentSubscription]
    )

    const hasErrors = useMemo(() => {
        return (
            !_.isNil(litigationResult) &&
            _.isEmpty(litigationResult.data) &&
            !_.isEmpty(litigationResult.errors?.non_field_errors)
        )
    }, [litigationResult])

    const handleAddLitigation = useCallback(
        (litigationNumber) => {
            setSelectedLitigations((prevLitigations) => {
                return [
                    ...prevLitigations,
                    {
                        project_id: selectedProject?.id,
                        source: MDSubscriptionSources.PORTAL.value,
                        litigation_number: litigationNumber
                    }
                ]
            })
        },
        [selectedProject]
    )

    const handleRemoveLitigation = useCallback((litigationNumber) => {
        setSelectedLitigations((prevLitigations) => {
            return prevLitigations.filter((litigation) => litigation.litigation_number !== litigationNumber)
        })
    }, [])

    const handleSaveSelectedLitigations = useCallback(() => {
        performRequest(RESOURCES.subscriptions.create(selectedLitigations)).then((res: any) => {
            if (!res.errors) {
                toast.success('Salvat')
                closeModal()
            } else {
                if (Array.isArray(res.errors) && res.errors.length > 0) {
                    _.forEach(_.values(res.errors), (error) => {
                        toast.error(error.message)
                    })
                } else {
                    toast.error(res.errors.message)
                }
            }
        })
    }, [closeModal, selectedLitigations])

    return (
        <Modal
            open={open}
            title="Adaugă abonare Monitor Dosare"
            maxWidth="80%"
            minWidth="1000px">
            <div className="add-md-subscription-modal-content">
                <div className="search-data-sources-container">
                    <p className="section-title">Caută surse de date</p>
                    <Tabs
                        tabs={_.values(searchTabs)}
                        selectedTab={selectedTab}
                        onChangeTab={handleChangeTab}
                    />
                    {!project && (
                        <div className="project-select">
                            <ProjectSelect
                                value={selectedProject}
                                onChange={setSelectedProject}
                                fullWidth
                            />
                        </div>
                    )}
                    <SearchDataSourcesForm
                        selectedTab={selectedTab}
                        onSubmit={handleSearch}
                        isLoading={isLoading}
                        isDisabled={!selectedProject}
                    />
                </div>
                <div className="results-list-container">
                    <p className="section-title">Alege dosarele dorite</p>
                    {hasErrors ? (
                        <ErrorComponent message={litigationResult.errors.non_field_errors.message} />
                    ) : (
                        <>
                            {!_.isNil(litigationResult) && !_.isEmpty(litigationResult?.data) ? (
                                <>
                                    <LitigationsList
                                        litigations={litigationResult?.data}
                                        selectedLitigations={selectedLitigations}
                                        handleAddLitigation={handleAddLitigation}
                                        handleRemoveLitigation={handleRemoveLitigation}
                                    />
                                    <Button
                                        title="Salvează abonarea"
                                        onClick={handleSaveSelectedLitigations}
                                        color="secondary"
                                        disabled={_.isEmpty(selectedLitigations)}
                                        fullWidth
                                    />
                                </>
                            ) : (
                                <p className="litigations-list-empty">Aici vor aparea rezultatele in urma cautarii</p>
                            )}
                        </>
                    )}
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.ADD_MONITOR_DOSARE_SUBSCRIPTION,
    selectedEntityID: state.localConfigs.selectedEntityID
})

const mapDispatchToProps = (dispatch: any) => ({
    openModal: (modalType: string) => dispatch(openModal(modalType)),
    closeModal: () => dispatch(closeModal()),
    setCurrentSubscription: (data: any) => dispatch(setCurrent(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddMDSubscriptionModal)

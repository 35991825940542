import {useQueryResourceDetail, useQueryResourceSearch} from 'queries/rest'
import React, {useCallback, useEffect, useMemo} from 'react'

// @ts-ignore
import {RESOURCES_V2} from 'avoapp-react-common/dist/redux/spec'

import {Select, SelectProps} from 'components/Select'
import {useDebouncedState} from 'hooks'
import _ from 'lodash'
import {connect} from 'react-redux'

interface ProjectSelectProps
    extends Omit<SelectProps, 'name' | 'options' | 'getOptionLabel' | 'getOptionValue' | 'onInputChange' | 'loading'> {
    selectedEntityID: string
    initialValue?: string
}

function ProjectSelect({selectedEntityID, initialValue, label = 'Proiect*', ...selectProps}: ProjectSelectProps) {
    const [_searchQuery, setSearchQuery, debouncedSearchQuery] = useDebouncedState('')

    const {data: initialProjectData, isFetching: isFetchingInitialProject} = useQueryResourceDetail(
        RESOURCES_V2.projects,
        initialValue ?? '',
        {enabled: !_.isNil(initialValue)}
    )

    useEffect(() => {
        if (!_.isNil(initialProjectData) && selectProps.onChange && _.isEmpty(selectProps.value)) {
            selectProps.onChange(initialProjectData)
        }
    }, [initialProjectData, selectProps])

    const {data: projectsData, isFetching: isFetchingProjects} = useQueryResourceSearch(
        RESOURCES_V2.projects,
        debouncedSearchQuery,
        {
            entity_id: selectedEntityID
        }
    )

    const isFetching = useMemo(() => {
        return isFetchingInitialProject || isFetchingProjects
    }, [isFetchingInitialProject, isFetchingProjects])

    const projects = useMemo(() => {
        const result = []

        if (!_.isNil(projectsData)) {
            result.push(...(projectsData as any))
        }

        if (!_.isNil(initialProjectData)) {
            result.push(initialProjectData)
        }

        return result
    }, [initialProjectData, projectsData])

    const getOptionValue = useCallback((option: any) => option.id, [])

    const getOptionLabel = useCallback((option: any) => {
        let label = option.name

        if (Array.isArray(option.clients_names) && option.clients_names.length > 0) {
            label += ` (${option.clients_names[0]})`
        }

        return label
    }, [])

    const onInputChange = useCallback(
        (value: string) => {
            setSearchQuery(value)
        },
        [setSearchQuery]
    )

    return (
        <Select
            label={label}
            {...selectProps}
            options={projects}
            loading={isFetching}
            onInputChange={onInputChange}
            getOptionValue={getOptionValue}
            getOptionLabel={getOptionLabel}
            name="projectID"
        />
    )
}

const mapStateToProps = (state: any) => ({
    selectedEntityID: state.localConfigs.selectedEntityID
})

export default connect(mapStateToProps)(ProjectSelect)

import {put, select, take, takeEvery} from 'redux-saga/effects'

import {find} from 'lodash'

// @ts-ignore
import {Action} from 'avoapp-react-common/dist/redux'
// @ts-ignore
import {clearTaskTimeLogID, selectTaskTimeLogID} from 'avoapp-react-common/dist/redux/localConfigs'
// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
// @ts-ignore
import {ACTIONS as TASK_TIME_LOG_ACTIONS, setCurrent} from 'avoapp-react-common/dist/redux/taskTimeLogs'

// @ts-ignore
import {toApiTimeFormat} from '../utils'

import {ACTIONS as MODAL_ACTIONS, closeModal, modalTypes, openModal} from './modals'

const {taskTimeLogs: resource} = RESOURCES

const ACTIONS = {
    STOP_RUNNING_TASK_TIME_LOG: new Action('STOP_RUNNING_TASK_TIME_LOG'),
    SAVE_AND_CLEAR_TASK_IDS: new Action('SAVE_AND_CLEAR_TASK_IDS'),
    OPEN_AUTO_STOPPED_TASK_TIME_LOG_MODAL: 'OPEN_AUTO_STOPPED_TASK_TIME_LOG_MODAL'
}

function* handleCreateTaskTimeLogSuccess({payload: taskTimeLog}: any) {
    yield put(closeModal())
    yield put(setCurrent(taskTimeLog))
}

function* handleUpdateTaskTimeLogSuccess() {
    yield put(closeModal())
}

function* handleDestroyTaskTimeLogSuccess() {
    yield put(closeModal())
}

function* handleSetCurrentTaskTimeLog({payload: {taskTimeLog}}: any) {
    yield put(selectTaskTimeLogID(taskTimeLog.id))
}

function* handleRetrieveLastRunningTaskTimeLog(): Generator<any, void, any> {
    const taskTimeLogID = yield select((state) => state.localConfigs.taskTimeLogID)

    if (taskTimeLogID) {
        yield put(RESOURCES.taskTimeLogs.retrieve(taskTimeLogID))
        yield take(RESOURCES.taskTimeLogs.actions.retrieve.success)

        const taskTimeLog = yield select((state) => find(state.taskTimeLogs.data, ['id', taskTimeLogID]))

        setCurrent(taskTimeLog)

        if (taskTimeLog.task_id) {
            yield put(RESOURCES.tasks.retrieve(taskTimeLog.task_id))
        }
    }
}

function* handleStopRunningTaskTimeLog({payload: {hasAutoStopped}}: any): Generator<any, void, any> {
    const taskTimeLogID = yield select((state) => state.localConfigs.taskTimeLogID)

    if (taskTimeLogID) {
        yield put(RESOURCES.taskTimeLogs.update({stop: toApiTimeFormat(new Date())}, taskTimeLogID))
        yield take(RESOURCES.taskTimeLogs.actions.update.success)
        yield take(MODAL_ACTIONS.CLOSE_MODAL.main)

        if (hasAutoStopped) {
            yield put(openModal(modalTypes.AUTO_STOPPED_TASK_TIME_LOG))
        } else {
            yield put(openModal(modalTypes.CHECK_TASK_TIME_LOG))
        }
    }
}

function* handleSaveLogAndClearLocaLTaskIDs({payload: {data, id}}: any): Generator<any, void, any> {
    yield put(RESOURCES.taskTimeLogs.update(data, id))

    const updateTaskTimeLogResponse = yield take(
        (action: any) =>
            action.type === RESOURCES.taskTimeLogs.actions.update.success ||
            action.type === RESOURCES.taskTimeLogs.actions.update.fail
    )

    if (updateTaskTimeLogResponse === RESOURCES.taskTimeLogs.actions.update.success) {
        yield put(clearTaskTimeLogID())
        yield put(closeModal())
    }
}

export function* saga() {
    yield takeEvery(resource.actions.create.success, handleCreateTaskTimeLogSuccess)
    yield takeEvery(resource.actions.update.success, handleUpdateTaskTimeLogSuccess)
    yield takeEvery(resource.actions.destroy.success, handleDestroyTaskTimeLogSuccess)
    yield takeEvery(TASK_TIME_LOG_ACTIONS.SET_CURRENT_TASK_TIME_LOG.main, handleSetCurrentTaskTimeLog)
    yield takeEvery(
        TASK_TIME_LOG_ACTIONS.RETRIEVE_LAST_RUNNING_TASK_TIME_LOG.main,
        handleRetrieveLastRunningTaskTimeLog
    )
    yield takeEvery(ACTIONS.STOP_RUNNING_TASK_TIME_LOG.main, handleStopRunningTaskTimeLog)
    yield takeEvery(ACTIONS.SAVE_AND_CLEAR_TASK_IDS.main, handleSaveLogAndClearLocaLTaskIDs)
}

export const stopRunningTaskTimeLog = (hasAutoStopped: boolean = false) => ({
    type: ACTIONS.STOP_RUNNING_TASK_TIME_LOG.main,
    payload: {
        hasAutoStopped
    }
})

export const saveLogAndClearLocaLTaskIDs = (data: any, id: string | null = null) => ({
    type: ACTIONS.SAVE_AND_CLEAR_TASK_IDS.main,
    payload: {
        data: data,
        id: id ? id : data.id
    }
})

import React, {useCallback} from 'react'

import _ from 'lodash'

import {connect} from 'react-redux'
import {closeModal, modalTypes, openModal} from '../../redux/modals'

import {Button} from '../Button'
import {Modal} from '../Modal'

import {manualUpdateSmartBillInvoicePayment} from 'avoapp-react-common/dist/redux/smartbill'
import {toast} from 'react-toastify'
import '../../assets/scss/DeleteModals.scss'
import {useManualUpdateSmartBillInvoice, useSpecMutation} from '../../mutations'
import {externalSyncActions} from '../../pages/Invoices/constants'

function InvoiceExternalSyncResolveModal({
    open,
    selectedInvoice,
    selectedInvoicePayment,
    isLoading,
    closeModal,
    openSyncInvoiceModal,
    reloadData
}) {
    const onSmartBillFinished = useCallback(
        (data, action) => {
            const {errors, status} = data

            if (_.includes([403, 418], status)) {
                // SmartBill is not enabled for this entity. Missing API key or action not needed.
                return
            }

            if (status >= 400) {
                const errorMessage = _.get(
                    errors,
                    'non_field_errors.0.message',
                    'A apărut o eroare la sincronizarea cu SmartBill'
                )
                openSyncInvoiceModal(selectedInvoice.id, action, errorMessage)
            } else {
                toast('Factura a fost sincronizată cu succes în SmartBill', {type: 'success'})
            }
        },
        [selectedInvoice, openSyncInvoiceModal]
    )

    const {mutateAsync: manualUpdateSmartBillInvoice} = useManualUpdateSmartBillInvoice({
        onSuccess: (data, variables, context) => {
            onSmartBillFinished(data, externalSyncActions.MANUAL_UPDATE)
        }
    })

    const {mutateAsync: runMutation} = useSpecMutation({
        onSuccess: (data, variables, context) => {
            onSmartBillFinished(data, externalSyncActions.MANUAL_UPDATE)
        }
    })

    return (
        <Modal
            open={open}
            title="Soncrinizare manuală document SmartBill">
            <div className="delete-modal">
                <p className="message">
                    {selectedInvoice && 'Ai rezolvat diferențele de sincronizare în mod manual în SmartBill?'}
                    {selectedInvoicePayment && 'Ai anulat încasarea și în SmartBill (manual)'}
                </p>
                <div className="buttons-container">
                    <Button
                        title="Nu, renunță"
                        onClick={closeModal}
                        variant="outlined"
                        loading={isLoading}
                        color="gray"
                    />
                    <Button
                        title="Da, marchează ca sincronizat"
                        onClick={async () => {
                            if (selectedInvoice) {
                                const {status: deleteHTTPStatus} = await manualUpdateSmartBillInvoice(selectedInvoice)
                                if (deleteHTTPStatus === 400) {
                                    return
                                }
                            } else if (selectedInvoicePayment) {
                                const {status: updateHTTPStatus} = await runMutation(
                                    manualUpdateSmartBillInvoicePayment(selectedInvoicePayment.id)
                                )
                                if (updateHTTPStatus === 400) {
                                    return
                                }
                            }

                            reloadData()
                            closeModal()
                        }}
                        loading={isLoading}
                        color="primary"
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.INVOICE_EXTERNAL_MANUAL_RESOLVE_MODAL,
    isLoading: state.invoices.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    openSyncInvoiceModal: (invoiceID, action, errorMessage) =>
        dispatch(openModal(modalTypes.INVOICE_EXTERNAL_SYNC_MODAL, {invoiceID, action, errorMessage}))
})

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceExternalSyncResolveModal)

import React, {useCallback} from 'react'

import {useQueryClient} from '@tanstack/react-query'

import {isEmpty} from 'lodash'

// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {useMutationResourceDelete} from 'mutations'
import {connect, useDispatch} from 'react-redux'
import {closeModal, modalTypes} from 'redux/modals'

import {Button} from 'components/Button'
// @ts-ignore
import {Modal} from '../Modal'

import '../../assets/scss/DeleteModals.scss'

interface DeleteSubscriptionModalProps {
    open: boolean
    subscription: any
    doAfterDelete?: () => void
}

function DeleteSubscriptionModal({open, subscription, doAfterDelete}: DeleteSubscriptionModalProps) {
    const dispatch = useDispatch()
    const queryClient = useQueryClient()

    const deleteSubscriptionMutation = useMutationResourceDelete(RESOURCES.subscriptions, {
        onSuccess() {
            dispatch(closeModal())
            queryClient.invalidateQueries(RESOURCES.subscriptions)

            if (doAfterDelete) {
                doAfterDelete()
            }
        }
    })

    const handleDelete = useCallback(() => {
        deleteSubscriptionMutation.mutate(subscription.id)
    }, [deleteSubscriptionMutation, subscription.id])

    return (
        <Modal
            open={open && !isEmpty(subscription)}
            title="Șterge abonarea">
            {!isEmpty(subscription) && (
                <div className="delete-modal">
                    <p className="message">
                        Ești sigur că vrei să ștergi sursa de date pentru{' '}
                        <span>"{subscription.litigation_number}"</span>?
                    </p>
                    <div className="buttons-container">
                        <Button
                            title="Nu, renunță"
                            onClick={closeModal}
                            variant="outlined"
                            loading={deleteSubscriptionMutation.isPending}
                            color="gray"
                        />
                        <Button
                            title="Da, șterge"
                            onClick={handleDelete}
                            loading={deleteSubscriptionMutation.isPending}
                            color="red"
                        />
                    </div>
                </div>
            )}
        </Modal>
    )
}

const mapStateToProps = (state: any) => ({
    open: state.modals.type === modalTypes.DELETE_SUBSCRIPTION,
    subscription: state.subscriptions.currentSubscription
})

export default connect(mapStateToProps)(DeleteSubscriptionModal)

import React, {useCallback, useMemo, useState} from 'react'

import {Form, Formik} from 'formik'

import {endOfDay, formatISO, isPast, isToday, startOfDay} from 'date-fns'
import _ from 'lodash'

// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {useQueryResourceList, useQueryResourceSearch} from 'queries/rest'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {modalTypes} from 'redux/modals'

// @ts-ignore
import {tasksSchema} from '../../assets/validations'

import {ProjectMinimal} from 'types/api'
// @ts-ignore
import {objectKeysToSnakeCase} from '../../utils'

import {Button} from 'components/Button'
import {ErrorsList} from 'components/ErrorComponents'
import {Modal} from 'components/Modal'
import {ProjectSelect} from 'components/ProjectSelect'
import {Select} from 'components/Select'
// @ts-ignore
import {ColorPicker} from '../ColorPicker'
// @ts-ignore
import {DatePicker} from '../DatePicker'
// @ts-ignore
import {Input} from '../Input'
// @ts-ignore
import {RequesterCreatable} from '../RequesterCreatable'
// @ts-ignore
import {RequiredFieldsText} from '../RequiredFieldsText'
// @ts-ignore
import {Toggle} from '../Toggle'

import './AddTaskModal.scss'

interface AddTaskModalProps {
    open: boolean
    projectID?: string
    nonFieldErrors: any
    fieldErrors: any
    isLoading: boolean
    selectedEntityID: string
    entityProfileID: string
    notifyPersons: any[]
    createTask: (data: any) => void
    initialData?: any
}

export const AddTaskModal = ({
    open,
    projectID,
    nonFieldErrors,
    fieldErrors,
    isLoading,
    selectedEntityID,
    entityProfileID,
    notifyPersons,
    createTask,
    initialData = {}
}: AddTaskModalProps) => {
    const [contractsQuery, setContractsQuery] = useState<string>('')
    const [selectedProject, setSelectedProject] = useState<ProjectMinimal | null | undefined>(undefined)

    const {data: entityProfilesData, isFetching: isFetchingEntityProfiles} = useQueryResourceList(
        RESOURCES.entityProfiles,
        {entity_id: selectedEntityID}
    )

    const entityProfiles = useMemo(() => {
        return (entityProfilesData as any)?.data?.results ?? []
    }, [entityProfilesData])

    const handleChangeContractsSearchField = useCallback((value: string) => {
        setContractsQuery(value)
    }, [])

    const {data: contractsData, isFetching: isFetchingContracts} = useQueryResourceSearch(
        RESOURCES.contracts,
        contractsQuery,
        {
            entity_id: selectedEntityID,
            project_id: selectedProject?.id
        },
        {enabled: !_.isNil(selectedProject)}
    )

    const contracts = useMemo(() => {
        return (contractsData as any)?.data?.results ?? []
    }, [contractsData])

    return (
        <Modal
            open={open}
            title="Adaugă sarcină">
            <ErrorsList errors={nonFieldErrors} />
            <Formik
                initialValues={{
                    projectId: selectedProject,
                    contractId: !_.isNil(selectedProject) && contracts.length === 1 ? contracts[0] : null,
                    ownersIds: _.filter(entityProfiles, (profile) => profile.id === entityProfileID),
                    supervisorsIds: [],
                    title: '',
                    requesterId: null,
                    timePlanning: true,
                    allDay: true,
                    start: new Date(),
                    stop: new Date(),
                    billable: true,
                    completion: false,
                    color: null,
                    taskSubscribers: [],
                    location: '',
                    ...initialData
                }}
                validationSchema={tasksSchema}
                onSubmit={(values) => {
                    let start = null
                    let stop = null

                    if (values.timePlanning) {
                        if (values.allDay) {
                            start = formatISO(startOfDay(values.start))
                            stop = formatISO(endOfDay(values.stop))
                        } else {
                            start = formatISO(values.start)
                            stop = formatISO(values.stop)
                        }
                    }

                    const taskData = {
                        ...objectKeysToSnakeCase({
                            ..._.omit(values, [
                                'projectId',
                                'contractId',
                                'ownersIds',
                                'supervisorsIds',
                                'requesterId',
                                'date',
                                'taskSubscribers',
                                'timePlanning'
                            ])
                        }),
                        entity_id: selectedEntityID,
                        owners_ids: values.ownersIds.map((profile: any) => profile.id),
                        supervisors_ids: values.supervisorsIds.map((profile: any) => profile.id),
                        project_id: values.projectId?.id || null,
                        contract_id: values.contractId?.id || null,
                        requester_id: values.requesterId?.id || null,
                        all_day: values.timePlanning ? values.allDay : null,
                        start: start,
                        stop: stop,
                        completion: values.completion ? new Date() : null,
                        task_subscribers: values.taskSubscribers.map((sub: any) => ({
                            subscriber_id: sub.value,
                            subscriber_category: sub.subscriber_category
                        }))
                    }

                    createTask(taskData)
                    setSelectedProject(null)
                }}>
                {({handleChange, setFieldValue, handleBlur, handleSubmit, values, errors, touched, isValid}) => (
                    <Form className="add-task-form-container">
                        <div className="add-task-form-row">
                            <ProjectSelect
                                initialValue={projectID}
                                value={values.projectId}
                                onChange={(option: any) => {
                                    setSelectedProject(option)
                                    setFieldValue('projectId', option)

                                    setFieldValue('taskSubscribers', [])
                                    setFieldValue('contractId', null)
                                }}
                                onBlur={handleBlur('projectId')}
                                disabled={!_.isUndefined(projectID)}
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.projectId}
                                fullWidth
                            />
                            <Select
                                label="Contract"
                                value={values.contractId}
                                options={contracts}
                                loading={isFetchingContracts}
                                getOptionValue={(option: any) => option.id}
                                getOptionLabel={(option: any) => option.name}
                                onChange={(e: any) => setFieldValue('contractId', e)}
                                onInputChange={handleChangeContractsSearchField}
                                onBlur={handleBlur('contractId')}
                                name="contractId"
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.contractId}
                                disabled={_.isNull(values.projectId)}
                                fullWidth
                                isClearable
                            />
                        </div>
                        <div className="add-task-form-row">
                            <Select
                                label="Deținători*"
                                value={values.ownersIds}
                                options={entityProfiles}
                                getOptionValue={(option: any) => option.id}
                                getOptionLabel={(option: any) => `${option.first_name} ${option.last_name}`}
                                onChange={(e: any) => setFieldValue('ownersIds', e)}
                                onBlur={handleBlur('ownersIds')}
                                name="ownersIds"
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.ownersIds}
                                loading={isFetchingEntityProfiles}
                                fullWidth
                                isMulti
                            />
                            <Select
                                label="Supraveghetori"
                                value={values.supervisorsIds}
                                options={entityProfiles}
                                getOptionValue={(option: any) => option.id}
                                getOptionLabel={(option: any) => `${option.first_name} ${option.last_name}`}
                                onChange={(e: any) => setFieldValue('supervisorsIds', e)}
                                onBlur={handleBlur('supervisorsIds')}
                                name="supervisorsIds"
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.supervisorsIds}
                                isClearable
                                fullWidth
                                isMulti
                            />
                        </div>
                        <Input
                            label="Titlu/Descriere sarcină"
                            value={values.title}
                            onChange={handleChange('title')}
                            onBlur={handleBlur('title')}
                            name="title"
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.title}
                            fullWidth
                        />
                        <RequesterCreatable
                            value={values.requesterId}
                            onChange={(e: any) => setFieldValue('requesterId', e)}
                            onBlur={handleBlur('requesterId')}
                            name="requesterId"
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.requesterId}
                            disabled={_.isNull(values.projectId)}
                            projectId={values.projectId?.id || null}
                        />
                        <Toggle
                            label="Planificare timp"
                            checked={values.timePlanning}
                            onChange={(e: any) => setFieldValue('timePlanning', e)}
                            onBlur={handleBlur('timePlanning')}
                            name="timePlanning"
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.timePlanning}
                        />
                        {values.timePlanning && (
                            <>
                                <Toggle
                                    label="Toată ziua"
                                    checked={values.allDay}
                                    onChange={(e: any) => setFieldValue('allDay', e)}
                                    onBlur={handleBlur('allDay')}
                                    name="allDay"
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.allDay}
                                />
                                <div className={`add-task-form-row ${!values.allDay ? 'asym-split' : ''}`}>
                                    <DatePicker
                                        label="Data start*"
                                        value={values.start}
                                        onChange={(date: any) =>
                                            _.isNull(date)
                                                ? setFieldValue('start', date)
                                                : setFieldValue('start', new Date(date))
                                        }
                                        onBlur={handleBlur('start')}
                                        name="start"
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.start}
                                        fullWidth
                                    />
                                    {!values.allDay && (
                                        <DatePicker
                                            label="Ora start"
                                            value={values.start}
                                            onChange={(date: any) => setFieldValue('start', new Date(date))}
                                            timeFormat="HH:mm"
                                            timeSelect
                                            fullWidth
                                        />
                                    )}
                                </div>
                                <div className={`add-task-form-row ${!values.allDay ? 'asym-split' : ''}`}>
                                    <DatePicker
                                        label="Data stop*"
                                        value={values.stop}
                                        onChange={(date: any) =>
                                            _.isNull(date)
                                                ? setFieldValue('stop', date)
                                                : setFieldValue('stop', new Date(date))
                                        }
                                        onBlur={handleBlur('stop')}
                                        name="stop"
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.stop}
                                        fullWidth
                                    />
                                    {!values.allDay && (
                                        <DatePicker
                                            label="Ora stop"
                                            value={values.stop}
                                            onChange={(date: any) => setFieldValue('stop', new Date(date))}
                                            timeFormat="HH:mm"
                                            timeSelect
                                            fullWidth
                                        />
                                    )}
                                </div>
                            </>
                        )}
                        <div className="add-task-form-row">
                            <div className="w-full">
                                <Toggle
                                    label="De facturat"
                                    checked={values.billable}
                                    onChange={(e: any) => setFieldValue('billable', e)}
                                    onBlur={handleBlur('billable')}
                                    name="billable"
                                    errors={fieldErrors}
                                    frontendErrors={errors}
                                    touched={touched.billable}
                                />
                            </div>
                            <div className="w-full">
                                {isPast(values.start) && !isToday(values.start) && (
                                    <Toggle
                                        label="Sarcină finalizată"
                                        checked={values.completion}
                                        onChange={(e: any) => setFieldValue('completion', e)}
                                        onBlur={handleBlur('completion')}
                                        name="completion"
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.completion}
                                    />
                                )}
                            </div>
                        </div>
                        <Select
                            label="Colaboratori interni anunțați"
                            value={values.taskSubscribers}
                            options={notifyPersons}
                            disabled={_.isNull(values.projectId)}
                            onChange={(e: any) => setFieldValue('taskSubscribers', e)}
                            onBlur={handleBlur('taskSubscribers')}
                            name="taskSubscribers"
                            errors={fieldErrors}
                            frontendErrors={errors}
                            touched={touched.taskSubscribers}
                            fullWidth
                            isMulti
                        />
                        <div className="add-task-form-row">
                            <ColorPicker
                                label="Culoare"
                                color={values.color}
                                onChange={(hex: string) => setFieldValue('color', hex)}
                                onBlur={handleBlur('color')}
                                name="color"
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.color}
                                isClearable
                            />
                            <Input
                                label="Locație"
                                value={values.location}
                                onChange={handleChange('location')}
                                onBlur={handleBlur('location')}
                                name="location"
                                errors={fieldErrors}
                                frontendErrors={errors}
                                touched={touched.location}
                                fullWidth
                            />
                        </div>
                        <RequiredFieldsText />
                        <Button
                            title="Adaugă sarcină"
                            onClick={handleSubmit}
                            disabled={!isValid}
                            loading={isLoading}
                            color="secondary"
                            type="submit"
                            fullWidth
                        />
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

const mapStateToProps = (state: any) => {
    const getPersons = () => {
        const persons: any[] = []

        const entityProfiles = _.values(state.entityProfiles.data)

        // const contactPersons = values(state.contactPersons.data)
        // forEach(contactPersons, (person) => {
        //     persons.push({
        //         value: person.id,
        //         label: `${person.first_name} ${person.last_name}`,
        //         subscriber_category: 'contact-persons'
        //     })

        _.forEach(entityProfiles, (person) => {
            persons.push({
                value: person.id,
                label: `${person.first_name} ${person.last_name}`,
                subscriber_category: 'entity-profiles'
            })
        })

        return persons
    }

    return {
        open: state.modals.type === modalTypes.ADD_TASK,
        isLoading: state.tasks.isLoading,
        fieldErrors: state.tasks.fieldErrors,
        nonFieldErrors: state.tasks.nonFieldErrors,
        notifyPersons: getPersons(),
        selectedEntityID: state.localConfigs.selectedEntityID,
        entityProfileID: state.localConfigs.entityProfileID
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
    createTask: (data: any) => dispatch(RESOURCES.tasks.create(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddTaskModal)

import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {keepPreviousData, useQuery} from '@tanstack/react-query'
import {useFormik} from 'formik'
import _ from 'lodash'
import queryString from 'query-string'

import {performRequest} from 'avoapp-react-common/dist/redux/api'
import {RESOURCES, RESOURCES_V2} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'

import {addendumsSchema} from '../../assets/validations'
import {getFieldOptions, objectKeysToSnakeCase, toApiDateFormat} from '../../utils'
import {CANCELED_STATE, debounceWait} from '../../utils/constants'
import {datatablePageSize} from '../../utils/datatables'
import {useDebouncedState} from '../../utils/hooks'
import {documentSeriesTypes, taxPercentageOptions} from '../../utils/types'

import {DatePicker} from '../../components/DatePicker'
import {ErrorsList} from '../../components/ErrorComponents'
import {FormSection} from '../../components/FormSection'
import {Input} from '../../components/Input'
import {RequiredFieldsText} from '../../components/RequiredFieldsText'
import {Select} from '../../components/Select'
import {Toggle} from '../../components/Toggle'

import {documentTemplateTypes} from './constants'

import './AddendumAdd.scss'

const fetchData = async (resource, filters) => {
    const {data} = await performRequest(resource.list(filters))
    return data.results || []
}

export const AddendumAdd = ({
    location,
    selectedEntityID,
    fieldErrors,
    nonFieldErrors,
    documentSeries,
    isLoadingDocumentSeries,
    createAddendum,
    listAllDocumentSeries
}) => {
    const {data: addendumOptions, isFetching: isFetchingAddendumsOptions} = useQuery({
        queryKey: [RESOURCES.addendums.name],
        queryFn: async () =>{
            const response = await performRequest(RESOURCES.addendums.getOptions())

            return response.data?.actions?.POST
        }
    })

    const initialProjectID = useMemo(() => {
        const params = queryString.parse(location.search)
        return _.get(params, 'initialProjectId', null)
    }, [location.search])

    const [initialProject, setInitialProject] = useState(undefined)
    useEffect(() => {
        if(initialProjectID) {
            performRequest(RESOURCES_V2.projects.retrieve(initialProjectID)).then((res) => {
                setInitialProject(res.data)
            })
        }
    }, [initialProjectID])

    const initialContractID = useMemo(() => {
        const params = queryString.parse(location.search)
        return _.get(params, 'initialContractId', null)
    }, [location.search])

    const [initialContract, setInitialContract] = useState(undefined)
    useEffect(() => {
        if(initialContractID) {
            performRequest(RESOURCES.contracts.retrieve(initialContractID)).then((res) => {
                setInitialContract(res.data)
            })
        }
    }, [initialContractID])

    const {handleChange, handleBlur, setFieldValue, handleSubmit, values, touched, errors, isValid} = useFormik({
        initialValues: {
            contractId: initialContract,
            templateId: null,
            internalSeriesId:  null,
            barSeriesId: null,
            name: '',
            legalObject: '',
            signedDate: null,
            startDate: null,
            endDate: null,
            hasAmountToRecover: false,
            amountToRecover: '',
            amountToRecoverCurrency: null,
            billingPaymentMethod: null,
            billingAmount: '',
            billingCurrency: null,
            billingTaxPercentage: null,
            billingMinimumHours: '',
            billingAmountForExtraHours: '',
            isRecurring: null,
            billingRecurringMonths: 1,
            hasSuccessBonus: false,
            billingSuccessBonusValue: '',
            billingSuccessBonusType: null,
            billingSuccessBonusCurrency: null
        },
        validationSchema: addendumsSchema.create,
        enableReinitialize: true,
        onSubmit: (values) => {
            const addendumData = {
                ...objectKeysToSnakeCase(
                    _.omit(values, [
                        'hasAmountToRecover',
                        'isRecurring',
                        'hasSuccessBonus',
                        'internalSeriesId',
                        'barSeriesId'
                    ])
                ),
                entity_id: selectedEntityID,
                contract_id: values.contractId.id,
                template_id: values.templateId.id,
                signed_date: toApiDateFormat(new Date(values.signedDate)),
                start_date: toApiDateFormat(new Date(values.startDate)),
                end_date: toApiDateFormat(new Date(values.endDate)),
                amount_to_recover_currency: values.amountToRecoverCurrency ?
                    values.amountToRecoverCurrency.value : null,
                billing_payment_method: values.billingPaymentMethod.value,
                billing_currency: values.billingCurrency.value,
                billing_tax_percentage: values.billingTaxPercentage.value,
                billing_recurring_months: values.isRecurring ? values.billingRecurringMonths : null,
                billing_success_bonus_type: values.hasSuccessBonus ?
                    values.billingSuccessBonusType.value : null,
                billing_success_bonus_currency: values.hasSuccessBonus &&
                    values.billingSuccessBonusType === _.find(billingSuccessBonusTypes, ['value', 'value']) ?
                    values.billingSuccessBonusCurrency.value : null
            }

            createAddendum(addendumData)
        }
    })

    const [contractsQuery, setContractsQuery] = useDebouncedState('', debounceWait)
    const {data: contracts, isFetching: isFetchingContracts, isPlaceholderData: isPlaceholderDataContracts} = useQuery({
        queryKey: [RESOURCES.contracts.name, contractsQuery],
        queryFn: async () => {
            const filters = {
                search: contractsQuery,
                project_id: values.initialProjectID,
                entity_id: selectedEntityID,
                page_size: datatablePageSize,
                state_not: [CANCELED_STATE]
            }

            const contractsResult = await fetchData(RESOURCES.contracts, filters)

            if(!_.isEmpty(contractsResult) && contractsResult.length === 1 && _.isNil(values.contractId)) {
                setFieldValue('contractId', _.head(contractsResult))
            }

            return contractsResult
        },
        placeholderData: keepPreviousData
    })

    const handleChangeContractSearchField = useCallback((value) => setContractsQuery(value) , [setContractsQuery])

    const amountToRecoverCurrencies = useMemo(() =>
        getFieldOptions(addendumOptions, 'amount_to_recover_currency')
    , [addendumOptions])

    const billingPaymentMethods = useMemo(() =>
        getFieldOptions(addendumOptions, 'billing_payment_method')
    , [addendumOptions])

    const billingCurrencies = useMemo(() =>
        getFieldOptions(addendumOptions, 'billing_currency')
    , [addendumOptions])

    const billingSuccessBonusTypes = useMemo(() =>
        getFieldOptions(addendumOptions, 'billing_success_bonus_type')
    , [addendumOptions])

    const billingSuccessBonusCurrencies = useMemo(() =>
        getFieldOptions(addendumOptions, 'billing_success_bonus_currency')
    , [addendumOptions])

    const {data: documentTemplates, isFetching: isFetchingDocumentTemplates} = useQuery({
        queryKey: [RESOURCES.documentTemplates.name],
        queryFn: async () => {
            const filters = {
                entity_id: selectedEntityID,
                type: documentTemplateTypes.find((type) => type.value === 'addendum').value
            }

            return await fetchData(RESOURCES.documentTemplates, filters)
        }
    })

    useEffect(() => {
        listAllDocumentSeries({entity_id: selectedEntityID})
    }, [listAllDocumentSeries, selectedEntityID])

    useEffect(() => {
        if(!_.isNil(values.contractId)) {
            setFieldValue(
                'internalSeriesId',
                _.find(
                    documentSeries,
                    (series) => parseInt(series.id) === parseInt(values.contractId.internal_series_id)
                ) || null
            )
            setFieldValue(
                'barSeriesId',
                _.find(
                    documentSeries,
                    (series) => parseInt(series.id) === parseInt(values.contractId.bar_series_id)
                ) || null
            )
            setFieldValue('name', `Act adițional nr. ${values.contractId.addendum_next_number}`)
            setFieldValue('legalObject', values.contractId.legal_object || '')
            setFieldValue(
                'signedDate',
                !_.isNull(values.contractId.signed_date) ? new Date(values.contractId.signed_date) : null
            )
            setFieldValue('startDate', new Date(values.contractId.start_date))
            setFieldValue(
                'endDate',
                !_.isNull(values.contractId.end_date) ? new Date(values.contractId.end_date) : null
            )
            // setFieldValue(
            //     'hasAmountToRecover',
            //     Boolean(
            //         !_.isNull(values.contractId.amount_to_recover) ||
            //         !_.isNull(values.contractId.amountToRecoverCurrency)
            //     )
            // )
            setFieldValue('amountToRecover', values.contractId.amount_to_recover || '' )
            setFieldValue(
                'amountToRecoverCurrency',
                !_.isNull(values.contractId.amount_to_recover_currency) ?
                    _.find(amountToRecoverCurrencies, ['value', values.contractId.amount_to_recover_currency]) :
                    null
            )
            setFieldValue(
                'billingPaymentMethod',
                _.find(billingPaymentMethods, ['value', values.contractId.billing_payment_method])
            )
            setFieldValue('billingAmount', values.contractId.billing_amount || '')
            setFieldValue(
                'billingCurrency',
                !_.isNull(values.contractId.billing_currency) ?
                    _.find(billingCurrencies, ['value', values.contractId.billing_currency]) :
                    null
            )
            setFieldValue(
                'billingTaxPercentage',
                !_.isNull(values.contractId.billing_tax_percentage) ?
                    taxPercentageOptions[parseInt(values.contractId.billing_tax_percentage)] :
                    null
            )
            setFieldValue('billingMinimumHours', values.contractId.billing_minimum_hours || '')
            setFieldValue('billingAmountForExtraHours', values.contractId.billing_amount_for_extra_hours || '')
            setFieldValue('isRecurring', !_.isNull(values.contractId.billing_recurring_months))
            setFieldValue(
                'billingRecurringMonths',
                !_.isNull(values.contractId.billing_recurring_months) ? values.contractId.billing_recurring_months : ''
            )
            setFieldValue('hasSuccessBonus', !_.isNull(values.contractId.billing_success_bonus_value))
            setFieldValue('billingSuccessBonusValue', values.contractId.billing_success_bonus_value || '')
            setFieldValue(
                'billingSuccessBonusType',
                !_.isNull(values.contractId.billing_success_bonus_type) ?
                    _.find(billingSuccessBonusTypes, ['value', values.contractId.billing_success_bonus_type]) :
                    null
            )
            setFieldValue(
                'billingSuccessBonusCurrency',
                !_.isNull(values.contractId.billing_success_bonus_currency) ?
                    _.find(billingSuccessBonusCurrencies, ['value', values.contractId.billing_success_bonus_currency]) :
                    null
            )
        }
    }, [
        amountToRecoverCurrencies,
        billingCurrencies,
        billingPaymentMethods,
        billingSuccessBonusCurrencies,
        billingSuccessBonusTypes,
        documentSeries,
        setFieldValue,
        values.contractId
    ])

    return (
        <div className="addendum-add-form-container">
            {!_.isEmpty(addendumOptions) && (
                <form>
                    <ErrorsList errors={nonFieldErrors} />
                    <div className="space-y-6">
                        <FormSection
                            autoHeight
                            renderForm={() =>
                                <>
                                    <p className="form-section-title">Setări generale</p>
                                    {initialProject && (
                                        <Select
                                            label='Proiect*'
                                            value={initialProject}
                                            options={[initialProject]}
                                            disabled
                                            getOptionValue={(option) => option.id}
                                            getOptionLabel={(option) => option.name}
                                            fullWidth
                                        />
                                    )}
                                    <Select
                                        label='Contract*'
                                        value={values.contractId}
                                        options={contracts}
                                        loading={isFetchingContracts || isPlaceholderDataContracts}
                                        disabled={initialContractID}
                                        getOptionValue={(option) => option.id}
                                        getOptionLabel={(option) => option.name}
                                        onChange={(e) => setFieldValue('contractId', e)}
                                        onInputChange={handleChangeContractSearchField}
                                        onBlur={handleBlur('contractId')}
                                        name='contractId'
                                        errors={fieldErrors}
                                        frontendErrors={errors}
                                        touched={touched.contractId}
                                        fullWidth
                                    />
                                    {!_.isNil(values.contractId) && (
                                        <>
                                            <Select
                                                label='Șablon*'
                                                value={values.templateId}
                                                options={documentTemplates}
                                                loading={isFetchingDocumentTemplates}
                                                getOptionValue={(option) => option.id}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(e) => setFieldValue('templateId', e)}
                                                onBlur={handleBlur('templateId')}
                                                disabled={_.isEmpty(documentTemplates)}
                                                name='templateId'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.templateId}
                                                fullWidth
                                            />
                                            <div className="split-row">
                                                <Input
                                                    label='Nume act adițional*'
                                                    value={values.name}
                                                    onChange={handleChange('name')}
                                                    onBlur={handleBlur('name')}
                                                    name='name'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.name}
                                                    fullWidth
                                                />
                                                <Input
                                                    label='Numar act adițional'
                                                    value={values.contractId?.addendum_next_number}
                                                    name='number'
                                                    disabled={true}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="split-row">
                                                <Select
                                                    label='Serie interna*'
                                                    value={values.internalSeriesId}
                                                    options={
                                                        _.filter(
                                                            documentSeries,
                                                            [
                                                                'type',
                                                                documentSeriesTypes.CONTRACT_INTERNAL.value
                                                            ]
                                                        )
                                                    }
                                                    loading={isLoadingDocumentSeries}
                                                    getOptionValue={(option) => option.id}
                                                    getOptionLabel={(option) =>
                                                        `${option.name} (${option.current_number})`
                                                    }
                                                    onChange={(e) => setFieldValue('internalSeriesId', e)}
                                                    onBlur={handleBlur('internalSeriesId')}
                                                    name='internalSeriesId'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.internalSeriesId}
                                                    fullWidth
                                                    disabled
                                                />
                                                <Select
                                                    label='Serie barou*'
                                                    value={values.barSeriesId}
                                                    options={
                                                        _.filter(
                                                            documentSeries,
                                                            ['type', documentSeriesTypes.CONTRACT_BAR.value]
                                                        )
                                                    }
                                                    loading={isLoadingDocumentSeries}
                                                    getOptionValue={(option) => option.id}
                                                    getOptionLabel={(option) =>
                                                        `${option.name} (${option.current_number})`
                                                    }
                                                    onChange={(e) => setFieldValue('barSeriesId', e)}
                                                    onBlur={handleBlur('barSeriesId')}
                                                    name='barSeriesId'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.barSeriesId}
                                                    fullWidth
                                                    disabled
                                                />
                                            </div>
                                            <Input
                                                label='Obiect contract'
                                                value={values.legalObject}
                                                onChange={handleChange('legalObject')}
                                                onBlur={handleBlur('legalObject')}
                                                name='legalObject'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.legalObject}
                                                fullWidth
                                            />
                                            <div className="split-row-3">
                                                <DatePicker
                                                    label='Data semnării'
                                                    value={values.signedDate}
                                                    onChange={(value) =>
                                                        setFieldValue(
                                                            'signedDate',
                                                            _.isNull(value) ? value : new Date(value)
                                                        )
                                                    }
                                                    onBlur={handleBlur('signedDate')}
                                                    name='signedDate'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.signedDate}
                                                    fullWidth
                                                />
                                                <DatePicker
                                                    label='Data producere efecte*'
                                                    value={values.startDate}
                                                    onChange={(value) =>
                                                        setFieldValue(
                                                            'startDate',
                                                            _.isNull(value) ? value : new Date(value)
                                                        )
                                                    }
                                                    onBlur={handleBlur('startDate')}
                                                    name='startDate'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.startDate}
                                                    fullWidth
                                                />
                                                <DatePicker
                                                    label='Data încetare'
                                                    value={values.endDate}
                                                    onChange={(value) =>
                                                        setFieldValue(
                                                            'endDate',
                                                            _.isNull(value) ? value : new Date(value)
                                                        )
                                                    }
                                                    onBlur={handleBlur('endDate')}
                                                    name='endDate'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.endDate}
                                                    fullWidth
                                                />
                                            </div>
                                            <div className="split-row-3">
                                                <Toggle
                                                    label='Sumă de recuperat'
                                                    checked={values.hasAmountToRecover}
                                                    onChange={(e) => setFieldValue('hasAmountToRecover', e)}
                                                    onBlur={handleBlur('hasAmountToRecover')}
                                                    name='hasAmountToRecover'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.hasAmountToRecover}
                                                />
                                                {values.hasAmountToRecover && (
                                                    <>
                                                        <Input
                                                            label='Suma*'
                                                            value={values.amountToRecover}
                                                            onChange={handleChange('amountToRecover')}
                                                            onBlur={handleBlur('amountToRecover')}
                                                            name='amountToRecover'
                                                            errors={fieldErrors}
                                                            frontendErrors={errors}
                                                            touched={touched.amountToRecover}
                                                            fullWidth
                                                        />
                                                        <Select
                                                            label='Moneda*'
                                                            value={values.amountToRecoverCurrency}
                                                            options={amountToRecoverCurrencies}
                                                            onChange={(e) =>
                                                                setFieldValue('amountToRecoverCurrency', e)
                                                            }
                                                            onBlur={handleBlur('amountToRecoverCurrency')}
                                                            name='amountToRecoverCurrency'
                                                            errors={fieldErrors}
                                                            frontendErrors={errors}
                                                            touched={touched.amountToRecoverCurrency}
                                                            fullWidth
                                                        />
                                                    </>
                                                )}

                                            </div>
                                            <RequiredFieldsText />
                                        </>
                                    )}
                                </>
                            }
                        />
                        {!_.isNil(values.contractId) && (
                            <FormSection
                                autoHeight
                                renderForm={() =>
                                    <>
                                        <p className="form-section-title">
                                            Setări facturare
                                        </p>
                                        <div className="split-row-4">
                                            <Select
                                                label='Tip facturare*'
                                                value={values.billingPaymentMethod}
                                                options={billingPaymentMethods}
                                                onChange={(e) => setFieldValue('billingPaymentMethod', e)}
                                                onBlur={handleBlur('billingPaymentMethod')}
                                                name='billingPaymentMethod'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.billingPaymentMethod}
                                                fullWidth
                                            />
                                            <Input
                                                label='Suma*'
                                                value={values.billingAmount}
                                                onChange={handleChange('billingAmount')}
                                                onBlur={handleBlur('billingAmount')}
                                                name='billingAmount'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.billingAmount}
                                                fullWidth
                                            />
                                            <Select
                                                label='Moneda*'
                                                value={values.billingCurrency}
                                                options={billingCurrencies}
                                                onChange={(e) => setFieldValue('billingCurrency', e)}
                                                onBlur={handleBlur('billingCurrency')}
                                                name='billingCurrency'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.billingCurrency}
                                                fullWidth
                                            />
                                            <Select
                                                label='Taxă adăugată*'
                                                value={values.billingTaxPercentage}
                                                options={_.values(_.orderBy(taxPercentageOptions, 'value','desc'))}
                                                onChange={(e) => setFieldValue('billingTaxPercentage', e)}
                                                onBlur={handleBlur('billingTaxPercentage')}
                                                name='billingTaxPercentage'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.billingTaxPercentage}
                                                fullWidth
                                            />
                                        </div>
                                        {values.billingPaymentMethod ===
                                            _.find(billingPaymentMethods, ['value', 'hourly_blended']
                                            ) && (
                                            <div className="split-row">
                                                <Input
                                                    label="Ore minime"
                                                    value={values.billingMinimumHours}
                                                    onChange={handleChange('billingMinimumHours')}
                                                    onBlur={handleBlur('billingMinimumHours')}
                                                    name="billingMinimumHours"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.billingMinimumHours}
                                                    type="number"
                                                    fullWidth
                                                />
                                                <Input
                                                    label="Sumă ore suplimentare"
                                                    value={values.billingAmountForExtraHours}
                                                    onChange={handleChange('billingAmountForExtraHours')}
                                                    onBlur={handleBlur('billingAmountForExtraHours')}
                                                    name="billingAmountForExtraHours"
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.billingAmountForExtraHours}
                                                    type="number"
                                                    fullWidth
                                                />
                                            </div>
                                        )}
                                        <div className="split-row-4">
                                            <Toggle
                                                label='Recurent'
                                                checked={values.isRecurring}
                                                onChange={(e) => setFieldValue('isRecurring', e)}
                                                onBlur={handleBlur('isRecurring')}
                                                name='isRecurring'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.isRecurring}
                                            />
                                            {values.isRecurring && (
                                                <div className="recurring-months-input-container">
                                                    <Input
                                                        value={values.billingRecurringMonths}
                                                        onChange={handleChange('billingRecurringMonths')}
                                                        onBlur={handleBlur('billingRecurringMonths')}
                                                        name='billingRecurringMonths'
                                                        errors={fieldErrors}
                                                        frontendErrors={errors}
                                                        touched={touched.billingRecurringMonths}
                                                        fullWidth
                                                    />
                                                    <p>luni*</p>
                                                </div>
                                            )}
                                        </div>
                                        <div className="split-row-4">
                                            <Toggle
                                                label='Onorariu succes'
                                                checked={values.hasSuccessBonus}
                                                onChange={(e) => setFieldValue('hasSuccessBonus', e)}
                                                onBlur={handleBlur('hasSuccessBonus')}
                                                name='hasSuccessBonus'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.hasSuccessBonus}
                                            />
                                            {values.hasSuccessBonus && (
                                                <>
                                                    <Select
                                                        label='Tip*'
                                                        value={values.billingSuccessBonusType}
                                                        options={billingSuccessBonusTypes}
                                                        onChange={(e) =>
                                                            setFieldValue('billingSuccessBonusType', e)
                                                        }
                                                        onBlur={handleBlur('billingSuccessBonusType')}
                                                        name='billingSuccessBonusType'
                                                        errors={fieldErrors}
                                                        frontendErrors={errors}
                                                        touched={touched.billingSuccessBonusType}
                                                        fullWidth
                                                    />
                                                    <Input
                                                        label='Număr*'
                                                        value={values.billingSuccessBonusValue}
                                                        onChange={handleChange('billingSuccessBonusValue')}
                                                        onBlur={handleBlur('billingSuccessBonusValue')}
                                                        name='billingSuccessBonusValue'
                                                        errors={fieldErrors}
                                                        frontendErrors={errors}
                                                        touched={touched.billingSuccessBonusValue}
                                                        fullWidth
                                                    />
                                                    {values.billingSuccessBonusType ===
                                                _.find(billingSuccessBonusTypes, ['value', 'value']) && (
                                                <Select
                                                            label="Monedă*"
                                                            value={values.billingSuccessBonusCurrency}
                                                            options={billingSuccessBonusCurrencies}
                                                            onChange={(e) =>
                                                                setFieldValue('billingSuccessBonusCurrency', e)
                                                            }
                                                            onBlur={handleBlur('billingSuccessBonusCurrency')}
                                                            name="billingSuccessBonusCurrency"
                                                            errors={fieldErrors}
                                                            frontendErrors={errors}
                                                            touched={touched.billingSuccessBonusCurrency}
                                                            fullWidth
                                                        />
                                                    )
                                                    }
                                                </>
                                            )}
                                        </div>
                                        <RequiredFieldsText />
                                    </>
                                }
                                buttonDisabled={!isValid}
                                onSubmit={handleSubmit}
                                submitButtonTitle='Generează act adițional'
                                loading={isFetchingAddendumsOptions}
                            />
                        )}
                    </div>
                </form>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    fieldErrors: state.addendums.fieldErrors,
    nonFieldErrors: state.addendums.nonFieldErrors,
    selectedEntityID: state.localConfigs.selectedEntityID,
    clients: _.values(state.clients.data),
    isLoadingClients: state.clients.isLoading,
    documentSeries: _.values(state.documentSeries.data),
    isLoadingDocumentSeries: state.documentSeries.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    listAllDocumentSeries: (params) => dispatch(RESOURCES.documentSeries.listAll({...params, active: true})),
    createAddendum: (addendumData) => dispatch(RESOURCES.addendums.create(addendumData))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddendumAdd)
import React from 'react'

import {StackSectionConfig} from './types'

import StackSection from './StackSection'

import './StackDatatable.scss'

interface StackDatatableProps {
    sectionsConfig: StackSectionConfig[]
}

const StackDatatable = ({sectionsConfig}: StackDatatableProps) => {
    return (
        <div className="stack-datatable">
            {sectionsConfig.map((sectionConfig, sectionConfigIdx) => (
                <StackSection
                    sectionConfig={sectionConfig}
                    key={sectionConfigIdx}
                />
            ))}
        </div>
    )
}

export default StackDatatable

import React, {useCallback, useMemo, useState} from 'react'

import {TrashIcon} from '@heroicons/react/24/outline'
import {keepPreviousData} from '@tanstack/react-query'

import _ from 'lodash'

import {setCurrent as setCurrentSubscription} from 'avoapp-react-common/dist/redux/bpiSubscriptions'
import {RESOURCES, RESOURCES_V2} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {modalTypes, openModal} from '../../../../redux/modals'

import {useQueryResourceDetail, useQueryResourceList} from '../../../../queries'
import {debounceWait} from '../../../../utils/constants'
import {useDebouncedState} from '../../../../utils/hooks'

import {Button} from '../../../../components/Button'
import {Datatable} from '../../../../components/Datatable'
import {DeleteBpiSubscriptionModal} from '../../../../components/DeleteBpiSubscriptionModal'

import {Link} from 'react-router-dom'
import {AddBPISubscriptionModal} from '../../../../components/AddBPISubscriptionModal'
import {BPIAppearancesSection} from '../../../MonitorBPI/partials'
import {bpiColumns} from './constants'

export const ExternalDataMD = ({
    selectedEntityID,
    setCurrentSubscription,
    openedModal,
    openModal,
    match: {
        params: {projectID}
    }
}) => {
    const [searchFilter, setSearchFilter] = useDebouncedState('', debounceWait)
    const [page, setPage] = useState(1)

    const filters = useMemo(
        () => ({
            search: searchFilter,
            project_id: projectID,
            entity_id: selectedEntityID,
            page_size: 15,
            page
        }),
        [page, projectID, searchFilter, selectedEntityID]
    )

    const {data: subscriptionsResult, isFetching} = useQueryResourceList(
        RESOURCES.bpiSubscriptions,
        filters,
        {placeholderData: keepPreviousData},
        //we want to refetch the data when the add or delete modal closes
        _.isNil(openedModal)
    )

    const {data: project} = useQueryResourceDetail(RESOURCES_V2.projects, projectID)

    const handleChangeSearchField = useCallback((value) => setSearchFilter(value), [setSearchFilter])

    const handleChangePage = useCallback((page) => setPage(page), [])

    return (
        <div className="page-project-show flex gap-4">
            <div className="w-2/5 shrink-0">
                <Datatable
                    title="Listă părți monitorizate"
                    data={subscriptionsResult?.data.results || []}
                    headerButton={() => (
                        <Button
                            title="Adaugă abonare"
                            onClick={() => openModal(modalTypes.ADD_MONITOR_BPI_SUBSCRIPTION)}
                            color="secondary"
                        />
                    )}
                    columns={[
                        ...bpiColumns,
                        {
                            Header: 'Acțiuni',
                            accessor: 'id',
                            Cell: ({row}) => (
                                <div className="datatable-row-buttons-container">
                                    <Link to={`/projects/${projectID}/external-data/monitor-bpi`}>
                                        <Button
                                            title="Vizualizare"
                                            size="small"
                                        />
                                    </Link>
                                    <Button
                                        icon={() => <TrashIcon />}
                                        onClick={() => {
                                            setCurrentSubscription(row.original)
                                            openModal(modalTypes.DELETE_BPI_SUBSCRIPTION)
                                        }}
                                        size="small"
                                        color="red"
                                    />
                                </div>
                            ),
                            disableSortBy: true
                        }
                    ]}
                    loading={isFetching}
                    previousPage={subscriptionsResult?.data?.pages?.previous}
                    currentPage={subscriptionsResult?.data?.page}
                    nextPage={subscriptionsResult?.data?.pages?.next}
                    totalPages={subscriptionsResult?.data?.number_of_pages}
                    onChangePage={(page) => handleChangePage(page)}
                    searchable
                    searchPlaceholder="Caută surse de date"
                    onSearch={(event) => handleChangeSearchField(event.target.value)}
                />
                <AddBPISubscriptionModal project={project} />
                <DeleteBpiSubscriptionModal />
            </div>
            <BPIAppearancesSection projectID={projectID} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    selectedEntityID: state.localConfigs.selectedEntityID,
    openedModal: state.modals.type
})

const mapDispatchToProps = (dispatch) => ({
    openModal: (modalType) => dispatch(openModal(modalType)),
    setCurrentSubscription: (subscription) => dispatch(setCurrentSubscription(subscription))
})

export default connect(mapStateToProps, mapDispatchToProps)(ExternalDataMD)

import React, {useCallback, useEffect} from 'react'

import _ from 'lodash'

import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {closeModal, modalTypes, openModal} from '../../redux/modals'

import {Button} from '../Button'
import {Modal} from '../Modal'

import {deleteSmartBillInvoicePayment} from 'avoapp-react-common/dist/redux/smartbill'
import {toast} from 'react-toastify'
import '../../assets/scss/DeleteModals.scss'
import {useSpecMutation} from '../../mutations'
import {externalSyncActions} from '../../pages/Invoices/constants'
import {Checkbox} from '../Checkbox'

export const DeleteInvoicePayment = ({
    open,
    isLoading,
    payment,
    deleteInvoicePayment,
    openSyncInvoiceModal,
    usersPreferences,
    closeModal
}) => {
    const [changesPerformed, setChangesPerformed] = React.useState(false)

    const onSmartBillFinished = useCallback(
        (data, action) => {
            const {errors, status} = data

            if (_.includes([403, 418], status)) {
                // SmartBill is not enabled for this entity. Missing API key or action not needed.
                return
            }

            if (status >= 400) {
                const errorMessage = _.get(
                    errors,
                    'non_field_errors.0.message',
                    'A apărut o eroare la sincronizarea cu SmartBill'
                )
                openSyncInvoiceModal(action, errorMessage)
            } else {
                toast('Factura a fost sincronizată cu succes în SmartBill', {type: 'success'})
            }
        },
        [openSyncInvoiceModal]
    )

    const {mutateAsync: runSpecMutation} = useSpecMutation({
        onSuccess: (data, variables, context) => {
            onSmartBillFinished(data, externalSyncActions.DELETE)
        }
    })

    const needsSmartBillWarning =
        usersPreferences.has_smartbill_integration && payment.sync_moment_smartbill && payment.type === 'bank_transfer'

    useEffect(() => {
        setChangesPerformed(false)
    }, [payment])

    return (
        <Modal
            open={open && !_.isNil(payment) && !_.isEmpty(payment)}
            title="Șterge încasare">
            <div className="delete-modal">
                <p className="message">Ești sigur că vrei să ștergi această încasare?</p>
                {needsSmartBillWarning && (
                    <>
                        <br />
                        <p className="message">
                            SmartBill nu ne permite ștergerea încasărilor prin OP în mod programatic. Înainte de a
                            șterge această încasare, te rugăm să o ștergi manual din SmartBill.
                        </p>
                        <Checkbox
                            label="M-am asigurat că încasarea aceasta e ștearsă manual din SmartBill"
                            checked={changesPerformed}
                            onChange={(e) => setChangesPerformed(e.target.checked)}
                        />
                    </>
                )}
                <div className="buttons-container">
                    <Button
                        title="Nu, renunță"
                        onClick={closeModal}
                        variant="outlined"
                        color="gray"
                    />
                    <Button
                        title="Da, șterge"
                        disabled={needsSmartBillWarning && !changesPerformed}
                        onClick={async () => {
                            if (!changesPerformed) {
                                const {status: deleteHTTPStatus} = await runSpecMutation(
                                    deleteSmartBillInvoicePayment(payment.id)
                                )
                                if (deleteHTTPStatus === 400) {
                                    return
                                }
                            }

                            deleteInvoicePayment(payment)
                        }}
                        loading={isLoading}
                        color="red"
                    />
                </div>
            </div>
        </Modal>
    )
}

const mapStateToProps = (state) => ({
    open: state.modals.type === modalTypes.DELETE_INVOICE_PAYMENT,
    usersPreferences: state.usersPreferences.data,
    isLoading: state.invoicePayments.isLoading
})

const mapDispatchToProps = (dispatch) => ({
    closeModal: () => dispatch(closeModal()),
    deleteInvoicePayment: (payment) => dispatch(RESOURCES.invoicePayments.destroy(payment)),
    openSyncInvoiceModal: (action, errorMessage) =>
        dispatch(openModal(modalTypes.INVOICE_EXTERNAL_SYNC_MODAL, {action, errorMessage}))
})

export default connect(mapStateToProps, mapDispatchToProps)(DeleteInvoicePayment)

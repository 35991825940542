import _, {isNull} from 'lodash'
import React, {useCallback, useMemo} from 'react'
import {Link} from 'react-router-dom'

import {FlagIcon, PlayIcon, StopIcon} from '@heroicons/react/24/outline'

// @ts-ignore
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
// @ts-ignore
import {stopRunningTaskTimeLog} from '../../../../../../redux/taskTimeLogs'

// @ts-ignore
import {toApiDateFormat, toApiTimeFormat} from '../../../../../../utils'

import {Button} from 'components/Button'
import {DatatableActions} from 'components/DatatableActions'
import {TableLink} from 'components/TableLink'

import {renderInterval} from '../utils'

import './StackTaskCard.scss'

interface StackTaskCardProps {
    task: any
    updateTask: (data: any, taskID: number) => void
    createTimeLog: (data: any) => void
    stopTaskTimeLog: (data: boolean) => void
    selectedEntityID: number
    currentTimeLog: any
}

const StackTaskCard = ({
    task,
    updateTask,
    createTimeLog,
    stopTaskTimeLog,
    selectedEntityID,
    currentTimeLog
}: StackTaskCardProps) => {
    const handleChangeCompletion = (task: any) => {
        let taskData: any = {completion: null}

        if (isNull(task.completion)) {
            const datetime = `${toApiDateFormat(new Date())}T${toApiTimeFormat(new Date())}`

            taskData = {completion: datetime}
        }

        updateTask(taskData, task.id)
    }

    const handleStartTimer = useCallback(
        (task: any) => {
            createTimeLog({
                entity_id: selectedEntityID,
                task_id: task.id,
                date: toApiDateFormat(new Date()),
                start: toApiTimeFormat(new Date())
            })
        },
        [createTimeLog, selectedEntityID]
    )

    const handleStopTimer = useCallback(
        (hasAutoStopped = false) => {
            stopTaskTimeLog(hasAutoStopped)
        },
        [stopTaskTimeLog]
    )

    const startStopActions = useMemo(() => {
        if (_.isEmpty(currentTimeLog) || !_.isNil(currentTimeLog.stop)) {
            return [
                {
                    title: 'Începe pontaj',
                    onClick: () => handleStartTimer(task),
                    icon: <PlayIcon />
                }
            ]
        }

        if (task.id === currentTimeLog.task_id) {
            return [
                {
                    title: 'Opreste timer',
                    onClick: () => handleStopTimer(false),
                    icon: <StopIcon />
                }
            ]
        }

        return []
    }, [currentTimeLog, handleStartTimer, handleStopTimer, task])

    return (
        <>
            <div className="flex justify-between">
                <Link to={`/tasks/${task.id}`}>
                    <p className="card-title">{task.title || 'Fară titlu'}</p>
                </Link>
                <DatatableActions
                    actions={[
                        {
                            title: isNull(task.completion) ? 'Finalizează' : 'Activează',
                            onClick: () => handleChangeCompletion(task),
                            icon: <FlagIcon />
                        },
                        ...startStopActions
                    ]}
                />
            </div>
            <div className="text-xs">{renderInterval(task)}</div>
            <div className="card-row">
                Responsabili:{' '}
                {_.join(
                    task.owners.map((owner: any) => owner.full_name),
                    ', '
                )}
            </div>
            <div className="card-row">
                Proiect: <TableLink to={`/porjects/${task.project.id}`}>{task.project.name}</TableLink>
            </div>
            {task.clients_names?.length ? (
                <div className="card-row text-nowrap">
                    Clienți:{' '}
                    {task.project.clients?.map((client: any, index: number) => (
                        <>
                            <TableLink to={`/clients/${client.id}`}>{client.name}</TableLink>
                            {index + 1 !== task.project.clients.length && ', '}
                        </>
                    ))}
                </div>
            ) : null}
            {task.duration_as_hours && <div className="card-row">Ore Lucrate: {task.duration_as_hours}</div>}

            <div className="card-footer">
                <Link to={`/tasks/${task.id}`}>
                    <Button
                        title="vezi detalii"
                        variant="text"
                        size="small"
                    />
                </Link>
            </div>
        </>
    )
}

const mapStateToProps = (state: any) => {
    return {
        selectedEntityID: state.localConfigs.selectedEntityID,
        currentTimeLog: state.taskTimeLogs.currentTaskTimeLog
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    updateTask: (taskData: any, taskID: number) => dispatch(RESOURCES.tasks.update(taskData, taskID)),
    createTimeLog: (data: any) => dispatch(RESOURCES.taskTimeLogs.create(data)),
    stopTaskTimeLog: (hasAutoStopped = false) => dispatch(stopRunningTaskTimeLog(hasAutoStopped))
})

export default connect(mapStateToProps, mapDispatchToProps)(StackTaskCard)

import React, {useEffect} from 'react'

import {Form, Formik} from 'formik'

import {values as _values, find, head, isEmpty, isNull, pick, get} from 'lodash'

import {updateOnbordingClient} from 'avoapp-react-common/dist/redux/clients'
import {getCompaniesFromName} from 'avoapp-react-common/dist/redux/companies'
import {getCountries, getStates} from 'avoapp-react-common/dist/redux/geo'
import {RESOURCES} from 'avoapp-react-common/dist/redux/spec'
import {connect} from 'react-redux'
import {increaseClientAddingStep, resetClientAddingStep} from '../../../redux/clients'

import {clientsSchema} from '../../../assets/validations'
import {getFieldOptions, objectKeysToSnakeCase} from '../../../utils'
import {RON_CURRENCY_VALUE} from '../../../utils/constants'
import {personTypes} from '../../../utils/types'

import {CompaniesModal} from '../../../components/CompaniesModal'
import {ErrorsList} from '../../../components/ErrorComponents'
import {FormSection} from '../../../components/FormSection'
import {Input} from '../../../components/Input'
import {PageLoader} from '../../../components/PageLoader'
import {PhoneInput} from '../../../components/PhoneInput'
import {RequiredFieldsText} from '../../../components/RequiredFieldsText'
import {Select} from '../../../components/Select'
import {Toggle} from '../../../components/Toggle'

export const GeneralInformations = ({
    client,
    isLoadingClients,
    fieldErrors,
    nonFieldErrors,
    increaseStep,
    updateClient,
    countries,
    states,
    clientBankAccountsOptions,
    getClientBankAccountsOptions,
    getCountries,
    getStates,
    resetStep,
    getCompaniesFromName
}) => {
    useEffect(() => {
        if(!client) {
            resetStep()
        }

        if(client.type === personTypes.COMPANY.value){
            getCompaniesFromName(client.name)
        }

        getClientBankAccountsOptions()
        getCountries()
        getStates('RO')

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(client.type === personTypes.COMPANY.value) {
            getCompaniesFromName(client.name)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [client.name])

    const currencies = getFieldOptions(clientBankAccountsOptions, 'currency')

    return (
        <>
            {!isEmpty(countries) ? (
                <Formik
                    initialValues={{
                        id: client.id,
                        client_id: client.id,
                        type: client.type,
                        vatCode: '',
                        IDSeries: '',
                        IDNumber: '',
                        businessRegistrationNumber: '',
                        email: '',
                        phone: '',

                        //Address
                        addAddress: true,
                        postalCode: '',
                        country: null,
                        county: null,
                        locality: '',
                        address: '',
                        addressDescription: 'Sediu principal',

                        // Bank Account
                        addBankAccount: true,
                        currency: null,
                        iban: '',
                        bankName: '',
                        swiftCode: '',
                        bankAccountDescription: ''
                    }}
                    validationSchema={clientsSchema.generalInformations}
                    onSubmit={(values) => {
                        const countyName = get(
                            values,
                            'county.name', // the county is selected from the list
                            get(values, 'county', null) // the county is typed in the input
                        )

                        const rawValues = {
                            ...values,
                            phone: !isEmpty(values.phone) ? `+${values.phone}` : null,
                            country: values.addAddress && !isNull(values.country) ? values.country.code : null,
                            county: values.addAddress && !isNull(values.county) ? countyName : null,
                            currency: values.addBankAccount && !isNull(values.currency) ?
                                values.currency.value :
                                null
                        }

                        let updateClientData = pick(
                            rawValues,
                            [
                                'id',
                                'vatCode',
                                'businessRegistrationNumber',
                                'email',
                                'phone',
                                'IDSeries',
                                'IDNumber'
                            ]
                        )

                        if(values.addAddress) {
                            const clientAddresses = pick(
                                rawValues,
                                [
                                    'client_id',
                                    'postalCode',
                                    'country',
                                    'county',
                                    'locality',
                                    'address',
                                    'addressDescription'
                                ]
                            )

                            updateClientData = {
                                ...updateClientData,
                                client_addresses: [
                                    {
                                        ...clientAddresses,
                                        postal_code: clientAddresses.postalCode || null
                                    }
                                ]

                            }
                        }

                        if(values.addBankAccount) {
                            const clientBankAccounts = pick(
                                rawValues,
                                ['client_id', 'currency', 'iban', 'bankName', 'swiftCode', 'bankAccountDescription']
                            )

                            updateClientData = {
                                ...updateClientData,
                                client_bank_accounts: [clientBankAccounts]
                            }
                        }

                        const updateClientSnakeData = objectKeysToSnakeCase(updateClientData)

                        updateClient(updateClientSnakeData)
                    }}
                >
                    {({
                        handleChange,
                        handleBlur,
                        setFieldValue,
                        handleSubmit,
                        values,
                        errors,
                        touched,
                        isValid
                    }) => (
                        <Form>
                            <ErrorsList errors={nonFieldErrors} />
                            <CompaniesModal setFieldValue={setFieldValue}/>
                            <FormSection
                                renderForm={() =>
                                    <>
                                        <div
                                            className={`${
                                                values.type !== personTypes.PERSON.value ?
                                                    'split-row' :
                                                    'space-y-6'
                                            }`}
                                        >
                                            <Input
                                                label={values.type === personTypes.PERSON.value ? 'CNP' : 'CUI'}
                                                value={values.vatCode}
                                                onChange={handleChange('vatCode')}
                                                onBlur={handleBlur('vatCode')}
                                                name='vatCode'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.vatCode}
                                                fullWidth
                                            />
                                            {values.type === personTypes.COMPANY.value ? (
                                                <Input
                                                    label='Reg. com.'
                                                    value={values.businessRegistrationNumber}
                                                    onChange={handleChange('businessRegistrationNumber')}
                                                    onBlur={handleBlur('businessRegistrationNumber')}
                                                    name='businessRegistrationNumber'
                                                    errors={fieldErrors}
                                                    frontendErrors={errors}
                                                    touched={touched.businessRegistrationNumber}
                                                    fullWidth
                                                />
                                            ) : null}
                                            {values.type === personTypes.PERSON.value ? (
                                                <div className="split-row">
                                                    <Input
                                                        label='Serie CI'
                                                        value={values.IDSeries}
                                                        onChange={handleChange('IDSeries')}
                                                        onBlur={handleBlur('IDSeries')}
                                                        name='IDSeries'
                                                        errors={fieldErrors}
                                                        frontendErrors={errors}
                                                        touched={touched.IDSeries}
                                                        fullWidth
                                                    />
                                                    <Input
                                                        label='Număr CI'
                                                        value={values.IDNumber}
                                                        onChange={handleChange('IDNumber')}
                                                        onBlur={handleBlur('IDNumber')}
                                                        name='IDNumber'
                                                        errors={fieldErrors}
                                                        frontendErrors={errors}
                                                        touched={touched.IDNumber}
                                                        fullWidth
                                                    />
                                                </div>
                                            ) : null}
                                            {/* <div className="offset-split-row flipped">
                                                    <Input
                                                        label='Serie'
                                                        value={values.idSeries}
                                                        onChange={handleChange('idSeries')}
                                                        fullWidth
                                                    />
                                                    <Input
                                                        label='Număr CI'
                                                        value={values.idNumber}
                                                        onChange={handleChange('idNumber')}
                                                        fullWidth
                                                    />
                                                </div> */}
                                        </div>
                                        <div className="split-row">
                                            <Input
                                                label='Email'
                                                value={values.email}
                                                onChange={handleChange('email')}
                                                onBlur={handleBlur('email')}
                                                name='email'
                                                errors={fieldErrors}
                                                frontendErrors={errors}
                                                touched={touched.email}
                                                fullWidth
                                            />
                                            <PhoneInput
                                                label='Telefon'
                                                value={values.phone}
                                                onChange={handleChange('phone')}
                                                name='phone'
                                                errors={fieldErrors}
                                                fullWidth
                                            />
                                        </div>
                                        <div className="address-form-section">
                                            <Toggle
                                                label='Adaugă adresă principală'
                                                checked={values.addAddress}
                                                onChange={(e) => {
                                                    setFieldValue('addAddress', e)
                                                    if (e) {
                                                        if(isNull(values.country)) {
                                                            setFieldValue('country', find(countries, ['code', 'RO']))

                                                            if(isNull(values.county)) {
                                                                setFieldValue('county', head(states))
                                                            }
                                                        }

                                                        if(isEmpty(values.addressDescription)) {
                                                            setFieldValue('addressDescription', 'Adresă principală')
                                                        }
                                                    }
                                                }}
                                            />
                                            {values.addAddress && (
                                                <>
                                                    <div className="address-container">
                                                        <Select
                                                            label='Țara*'
                                                            value={values.country}
                                                            options={countries}
                                                            onChange={(e) => {
                                                                setFieldValue('country', e)
                                                                setFieldValue('county', '')

                                                                if(!isNull(e)) {
                                                                    getStates(e.code)
                                                                }
                                                            }}
                                                            onBlur={handleBlur('country')}
                                                            getOptionLabel={(option) => option.name}
                                                            getOptionValue={(option) => option.code}
                                                            name='country'
                                                            errors={head(fieldErrors.client_addresses)}
                                                            frontendErrors={errors}
                                                            touched={touched.country}
                                                            isClearable
                                                            fullWidth
                                                        />
                                                        {!isEmpty(states) || isNull(values.country) ? (
                                                            <Select
                                                                label='Județ*'
                                                                value={values.county}
                                                                options={states}
                                                                onChange={(e) => setFieldValue('county', e)}
                                                                onBlur={handleBlur('county')}
                                                                getOptionLabel={(option) => option.name}
                                                                getOptionValue={(option) => option.code}
                                                                name='county'
                                                                errors={head(fieldErrors.client_addresses)}
                                                                frontendErrors={errors}
                                                                touched={touched.county}
                                                                disabled={isNull(values.country)}
                                                                isClearable
                                                                fullWidth
                                                            />
                                                        ) : (
                                                            <Input
                                                                label='Județ*'
                                                                value={values.county}
                                                                onChange={handleChange('county')}
                                                                onBlur={handleBlur('county')}
                                                                name='county'
                                                                errors={head(fieldErrors.client_addresses)}
                                                                frontendErrors={errors}
                                                                touched={touched.county}
                                                                fullWidth
                                                            />
                                                        )}
                                                        <Input
                                                            label='Localitate / Oraș*'
                                                            value={values.locality}
                                                            onChange={handleChange('locality')}
                                                            onBlur={handleBlur('locality')}
                                                            name='locality'
                                                            errors={head(fieldErrors.client_addresses)}
                                                            frontendErrors={errors}
                                                            touched={touched.locality}
                                                            fullWidth
                                                        />
                                                    </div>
                                                    <div className="offset-split-row flipped">
                                                        <Input
                                                            label='Cod Poștal'
                                                            value={values.postalCode}
                                                            onChange={handleChange('postalCode')}
                                                            onBlur={handleBlur('postalCode')}
                                                            name='postalCode'
                                                            errors={head(fieldErrors.client_addresses)}
                                                            frontendErrors={errors}
                                                            touched={touched.postalCode}
                                                            fullWidth
                                                        />
                                                        <Input
                                                            label='Adresa*'
                                                            value={values.address}
                                                            onChange={handleChange('address')}
                                                            onBlur={handleBlur('address')}
                                                            name='address'
                                                            errors={head(fieldErrors.client_addresses)}
                                                            frontendErrors={errors}
                                                            touched={touched.address}
                                                            fullWidth
                                                        />
                                                    </div>
                                                    <Input
                                                        label='Descriere adresă*'
                                                        value={values.addressDescription}
                                                        onChange={handleChange('addressDescription')}
                                                        onBlur={handleBlur('addressDescription')}
                                                        name='addressDescription'
                                                        errors={head(fieldErrors.client_addresses)}
                                                        frontendErrors={errors}
                                                        touched={touched.addressDescription}
                                                        fullWidth
                                                    />
                                                </>
                                            )}

                                        </div>
                                        <div className="bank-account-section">
                                            <Toggle
                                                label='Adaugă cont bancar principal'
                                                checked={values.addBankAccount}
                                                onChange={(e) => {
                                                    setFieldValue('addBankAccount', e)

                                                    if (e) {
                                                        if(isNull(values.currency)) {
                                                            setFieldValue(
                                                                'currency',
                                                                find(currencies, ['value', RON_CURRENCY_VALUE])
                                                            )
                                                        }

                                                        if(isEmpty(values.bankAccountDescription)) {
                                                            setFieldValue(
                                                                'bankAccountDescription',
                                                                'Cont bancar principal'
                                                            )
                                                        }
                                                    }
                                                }}
                                            />
                                            {values.addBankAccount && (
                                                <>
                                                    <div className="offset-split-row">
                                                        <Input
                                                            label='IBAN*'
                                                            value={values.iban}
                                                            onChange={handleChange('iban')}
                                                            onBlur={handleBlur('iban')}
                                                            name='iban'
                                                            errors={head(fieldErrors.client_bank_accounts)}
                                                            frontendErrors={errors}
                                                            touched={touched.iban}
                                                            fullWidth
                                                        />
                                                        <Select
                                                            label='Moneda*'
                                                            value={values.currency}
                                                            options={currencies}
                                                            onChange={(e) => setFieldValue('currency', e)}
                                                            onBlur={handleBlur('currency')}
                                                            name='currency'
                                                            errors={head(fieldErrors.client_bank_accounts)}
                                                            frontendErrors={errors}
                                                            touched={touched.currency}
                                                            fullWidth
                                                        />
                                                    </div>
                                                    <div className="offset-split-row">
                                                        <Input
                                                            label='Banca*'
                                                            value={values.bankName}
                                                            onChange={handleChange('bankName')}
                                                            onBlur={handleBlur('bankName')}
                                                            name='bankName'
                                                            errors={head(fieldErrors.client_bank_accounts)}
                                                            frontendErrors={errors}
                                                            touched={touched.bankName}
                                                            fullWidth
                                                        />
                                                        <Input
                                                            label='Cod SWIFT'
                                                            value={values.swiftCode}
                                                            onChange={handleChange('swiftCode')}
                                                            onBlur={handleBlur('swiftCode')}
                                                            name='swiftCode'
                                                            errors={head(fieldErrors.client_bank_accounts)}
                                                            frontendErrors={errors}
                                                            touched={touched.swiftCode}
                                                            fullWidth
                                                        />
                                                    </div>
                                                    <Input
                                                        label='Descriere cont bancar*'
                                                        value={values.bankAccountDescription}
                                                        onChange={handleChange('bankAccountDescription')}
                                                        onBlur={handleBlur('bankAccountDescription')}
                                                        name='bankAccountDescription'
                                                        errors={head(fieldErrors.client_bank_accounts)}
                                                        frontendErrors={errors}
                                                        touched={touched.bankAccountDescription}
                                                        fullWidth
                                                    />
                                                </>
                                            )}
                                            <RequiredFieldsText />
                                        </div>
                                    </>
                                }
                                onSkip={increaseStep}
                                skipButtonTitle='Sari peste'
                                onSubmit={handleSubmit}
                                submitButtonTitle='Mai departe'
                                buttonDisabled={!isValid}
                                loading={isLoadingClients}
                            />
                        </Form>
                    )}
                </Formik>
            ) : (
                <PageLoader />
            )}
        </>
    )
}

const mapStateToProps = (state) => ({
    clientBankAccountsOptions: state.clientBankAccounts.options,
    client: state.clients.currentClient,
    fieldErrors: state.clients.fieldErrors,
    nonFieldErrors: state.clients.nonFieldErrors,
    countries: _values(state.geo.countries),
    states: _values(state.geo.states)
})

const mapDispatchToProps = (dispatch) => ({
    getClientBankAccountsOptions: () => dispatch(RESOURCES.clientBankAccounts.getOptions()),
    updateClient: (values) => dispatch(updateOnbordingClient(values)),
    resetStep: () => dispatch(resetClientAddingStep()),
    increaseStep: () => dispatch(increaseClientAddingStep()),
    getCountries: () => dispatch(getCountries()),
    getStates: (countryCode) => dispatch(getStates({country_code: countryCode, page_size: 50})),
    getCompaniesFromName: (name) => dispatch(getCompaniesFromName(name))
})

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInformations)
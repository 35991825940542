import React from 'react'

import {useFormik} from 'formik'

// @ts-ignore
import {bpiAddTabs} from '../../pages/ProjectDetails/partials/ExternalData/constants'

import {Button} from 'components/Button'
import {Select} from 'components/Select'
// @ts-ignore
import {Input} from '../Input'

const bpiSource = {label: 'BPI', value: 'bpi'}

interface VatCodeFormProps {
    onAddSubscription: (values: any) => void
    isLoading: boolean
    isDisabled: boolean
}

interface NameFormProps {
    onSearchCompany: (values: any) => void
    isLoading: boolean
    isDisabled: boolean
}

interface SearchDataSourcesFormProps extends VatCodeFormProps, NameFormProps {
    selectedTab: string
}

export default function SearchDataSourcesForm({
    selectedTab,
    onAddSubscription,
    onSearchCompany,
    isLoading,
    isDisabled
}: SearchDataSourcesFormProps) {
    switch (selectedTab) {
        case bpiAddTabs.WITH_VAT_CODE:
            return (
                <VatCodeForm
                    onAddSubscription={onAddSubscription}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                />
            )

        case bpiAddTabs.WITH_NAME:
            return (
                <NameForm
                    onSearchCompany={onSearchCompany}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                />
            )

        default:
            return <React.Fragment />
    }
}

function VatCodeForm({onAddSubscription, isLoading, isDisabled}: VatCodeFormProps) {
    const {values, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues: {
            companyName: '',
            vatNumber: ''
        },
        onSubmit: () => {
            onAddSubscription(values)
        }
    })

    return (
        <form className="search-data-sources-form">
            <Select
                label="Sursa"
                name="source"
                value={bpiSource}
                options={[bpiSource]}
                fullWidth
                disabled
            />
            <Input
                label="CUI"
                value={values.vatNumber}
                onChange={handleChange('vatNumber')}
                onBlur={handleBlur('vatNumber')}
                name="vatNumber"
                touched={touched.vatNumber}
                disabled={isDisabled}
                fullWidth
            />
            <Input
                label="Nume companie"
                value={values.companyName}
                onChange={handleChange('companyName')}
                onBlur={handleBlur('companyName')}
                name="companyName"
                touched={touched.companyName}
                disabled={isDisabled}
                fullWidth
            />
            <Button
                title="Adaugă abonare BPI"
                onClick={handleSubmit}
                loading={isLoading}
                disabled={isDisabled}
                fullWidth
            />
        </form>
    )
}

function NameForm({onSearchCompany, isLoading, isDisabled}: NameFormProps) {
    const {values, touched, handleBlur, handleChange, handleSubmit} = useFormik({
        initialValues: {companyName: ''},
        onSubmit: () => {
            onSearchCompany(values)
        }
    })

    return (
        <form className="search-data-sources-form">
            <Select
                label="Sursa"
                name="source"
                value={bpiSource}
                options={[bpiSource]}
                fullWidth
                disabled
            />
            <Input
                label="Nume companie"
                value={values.companyName}
                onChange={handleChange('companyName')}
                onBlur={handleBlur('companyName')}
                name="companyName"
                touched={touched.companyName}
                disabled={isDisabled}
                fullWidth
            />
            <Button
                title="Caută compania"
                onClick={handleSubmit}
                loading={isLoading}
                disabled={isDisabled}
                fullWidth
            />
        </form>
    )
}

import React from 'react'

interface StackSectionItemProps {
    item: any
    render: ({item}: {item: any}) => React.ReactNode
}

const StackSectionItem = ({item, render}: StackSectionItemProps) => {
    return <li className="stack-section-item">{render(item)}</li>
}

export default StackSectionItem
